import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TopHeader from '../components/Header/TopHeader';
import Footer from '../components/Footer/Footer';
import { useAppDispatch } from '../store/hook';
import { UserProfileAction } from '../store/reducer/user/action';
import FullPageLoader from '../_common/helper/FullPageLoader';


const BusinessSingnupSecondStep = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [file, setFile] = useState(null); // Store the selected file
    //const _tempuser = JSON.parse(localStorage.getItem('_tempuser'));
    const _tempuser = JSON.parse(localStorage.getItem('user'));
    const userId = _tempuser?.userId;
    const [loading, setLoading] = useState(false);
    const [workForData, setWorkForData] = useState(3);
    
    const workforHandler = (data) => {
        setWorkForData(data)
    }
    const onSubmit = async (data) => {
        setLoading(true);
        try {
          const formData = new FormData();
          formData.append('UserId', userId); // Replace with your user ID
          formData.append('Image', file); // Append the file
          formData.append('DisplayName', data.DisplayName);
          formData.append('CompanyName', data.CompanyName);
          formData.append('Biodata', data.Biodata);
          formData.append('JobTitle', data.JobTitle);
          formData.append('WorkFor', workForData);
          formData.append('TwoFA', false);
          
        
        dispatch(UserProfileAction(formData))
        .unwrap()
        .then((fulfilledAction) => {           
           // console.log('fulfilledAction:', fulfilledAction);
           
            if (fulfilledAction.token !== null && fulfilledAction.token !== "null") {
                navigate('/expertise');
            } else {
                toast.error('Sorry, this email address already exists.');
                
            }
            setLoading(false);  
        })
        .catch((rejectedAction) => {
            //console.log('rejectedAction:', rejectedAction);
            setLoading(false); 
            toast.error('Incorrect username or password. Please try again.');
            
        });
        //setLoading(false); 
    
        //   console.log('Response:', formData);
        //   console.log('data.DisplayName',data.DisplayName);
        //   console.log('file',file);
          // Redirect or handle success as needed
          //navigate('/success'); // Replace with your success route
        } catch (error) {
          console.error('Error:', error);
          setLoading(false); 
          // Handle error and show a message if necessary
          toast.error('Error occurred while uploading.');
        }
      };

    // Function to handle file input change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        //console.log(e.target.files[0]);
    };
    
    return (
        <>
            {loading && <FullPageLoader />}
            <TopHeader />
            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                                <div className="step_page">
                                    <h3>Profile Customization</h3>
                                    <div className="mb-3">
                                        <div className="profile_pic ms-auto me-auto">
                                            <img src="assets/images/avatar.jpg" />
                                            <div className="edit_profpic">
                                                <span className="material-symbols-outlined">edit</span>
                                            </div>
                                            <input type="file" className="inputfile_profilechange" onChange={handleFileChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <input type="text" {...register("DisplayName", { required: true })} placeholder="Display Name" className="form-control" maxLength={48} />
                                            {errors.DisplayName && <span className="rf-field-validation">Display name is required</span>}
                                        </div>
                                        
                                        
                                        <div className="col-md-6 mb-3">
                                            <input type="text" {...register("CompanyName", { required: true })} placeholder="Company Name" className="form-control" maxLength={148} />
                                            {errors.CompanyName && <span className="rf-field-validation">Company name is required</span>}
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <input type="text" {...register("JobTitle", { required: true })} placeholder="Job Title" className="form-control" maxLength={148} />
                                        </div>
                                    </div>
                                    {errors.JobTitle && <span className="rf-field-validation">Job title is required</span>}

                                    <div className="stepcategory_list">
                                        <div className="category_list mb-3 w-100">
                                            <h5>I work for a</h5>
                                            <div className="d-flex">
                                                <div className="form-check">
                                                    <input className="form-check-input" {...register("WorkFor", { required: true })} type="radio" value={2} name="flexRadioDefault" id="flexRadioDefault1" onChange={() => workforHandler(2)}/>
                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                        Utility
                                                    </label>
                                                </div>
                                                <div className="form-check ms-3">
                                                    <input className="form-check-input" {...register("WorkFor", { required: true })} type="radio" value={3} name="flexRadioDefault" id="flexRadioDefault2" onChange={() => workforHandler(3)}   defaultChecked/>
                                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                        Solution Provider
                                                    </label>
                                                </div>
                                                {/* <div className="form-check ms-3">
                                                    <input className="form-check-input" {...register("WorkFor", { required: true })} type="radio" value={4} name="flexRadioDefault" id="flexRadioDefault3" disabled/>
                                                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                        Other
                                                    </label>
                                                </div> */}
                                            </div>


                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <textarea {...register("Biodata", { required: true })} id="" placeholder="Brief Bio" className="form-control" maxLength={1900}></textarea>
                                        </div>
                                        {errors.Biodata && <span className="rf-field-validation">Biodata is required</span>}
                                    </div>


                                    <div className="step_bottomposition">
                                        <div className="text-end">
                                            {/* <button className="btn filter_btn text-uppercase">back</button> */}
                                            <button className="btn btn-danger text-uppercase ms-2" type='submit'>continue</button>
                                        </div>
                                        <div className="pagebullet">
                                            <ul>
                                                <li><a className="active" href="#">1</a></li>
                                                <li><a className="active" href="#">2</a></li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />

        </>
    )
}

export default BusinessSingnupSecondStep