import React from 'react'
import TopHeader from '../components/Header/TopHeader'
import Footer from '../components/Footer/Footer'

const AccessDenied = () => {
  return (
    <>
      <TopHeader />
      <div className="masonry-container page_minheight">
        <div className="container">
          <div className="profile_page page_minheight d-flex flex-column justify-content-center align-items-center p-4">
            <div className="error_img pb-3">
              <img src="/assets/images/error.png" />
            </div>
            <div className="text_404">
              
              <span><img src="/assets/images/glass.png" /></span>
             
            </div>
            <div className="notfound_txt">Access Denied.</div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default AccessDenied