import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export function useAuthStatus() {
  
  const auth = localStorage.getItem('_rftoken');
  
  const authChecker = () => {      
    return (auth)?true:false

  }
  
  const [isAuthenticated, setAuthenticated] = useState(authChecker())
  useEffect(() => {
    setAuthenticated(authChecker())
  }, [auth]);

  return isAuthenticated
}

export function useRegisterStatus() { 
  

  const _tempuser = JSON.parse(localStorage.getItem('_tempuser'));
  const localUser = _tempuser?.token;
  
  const registerChecker = () => {      
    return (localUser)?true:false

  }
  
  const [isLocalUser, setLocalUser] = useState(registerChecker())
  useEffect(() => {
    setLocalUser(registerChecker())
  }, [localUser]);

  return isLocalUser
}





  