import {   
    createAsyncThunk, 
    createSlice
} from "@reduxjs/toolkit";

import { AddMyPinAction, GetProfileCategoryMap, getAllPins } from "./action";




const addPinSlice = createSlice({
    name:"auth",
    initialState: {
        isLoding: false,
        items: null,
        error: null
    },
    extraReducers: (builder)=> {
        builder.addCase(AddMyPinAction.pending, (state,action)=>{
            state.isLoding = true;
            state.items = null;
            state.error = null;
        })
        builder.addCase(AddMyPinAction.fulfilled, (state, action)=>{
            state.isLoding = false;
            state.items = action.payload;
            state.error = null;
        })

        builder.addCase(AddMyPinAction.rejected,(state, action)=>{
            state.isLoding = false;
            state.items = null;
            // console.log(action.error.message);
            state.error = action.error.message;
        })
    }
})

const getPinItemsSlice = createSlice({
    name:"auth",
    initialState: {
        isLoding: false,
        items: [],
        error: null
    },
    extraReducers: (builder)=> {
        builder.addCase(GetProfileCategoryMap.pending, (state,action)=>{
            state.isLoding = true;
            state.items = null;
            state.error = null;
        })
        builder.addCase(GetProfileCategoryMap.fulfilled, (state, action)=>{
            //console.log('action', action.payload);
            state.isLoding = false;
            state.items = action.payload.objCategories;
            state.error = null;
        })

        builder.addCase(GetProfileCategoryMap.rejected,(state, action)=>{
            state.isLoding = false;
            state.items = null;
            // console.log(action.error.message);
            state.error = action.error.message;
        })
    }
})

const getAllPinItemsSlice = createSlice({
    name:"auth",
    initialState: {
        isLoding: false,
        allItems: [],
        error: null
    },
    extraReducers: (builder)=> {
        builder.addCase(getAllPins.pending, (state,action)=>{
            state.isLoding = true;
            state.allItems = null;
            state.error = null;
        })
        builder.addCase(getAllPins.fulfilled, (state, action)=>{
            //console.log('action', action.payload);
            state.isLoding = false;
            state.allItems = action.payload;
            state.error = null;
        })

        builder.addCase(getAllPins.rejected,(state, action)=>{
            state.isLoding = false;
            state.allItems = null;
            // console.log(action.error.message);
            state.error = action.error.message;
        })
    }
})


// Export both reducers as named exports
export const addPinReducer = addPinSlice.reducer;
export const getPinItemsReducer = getPinItemsSlice.reducer;
export const getAllPinItemsReducer = getAllPinItemsSlice.reducer;