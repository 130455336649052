import "./App.scss";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { privatepages, publicpages, rgistrationpages } from "./routes";

import AccessDenied from "./pages/AccessDenied";
import CategorySelection from "./pages/test";
import Details from "./pages/Details";
import HomePage from "./pages/HomePage";
import Layout from "./pages/Layout/Layout";
import Login from "./pages/Auth/Login";
import PageNotFound from "./pages/PageNotFound";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import RegistrationRoute from "./routes/RegistrationRoute";
import UserApproval from "./pages/UserApproval";

function App() {
  return (
    <Router>
      <Routes>

        <Route path='/test' exact element={<CategorySelection />} />
        <Route path='/access-denied' exact element={<UserApproval />} />
        <Route path='/permission-denied' exact element={<AccessDenied />} />
        <Route path='/details' exact element={<Details />} />
        
        {/* Public Route */}
        <Route element={<PublicRoute />}>
          {publicpages.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
        </Route>

        {/* Private Route */}
        <Route element={<PrivateRoute />}>
          {privatepages.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
        </Route>

        {/* Registration Route */}
        <Route element={<RegistrationRoute />}>
          {rgistrationpages.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
        </Route>

        {/* Page Not Found Route */}
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
