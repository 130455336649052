import React from 'react'
import { NavLink } from 'react-router-dom'
import { getUserStore } from '../../_common/helper/LocalStorage'


const LeftNavMenu = () => {
    const data = getUserStore();
    //console.log('getUserStore', data);
  
    // if(data.isApproved === true) {
    //     if( data.twoFA === true && data.localTwoFA === true ) {
    //         to='/my-profile'
    //     } else if (data.twoFA === true && data.localTwoFA !== true) {
    //         to='/login'
    //     }  else if (data.twoFA === false && data.localTwoFA !== true) {
    //         to='/my-profile'
    //     }

    // } else {
    //     to='access-denied'
    // }
    // const toProp = data.twoFA === true && data.localTwoFA === true
    //     ? '/my-profile'
    //     : data.twoFA === true && data.localTwoFA !== true
    //     ? '/access-twofa'
    //     : '/login';

    // to={
    //     data.isApproved === true
    //       ? data.twoFA === true && data.localTwoFA === true
    //         ? '/my-profile'
    //         : data.twoFA === true && data.localTwoFA !== true
    //         ? '/login'
    //         : data.twoFA === false && data.localTwoFA !== true
    //         ? '/my-profile'
    //         : '/access-denied'
    //       : '/access-denied'
    //   }
    return (
        <>
            <ul>
                <li>
                    <NavLink to="/my-profile">
                        <span className="material-symbols-outlined">person</span> User Profile
                    </NavLink>
                </li>
                <li><NavLink to="/my-expertise"><span className="material-symbols-outlined">construction</span> My Expertise</NavLink></li>
                <li><NavLink to='/add-pin'><span className="material-symbols-outlined">add</span>Add Test Flight</NavLink></li>
                <li><NavLink to='/my-pin'><span className="material-symbols-outlined">push_pin</span>My Test Flight</NavLink></li>                
                <li><NavLink to='/my-save-pins'><span className="material-symbols-outlined">save</span>Saved Test Flight</NavLink></li>
                <li><NavLink to="/settings"><span className="material-symbols-outlined">settings</span> Settings</NavLink></li>
            </ul>
        </>
    )
}

export default LeftNavMenu