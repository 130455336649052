import React, { useEffect } from 'react'
import TopHeader from '../components/Header/TopHeader'
import Footer from '../components/Footer/Footer'
import { removeAllUserStore } from '../_common/helper/LocalStorage';
import { useNavigate } from 'react-router-dom';


const UserApproval = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      removeAllUserStore()       
      
    }, 3000);

    setTimeout(() => {         
        navigate('/');
      
    }, 5000);
  }, []);


  return (
    <>
      <TopHeader />
      <div className="masonry-container page_minheight">
        <div className="container">
          <div className="profile_page page_minheight d-flex flex-column justify-content-center align-items-center p-4">
            <div className="error_img pb-3">
              <img src="/assets/images/error.png" />
            </div>
            <div className="text_404">

              <span><img src="/assets/images/glass.png" /></span>

            </div>
            <div className="notfound_txt">Waiting For Admin Approval</div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default UserApproval