import { Activity, NewActivity, UR } from 'getstream';
import { DefaultAT, DefaultUT, useTranslationContext } from '../../context';
import { Textarea as DefaultTextarea, TextareaProps } from '../Textarea';
import { ElementOrComponentOrLiteralType, PropsWithElementAttributes, smartRender } from '../../utils';
import { EmojiPicker, EmojiPickerProps } from '../EmojiPicker';
import {
  FilePreviewer,
  FileUpload,
  FileUploadButton,
  ImageDropzone,
  ImagePreviewer,
  ImageUpload,
  ImageUploadButton,
  LoadingIndicator,
} from 'react-file-utils';
import { Panel, PanelContent, PanelFooter, PanelHeading } from '../Panel';
import React, { ComponentProps, ReactNode } from 'react';

import { Audio } from '../Audio';
import { Avatar } from '../Avatar';
import { BookmarkIcon } from '../Icons';
import { Button } from '../Button';
import { Card } from '../Card';
import { Title } from '../Title';
import { Video } from '../Video';
import { useStatusUpdateForm } from './useStatusUpdateForm';

export type StatusUpdateFormProps<AT extends DefaultAT = DefaultAT> = PropsWithElementAttributes<{
  /** The verb that should be used to post the activity, default to "post" */
  activityVerb?: string;
  /** Override Post request */
  doRequest?: (activity: NewActivity<AT>) => Promise<Activity<AT>>;
  /** Override the default emoji dataset, library has a light set of emojis
   * to show more emojis use your own or [emoji-mart sets](https://github.com/missive/emoji-mart#datasets)
   */
  emojiData?: EmojiPickerProps['emojiData'];
  /** Override the default i18n dictionary providing your own translations where necessary */
  emojiI18n?: EmojiPickerProps['i18n'];
  /** The feed group part of the feed that the activity should be posted to, default to "user" */
  feedGroup?: string;
  /** Add extra footer item */
  FooterItem?: ReactNode;
  /** The header to display */
  Header?: ReactNode;
  /** The Left where Avatar to display */
  Left?: ReactNode;
  /** If you want to change something about the activity data that this form
   * sends to stream you can do that with this function. This function gets the
   * activity data that the form would send normally and should return the
   * modified activity data that should be posted instead.
   *
   * For instance, this would add a target field to the activity:
   *
   * ```javascript
   * &lt;StatusUpdateForm
   *   modifyActivityData={(data) => ({...data, target: 'Group:1'})}
   * />
   * ```
   * */
  modifyActivityData?: (activity: NewActivity<AT>) => NewActivity<AT>;
  /** A callback to run after the activity is posted successfully */
  onSuccess?: (activity: Activity<AT>) => void;
  /** Custom Textarea component implementation */
  Textarea?: ElementOrComponentOrLiteralType<Omit<TextareaProps, 'maxLength' | 'rows'>>;
  /** An extra trigger for ReactTextareaAutocomplete, this can be used to show
   * a menu when typing @xxx or #xxx, in addition to the emoji menu when typing
   * :xxx  */
  trigger?: TextareaProps['trigger'];
  /** The user_id part of the feed that the activity should be posted to  */
  userId?: string;
}>;


export function StatusUpdateForm<
  UT extends DefaultUT = DefaultUT,
  AT extends DefaultAT = DefaultAT,
  CT extends UR = UR,
  RT extends UR = UR,
  CRT extends UR = UR,
  PT extends UR = UR
>({
  feedGroup = 'user',
  activityVerb = 'post',
  modifyActivityData,
  emojiData,
  emojiI18n,
  Header,
  Left,
  FooterItem,
  Textarea = DefaultTextarea,
  trigger,
  doRequest,
  userId,
  onSuccess,
  style,
  className,
}: StatusUpdateFormProps<AT>) {
  const { t } = useTranslationContext();
  const state = useStatusUpdateForm<UT, AT, CT, RT, CRT, PT>({
    feedGroup,
    activityVerb,
    modifyActivityData,
    doRequest,
    userId,
    onSuccess,
  });

  return (
    <Panel style={style} className={className}>
      <form onSubmit={state.onSubmitForm} style={{
        padding: '32px 24px',
      }}>
        <ImageDropzone handleFiles={state.uploadNewFiles}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }} >
            <PanelHeading style={{
              padding: 0,
              border: 'none',
            }}>{Header ?? <Title><>
              {t('New Post')}
            </>
            </Title>}</PanelHeading>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              padding: 0,
              gap: '16px',
            }}>
              {Left ?? (state.userData.profileImage && (
                <div style={{ minWidth: 'fit-content' }} >
                  <Avatar image={state.userData.profileImage} size={40} circle />
                </div>
              ))}
              <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}>
                <PanelContent style={{ padding: 0 }}>
                  <div style={{ display: 'flex' }}>
                    {smartRender(Textarea, {
                      emojiData,
                      innerRef: state.textInputRef,
                      onChange: state.onChange,
                      onPaste: state.onPaste,
                      placeholder: t('Type your reply'),
                      trigger,
                      value: state.text,
                    })}
                  </div>

                  {state.isOgScraping && (
                    <div className="raf-status-update-form__og-loading">
                      <LoadingIndicator />{" "}<>
                        {t('Getting website data...')}
                      </>
                    </div>
                  )}

                  {state.activeOg && (
                    <div style={{ margin: '8px 0' }}>
                      {!state.activeOg.videos && !state.activeOg.audios ? (
                        <Card nolink handleClose={state.dismissOg} {...state.activeOg} />
                      ) : (
                        <>
                          {!!state.activeOg.videos && <Video og={state.activeOg} handleClose={state.dismissOg} />}
                          {!!state.activeOg.audios && <Audio og={state.activeOg} handleClose={state.dismissOg} />}
                        </>
                      )}
                    </div>
                  )}

                  {state.availableOg && state.availableOg.length > 1 && (
                    <ol className="raf-status-update-form__url-list">
                      {state.availableOg.map(({ url, title }) => (
                        <li
                          onClick={() => state.setActiveOg(url as string)}
                          key={url}
                          className={`raf-status-update-form__url-list-item${url === state.ogActiveUrl ? ' raf-status-update-form__url-list-item--active' : ''
                            }`}
                        >
                          <BookmarkIcon
                            style={{
                              width: '0.75em',
                              verticalAlign: '-0.125em',
                            }}
                          />{' '}
                          {title !== undefined ? title : url}
                        </li>
                      ))}
                    </ol>
                  )}

                  {state.images.order.length > 0 && (
                    <ImagePreviewer
                      imageUploads={state.images.order.map((id) => state.images.data[id]) as ImageUpload[]}
                      handleRemove={state.removeImage}
                      handleRetry={(id) => state.uploadImage(id, state.images.data[id])}
                      handleFiles={state.uploadNewFiles}
                    />
                  )}

                  {state.files.order.length > 0 && (
                    <FilePreviewer
                      uploads={state.files.order.map((id) => state.files.data[id]) as FileUpload[]}
                      handleRemove={state.removeFile}
                      handleRetry={(id) => state.uploadFile(id, state.files.data[id])}
                      handleFiles={state.uploadNewFiles}
                    />
                  )}
                </PanelContent>

                <PanelFooter style={{ padding: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                      <div style={{ marginRight: '32px', display: 'inline-block' }}>
                        <ImageUploadButton resetOnChange handleFiles={state.uploadNewFiles} multiple>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{
                            color: '#6B7280'
                          }}>
                            <path
                              d="M4.7999 3.59998C4.16338 3.59998 3.55293 3.85283 3.10285 4.30292C2.65276 4.75301 2.3999 5.36346 2.3999 5.99998V18C2.3999 18.6365 2.65276 19.2469 3.10285 19.697C3.55293 20.1471 4.16338 20.4 4.7999 20.4H19.1999C19.8364 20.4 20.4469 20.1471 20.897 19.697C21.347 19.2469 21.5999 18.6365 21.5999 18V5.99998C21.5999 5.36346 21.347 4.75301 20.897 4.30292C20.4469 3.85283 19.8364 3.59998 19.1999 3.59998H4.7999ZM19.1999 18H4.7999L9.5999 8.39998L13.1999 15.6L15.5999 10.8L19.1999 18Z"
                              fill="currentColor"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </ImageUploadButton>
                      </div>
                      <div style={{ marginRight: '32px', display: 'inline-block' }}>
                        <FileUploadButton handleFiles={state.uploadNewFiles} multiple >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{
                            color: '#6B7280'
                          }}>
                            <path
                              d="M9.6001 4.80002C8.64532 4.80002 7.72964 5.17931 7.05451 5.85444C6.37938 6.52957 6.0001 7.44525 6.0001 8.40002V13.2C6.0001 14.7913 6.63224 16.3174 7.75746 17.4427C8.88267 18.5679 10.4088 19.2 12.0001 19.2C13.5914 19.2 15.1175 18.5679 16.2427 17.4427C17.368 16.3174 18.0001 14.7913 18.0001 13.2V8.40002C18.0001 8.08176 18.1265 7.77654 18.3516 7.5515C18.5766 7.32645 18.8818 7.20002 19.2001 7.20002C19.5184 7.20002 19.8236 7.32645 20.0486 7.5515C20.2737 7.77654 20.4001 8.08176 20.4001 8.40002V13.2C20.4001 14.3031 20.1828 15.3954 19.7607 16.4146C19.3385 17.4337 18.7198 18.3597 17.9398 19.1397C17.1598 19.9197 16.2338 20.5385 15.2146 20.9606C14.1955 21.3828 13.1032 21.6 12.0001 21.6C10.897 21.6 9.80469 21.3828 8.78556 20.9606C7.76642 20.5385 6.84041 19.9197 6.0604 19.1397C5.28039 18.3597 4.66165 17.4337 4.23951 16.4146C3.81737 15.3954 3.6001 14.3031 3.6001 13.2V8.40002C3.6001 6.80873 4.23224 5.2826 5.35746 4.15738C6.48268 3.03217 8.0088 2.40002 9.6001 2.40002C11.1914 2.40002 12.7175 3.03217 13.8427 4.15738C14.968 5.2826 15.6001 6.80873 15.6001 8.40002V13.2C15.6001 14.1548 15.2208 15.0705 14.5457 15.7456C13.8706 16.4207 12.9549 16.8 12.0001 16.8C11.0453 16.8 10.1296 16.4207 9.45451 15.7456C8.77938 15.0705 8.4001 14.1548 8.4001 13.2V8.40002C8.4001 8.08176 8.52653 7.77654 8.75157 7.5515C8.97661 7.32645 9.28184 7.20002 9.6001 7.20002C9.91836 7.20002 10.2236 7.32645 10.4486 7.5515C10.6737 7.77654 10.8001 8.08176 10.8001 8.40002V13.2C10.8001 13.5183 10.9265 13.8235 11.1516 14.0486C11.3766 14.2736 11.6818 14.4 12.0001 14.4C12.3184 14.4 12.6236 14.2736 12.8486 14.0486C13.0737 13.8235 13.2001 13.5183 13.2001 13.2V8.40002C13.2001 7.44525 12.8208 6.52957 12.1457 5.85444C11.4706 5.17931 10.5549 4.80002 9.6001 4.80002V4.80002Z"
                              fill="currentColor"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </FileUploadButton>
                      </div>
                      <EmojiPicker onSelect={state.onSelectEmoji} emojiData={emojiData} i18n={emojiI18n} style={{
                        color: '#6B7280'
                      }} />
                      {FooterItem}
                    </div>

                    <Button type="submit" buttonStyle="primary" loading={state.submitting} disabled={!state.canSubmit()}>
                      <>
                        {t('Post')}
                      </>
                    </Button>
                  </div>
                </PanelFooter>
              </div>
            </div>
          </div>
        </ImageDropzone>
      </form>
    </Panel>
  );
}
