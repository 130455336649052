import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../store/hook';
import { ForgotPassword } from '../store/reducer/user/action';
import { toast } from 'react-toastify';
import FullPageLoader from '../_common/helper/FullPageLoader';
import { LOGIN_VERIFICATION, LOGO_IMAGE } from '../config';

const LostPassword = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const hostname = window.location;
    const onSubmit = (data) => {
        setLoading(true);
        const email_verification = hostname.origin + '/reset-password';
        let param = {          
            ...data,
            "changePasswordBaseURL": email_verification,
            "IsPublicEnvironment": !LOGIN_VERIFICATION
        };
        
        dispatch(ForgotPassword(param))
            .unwrap()
            .then((fulfilledAction) => {   
                //console.log(fulfilledAction);            
                if (fulfilledAction.success === true) {              
                   
                    setLoading(false);
                    toast.success(fulfilledAction.message);
                    reset();
                    navigate('/'); // /login
                } else {
                    setLoading(false);
                    toast.error(fulfilledAction.message);

                }

            })
            .catch((rejectedAction) => {
                setLoading(false);
                //console.log('rejectedAction:', rejectedAction);
                toast.error('Incorrect username or password. Please try again.');

            });
    }
    return (
        <React.Fragment>
            {loading && <FullPageLoader />}
            <div className="login_page">
                <div className="loin_area">
                    <div className="login_logo">
                        <Link to='/'><img src={LOGO_IMAGE} /></Link>
                    </div>
                    <h3>Welcome!</h3>
                    <p>Lost your password?</p>
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="login_content">
                            <div className="loin_input">
                                <input type="text" {...register("email", { required: true })} placeholder="Email Address" className="input_box" />
                                <span className="material-symbols-outlined">person</span>
                            </div>
                            {errors.email && <span className="rf-field-validation">Enter the mail address</span>}

                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-3">

                            <div className="remember_txt">
                            <p className="dont_account"><Link to='/'>Back To Login</Link></p>
                                {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">&nbsp;Remember Me</label> */}
                            </div>
                            <button className="btn btn-danger signin_btn" type="submit">Submit</button>
                        </div>
                    </form>
                    {/* <p className="dont_account mt-3">Don't have an account? <Link to='/sign-up'>Sign Up</Link></p> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default LostPassword