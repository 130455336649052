import {
  ElementOrComponentOrLiteralType,
  OnClickUserHandler,
  PropsWithElementAttributes,
  humanizeTimestamp,
  smartRender,
  textRenderer,
  useOnClickUser,
} from '../utils';
import { EnrichedReaction, UR } from 'getstream';

import { Avatar } from './Avatar';
import { DefaultUT } from '../context/StreamApp';
import { Flex } from './Flex';
import React from 'react';
import classNames from 'classnames';
import { useTranslationContext } from '../context';

export type CommentItemProps<
  UT extends DefaultUT = DefaultUT,
  RT extends UR = UR,
  CRT extends UR = UR
> = PropsWithElementAttributes<
  {
    comment: EnrichedReaction<RT, CRT, UT>;
    onClickUser?: OnClickUserHandler<UT>;
    HeaderRight?: ElementOrComponentOrLiteralType;
    Footer?: ElementOrComponentOrLiteralType;
    Content?: ElementOrComponentOrLiteralType;
  } & Partial<Record<'onClickMention' | 'onClickHashtag', (word: string) => void>>
>;

export const CommentItem = <UT extends DefaultUT = DefaultUT, RT extends UR = UR, CRT extends UR = UR>({
  comment,
  onClickHashtag,
  onClickMention,
  onClickUser,
  className,
  style,
  HeaderRight,
  Footer,
  Content
}: CommentItemProps<UT, RT, CRT>) => {
  const { user, created_at, data } = comment;
  const { tDateTimeParser } = useTranslationContext();

  const handleUserClick = useOnClickUser<UT, SVGSVGElement | HTMLSpanElement>(onClickUser);

  return (
    <div className={classNames('raf-comment-item', className)} style={style}>
      <Flex a="flex-start" style={{ padding: '8px 0' }}>
        {user?.data.profileImage && (
          <Avatar onClick={handleUserClick?.(user)} image={user.data.profileImage} circle size={25} />
        )}
      </Flex>
      <Flex d="column" style={{ flex: 1, margin: '0 0 0 8px' }}>
        <div className="raf-comment-item__content">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
            <span onClick={handleUserClick?.(user)} className="raf-comment-item__author">
              {user?.data.name}
            </span>
            <div style={{ flexGrow: 1 }} />
            <time dateTime={created_at} title={created_at}>
              <small>{humanizeTimestamp(created_at, tDateTimeParser)}</small>
            </time>
            {smartRender(
              HeaderRight,
              {},
              <></>,
            )}
          </div>

          {smartRender(
            Content,
            {},
            <p>
              {textRenderer(data.text as string, 'raf-comment-item', onClickMention, onClickHashtag)}
            </p>,
          )}
        </div>
        {smartRender(
          Footer,
          {},
          <></>,
        )}
      </Flex>
    </div>
  );
};
