import AccessDenied from '../pages/AccessDenied';
import AccessTwofa from '../pages/AccessTwofa';
import AddMyPin from '../pages/AddMyPin';
import BusinessSingnup from '../pages/BusinessSingnup';
import BusinessSingnupSecondStep from '../pages/BusinessSingnupSecondStep'
import BusinessSingnupThirdStep from '../pages/BusinessSingnupThirdStep'
import Details from '../pages/Details';
import EditMyPin from '../pages/EditMyPin';
import EmailVerification from '../pages/EmailVerification';
import HomePage from '../pages/HomePage';
import Login from '../pages/Auth/Login';
import LostPassword from '../pages/LostPassword';
import MyBoardDetails from '../pages/MyBoardDetails';
import MyExpertise from '../pages/MyExpertise';
import MyPin from '../pages/MyPin';
import MyWisePinList from '../pages/MyWisePinList';
import MyprofilePage from '../pages/MyprofilePage';
import React from 'react'
import RestPassword from '../pages/RestPassword';
import SettingsProfile from '../pages/SettingsProfile';
import SignUp from '../pages/SignUp';
import TheClub from '../pages/TheClub';
import UserApproval from '../pages/UserApproval';
import ViewPin from '../pages/ViewPin';
import ClerkLogin from '../pages/Auth/ClerkLogin';
import ClerkSignUp from '../pages/Auth/ClerkSignUp';

export const URLS = {
    LOGIN: '/',
    HOME: '/home',    
    SIGNUP: '/sign-up',    
    FORGOT_PASSWORD: '/lost-password',    
    CLUB: '/the-club',    
}

export const publicpages = [
    { path: URLS.LOGIN, component: process.env.REACT_APP_CLERK_LOGIN == 'true' ?ClerkLogin : Login}, 
    { path: URLS.SIGNUP, component: process.env.REACT_APP_CLERK_LOGIN == 'true' ? ClerkSignUp : BusinessSingnup }, 
    { path: "/lost-password", component: LostPassword },    
    { path: "/details", component: Details },
    { path: "/email-verification", component: EmailVerification }, 
    { path: "/reset-password", component: RestPassword },
    { path: "/access-denied", component: UserApproval },
    { path: "/permission-denied", component: AccessDenied },
];

export const privatepages = [    
    { path: "/my-profile", component: MyprofilePage },
    { path: "/add-pin", component: AddMyPin },
    { path: "/my-pin", component: MyPin },
    { path: "/settings", component: SettingsProfile },
    { path: "/my-expertise", component: MyExpertise },
    { path: "/edit-pin/:id", component: EditMyPin },
    { path: "/view-pin/:id", component: ViewPin },
    { path: "/access-twofa", component: AccessTwofa },
    { path: URLS.HOME, component: HomePage },
    { path: "/my-save-pins", component: MyWisePinList },
    { path: "/my-board-pins/:id", component: MyBoardDetails },
    { path: URLS.CLUB, component: TheClub },
];

export const rgistrationpages = [
    { path: "/profile-customization", component: BusinessSingnupSecondStep },
    { path: "/expertise", component: BusinessSingnupThirdStep },
];



