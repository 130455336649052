import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Masonry from 'masonry-layout';
import TopHeader from '../components/Header/TopHeader';
import Footer from '../components/Footer/Footer';
import { HomePageAllPins, GetUserEngagement, PinLikes } from '../store/reducer/pin/action';
import { useAppDispatch } from '../store/hook';
import FullPageLoader from '../_common/helper/FullPageLoader';
import debounce from 'lodash/debounce';
import imagesLoaded from 'imagesloaded';
import { getUserStore, formatNumberAbbreviation } from '../_common/helper/LocalStorage';
import queryString from 'query-string';
import { Waypoint } from 'react-waypoint';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const HomePage = () => {
    const [loading, setLoading] = useState(true);
    const [spinLoading, setSpinLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(true);
    const dispatch = useAppDispatch();
    const storeData = getUserStore();
    const masonryRef = useRef(null);
    const [totalImageCount, setImageCount] = useState(0);
    const [pinAllData, setPinAllData] = useState([]);
    const [pinAllDataPages, setPinAllDataPages] = useState(null);
    const location = useLocation();
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const history = [];
    const itemsPerPage = 20;
    const [engagement, setEngagement] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [pinValue, setPinValue] = useState(0);
    const [pinTitle, setPinTitle] = useState('');
    const hostname = window.location;

    const _user = getUserStore();
    const userId = _user?.userID;

    const totalPages = Math.ceil(totalImageCount / itemsPerPage);

    const initializeMasonry = () => {
        const masonry = new Masonry(masonryRef.current, {
            itemSelector: '.grid-item',
            percentPosition: true,
        });

        const handleResize = debounce(() => {
            masonry.layout();
        }, 200);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            masonry.destroy();
        };
    };

    const fetchPins = (page) => {
        let getPin = {};
        page = page;
        if (location?.state?.name === 'date' || location?.state?.name === 'view') {
            getPin = {
                RoleId: 3,
                SortColumn: location?.state?.name,
                SortOrder: location?.state?.order,
                IsPublished: 1,
                PageNumber: page,
                PageSize: itemsPerPage,
            };

        } else if (location?.state?.name === 'search') {
            getPin = {
                RoleId: 3,
                SearchedBy: storeData.userID,
                SearchText: location?.state?.value,
                IsPublished: 1,
                PageNumber: page,
                PageSize: itemsPerPage,
            };

        } else if (location?.state?.name === 'filter') {

            getPin = {
                RoleId: 3,
                CategoryId: location?.state?.catIds,
                StartDate: location?.state?.fromDate,
                EndDate: location?.state?.toDate,
                SortOrder: location?.state?.order,
                IsPublished: 1,
                PageNumber: page,
                PageSize: itemsPerPage,
            };

        } else {
            getPin = {
                RoleId: 3,
                SortColumn: 'date',
                SortOrder: 'desc',
                IsPublished: 1,
                PageNumber: page,
                PageSize: itemsPerPage,
            };


        }

        setSpinLoading(true)
        setDataLoading(true)
        dispatch(HomePageAllPins(getPin)).then((fulfilledAction) => {
            let _pinAll = fulfilledAction.payload.data.objListPin;
            setImageCount(fulfilledAction.payload.totalRecords);
            let data = {};
            data[`page${page}`] = _pinAll;

            if (pinAllDataPages) {
                setPinAllDataPages({ ...pinAllDataPages, ...data })
            } else {
                setPinAllDataPages(data)
            }


            setLoading(false);
            setSpinLoading(false)
            setDataLoading(false);
        }).catch((error) => {
            setLoading(false);
            setSpinLoading(false)
            setDataLoading(false);
            console.error('Error fetching expertise data:', error);
        });
    };

    const loadPinsForPageRange = (range) => {
        const [start, end] = range.split('-').map(Number);
        for (let page = start; page <= end; page++) {
            fetchPins(page);
        }
    };

    const handleWaypointEnter = () => {
        if (currentPage < totalPages && !dataLoading) {
            setCurrentPage(currentPage + 1);
        }
    };

    const getLikeStatus = (pinID) => {
        if (engagement.length > 0) {
            const engagementItem = engagement.find(item => item.pinsId === pinID);
            if (engagementItem) {
                return engagementItem.userLiked;
            } else {
                return false;
            }
        }
    };

    const closeModal = () => {
        setShowModal(false);
    }

    const openModal = (_pinTitle, _pinid) => {
        setPinTitle(_pinTitle)
        setPinValue(_pinid)
        setShowModal(true);
        let newPagesData = {};
        Object.keys(pinAllDataPages).map((pageKey) => {
            let pins = pinAllDataPages[pageKey];
            pins.map((pin) => {
                if (pin.pinId == _pinid) {
                    pin.totalViews = pin.totalViews + 1
                }
                return pin
            })
            newPagesData[pageKey] = pins;
        })
        setPinAllDataPages(newPagesData)


    }


    const handleLike = (id) => {
        const likeParam = {
            "pinsId": id,
            "userId": userId,
        }

        dispatch(PinLikes(likeParam)).unwrap().then((fulfilledAction) => {
            if (fulfilledAction.success === true) {
                toast.success(fulfilledAction.message);
                getUserEngagement();
                let newPagesData = {};
                Object.keys(pinAllDataPages).map((pageKey) => {
                    let pins = pinAllDataPages[pageKey];
                    pins.map((pin) => {
                        if (pin.pinId == id) {
                            pin.totalLikes = fulfilledAction?.data?.totalLikes;
                        }
                        return pin
                    })
                    newPagesData[pageKey] = pins;
                })
                setPinAllDataPages(newPagesData)
            } else {
                toast.error(fulfilledAction.message);
            }
            


        }).catch((rejectedAction) => {
            toast.error('Error occurred while uploading.');
        });

    };

    const getUserEngagement = ()=>{
        const engagementParam = {
            UserId: storeData.userID,
            UserLiked: true
        };

        dispatch(GetUserEngagement(engagementParam)).then((fulfilledAction) => {
            const _engagement = fulfilledAction.payload;
            setEngagement(_engagement);
        }).catch((error) => {
            console.error('Error fetching related pins:', error);
        });
    }

    useEffect(() => {
        const { range } = queryString.parse(location.search);
        if (range) {
            loadPinsForPageRange(range);
            setCurrentPage(range.split('-')[1]); // Set the current page based on the range
        } else {
            fetchPins(currentPage);
        }
    }, [location, currentPage]);

    useEffect(() => {
        setPinAllData([])
        setPinAllDataPages({})
        setCurrentPage(1);
    }, [location?.state?.name])

    useEffect(() => {
        if (pinAllDataPages) {
            let list = [];
            Object.keys(pinAllDataPages).map((key) => {
                list = [...list, ...pinAllDataPages[key]]
            })
            setPinAllData(list);
            initializeMasonry();
        }
    }, [pinAllDataPages]);

    useEffect(() => {
        getUserEngagement();
    }, []);


    return (
        <div style={{ overflowY: 'scroll', height: '100vh' }}>
            {/* loading && <FullPageLoader /> */}
            <TopHeader />

            <div className="masonry-container" ref={masonryRef}>
                <div className="container-fluid">
                    <div
                        className="row grid"
                        data-masonry="{&quot;percentPosition&quot;: true }"
                        style={{ position: "relative", minHeight: "690px" }}
                    >
                        {loading ? (
                            null
                        ) : (
                            <React.Fragment>
                                {pinAllData?.length > 0 ? (
                                    pinAllData?.map((item, index) => (
                                        <div key={index} className="grid-item col-sm-6 col-lg-2 mb-2">
                                            <div className="card">
                                                <div className='home-card' onClick={() => openModal(item.pinTitle, item.pinId)}>
                                                    <img
                                                        src={item?.pinImage ? item?.pinImage : `/assets/images/no-image-${(index%2) + 1}.png`}
                                                        alt="image"
                                                        data-src={item?.pinImage}
                                                       /*  loading="lazy" */
                                                        onLoad={() => { initializeMasonry() }}
                                                    />
                                                    <div className="verified-icon">
                                                        {item?.isApproved === 7 && <img src="/assets/images/verify.png" alt="Verified" />}
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    {/* <Link to={`/view-pin/${item.pinId}`} className='home-title-link'> */}
                                                    <Link to='#' className='home-title-link' onClick={() => openModal(item.pinTitle, item.pinId)}>
                                                        <h5 className="card-title text-truncate">{item.pinTitle}</h5>
                                                    </Link>
                                                    <div className="bill-body-text-like-inner-sec p-0">
                                                        <ul>
                                                            <li>
                                                                <Link to='#' className={getLikeStatus(item.pinId) ? 'active' : ''} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleLike(item.pinId)
                                                                }}>
                                                                    <span className="material-symbols-outlined">thumb_up</span>{formatNumberAbbreviation(item.totalLikes)}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={(e) => e.preventDefault()}>
                                                                    <span className="material-symbols-outlined">visibility</span> {formatNumberAbbreviation(item.totalViews)}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={(e) => e.preventDefault()}>
                                                                    <span className="material-symbols-outlined">message</span> {formatNumberAbbreviation(item.totalComments)}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <React.Fragment>
                                        {dataLoading === true ? (
                                            null
                                        ) : (
                                            <div className="text-center">No data found!</div>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>

            {/* Waypoint component to handle lazy loading more items */}
            {(currentPage < totalPages || dataLoading) && (
                <React.Fragment>
                    <Waypoint onEnter={handleWaypointEnter} />
                    <div className="text-center">
                        <RotatingLines
                            strokeColor="#b32f84"
                            strokeWidth={5}
                            animationDuration={0.75}
                            width={40}
                            visible={true}
                        />
                        <br />
                        Loading..
                    </div>
                </React.Fragment>
            )}

            {showModal && (
                <div className={`modal fade rf-modal ${showModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="commentModalLabel">{pinTitle} Details Page</h5>
                                <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
                            </div>

                            <div className="modal-body overflow-hidden">
                                <iframe src={`${hostname.origin}/view-pin/${pinValue}?home=yes`} className='w-100 h-100'></iframe>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-danger" onClick={closeModal}>Back To Test Flight Previous Page</button>

                            </div>

                        </div>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
};

export default HomePage;
