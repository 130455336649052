import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateProfileImage, getUserProfile } from '../../store/reducer/user/action';

const ProfileImage = () => {
  const _user = JSON.parse(localStorage.getItem('user'));
  const userId = _user?.userId;
  const userProfileId = localStorage.getItem('_rfprofile');
  const items = useSelector((state) => state.userProfileImage);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(items?.imageData?.data?.profilePic);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);
    } else {
      alert('Please select an image file.');
    }
  };

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('UserProfileId', userProfileId);
      formData.append('Image', selectedFile);

      dispatch(updateProfileImage(formData))
        .unwrap()
        .then((fulfilledAction) => {
          const profilePicUrl = fulfilledAction.data.profilePic;
          setImageUrl(profilePicUrl);
          if (fulfilledAction.success === true) {
            toast.success(fulfilledAction.message);
          } else {
            toast.error(fulfilledAction.message);
          }
        })
        .catch((rejectedAction) => {
          
        });

      closeModal();
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error occurred while uploading.');
    }
  }
  //console.log('imageUrl__',items?.imageData)
  useEffect(() => {
    //console.log('imageUrl___', items?.imageData)
    if (items?.imageData === null) {
      dispatch(getUserProfile(userProfileId))
        .then((fulfilledAction) => {
          const data = fulfilledAction.payload.data;
          //console.log('data____', data)
          if (!imageUrl) {
            setImageUrl(data?.profilePic);
          }
        })
        .catch((error) => {
          console.error('Error fetching expertise data:', error);
        });
    }
  }, [items]);
  return (
    <>
      <div className="profile_pic" onClick={openModal}>
        {imageUrl ? (
          <img src={imageUrl} id='upload_profile_image' alt='Profile' />
        ) : (
          <img src='/assets/images/avatar.jpg' id='upload_profile_image' alt='Default' />
        )}
        <div className="edit_profpic">
          <span className="material-symbols-outlined">photo_camera</span>
        </div>
      </div>
      <h3>{_user?.firstName} {_user?.lastName}</h3>
      {/* <p>New York, USA</p> */}

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Profile Picture</h5>
              <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className='mb-3'>
                  <div id="dropzone">
                    <div
                      className="image-dropzone w-100"
                      onDragOver={(e) => e.preventDefault()}
                      onClick={() => document.getElementById('upload-image-input').click()}
                    >
                      <div className="dz-message needsclick">
                        {selectedFile ? (
                          <div></div>
                        ) : (
                          <>
                            <span className="material-symbols-outlined">cloud_upload</span>
                            <div className="note needsclick">Click to upload image (JPEG or PNG) to be used for profile image.</div>
                          </>
                        )}
                      </div>
                      {selectedFile ? (
                        <img src={URL.createObjectURL(selectedFile)} alt="Selected" />
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <input
                      id="upload-image-input"
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleFileInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                <button type="submit" className="btn btn-primary">Save changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isModalOpen && <div className="modal-backdrop fade show"></div>
      }
    </>
  );
};

export default ProfileImage;
