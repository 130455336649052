import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { UserExpertiseAction, AddUserExpertiseAction } from '../store/reducer/user/action';
import TopHeader from '../components/Header/TopHeader'
import Footer from '../components/Footer/Footer'
import { useDispatch, useSelector } from 'react-redux';
import FullPageLoader from '../_common/helper/FullPageLoader';



const BusinessSingnupThirdStep = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [groupedSubcategories, setGroupedSubcategories] = useState({});
    const [selectAllMap, setSelectAllMap] = useState({});
    const [loading, setLoading] = useState(false);
    //const _tempuser = JSON.parse(localStorage.getItem('_tempsecond'));
    
    const userProfileId = localStorage.getItem('_rfprofile');

    useEffect(() => {
        dispatch(UserExpertiseAction())
            .then((fulfilledAction) => {
                // Handle successful fetch here (fulfilledAction)
                //console.log('Fetched expertise data:', fulfilledAction.payload);
                // Group subcategories by category name
                const groupedData = groupSubcategoriesByCategory(fulfilledAction.payload);
                setGroupedSubcategories(groupedData);
                // Initialize selectAllMap with false for each category
                const initialSelectAllMap = {};
                Object.keys(groupedData).forEach((categoryName) => {
                    initialSelectAllMap[categoryName] = false;
                });
                setSelectAllMap(initialSelectAllMap);
            })
            .catch((error) => {
                // Handle fetch error here (error)
                console.error('Error fetching expertise data:', error);
            });
    }, [dispatch]);

    // Function to group subcategories by category name
    const groupSubcategoriesByCategory = (data) => {
        const groupedData = {};
        if (data && Array.isArray(data.data)) {
            data.data.forEach((category) => {
                const categoryName = category.categoryName;
                const subcategories = category.subCategories;
                groupedData[categoryName] = subcategories;
            });
        } else {
            console.error('Data is not in the expected structure:', data);
        }
        return groupedData;
    };

    // Toggle all checkboxes in a category
    //   const toggleSelectAll = (categoryName) => {
    //     const updatedSelectAllMap = { ...selectAllMap };
    //     updatedSelectAllMap[categoryName] = !updatedSelectAllMap[categoryName];
    //     setSelectAllMap(updatedSelectAllMap);
    //   };

    // const toggleSelectAll = (categoryName) => {
    //     const updatedSelectAllMap = { ...selectAllMap };
    //     updatedSelectAllMap[categoryName] = !updatedSelectAllMap[categoryName];

    //     // Update the checked state of individual subcategory checkboxes
    //     const updatedGroupedSubcategories = { ...groupedSubcategories };
    //     updatedGroupedSubcategories[categoryName] = updatedGroupedSubcategories[categoryName].map((subCategory) => ({
    //         ...subCategory,
    //         isChecked: updatedSelectAllMap[categoryName],
    //     }));

    //     setSelectAllMap(updatedSelectAllMap);
    //     setGroupedSubcategories(updatedGroupedSubcategories);
    // };

    // const handleSubcategoryChange = (categoryName, subCategoryId) => {
    //     const updatedGroupedSubcategories = { ...groupedSubcategories };
    //     const categorySubcategories = updatedGroupedSubcategories[categoryName];

    //     // Find the subcategory within the category by subCategoryId
    //     const subcategory = categorySubcategories.find((sub) => sub.subCategoryId === subCategoryId);

    //     // Toggle the isChecked property for the subcategory
    //     if (subcategory) {
    //         subcategory.isChecked = !subcategory.isChecked;

    //         // Check if all subcategories in the category are checked to update the "Select All" checkbox
    //         const isAllSubcategoriesChecked = categorySubcategories.every((sub) => sub.isChecked);
    //         const updatedSelectAllMap = { ...selectAllMap };
    //         updatedSelectAllMap[categoryName] = isAllSubcategoriesChecked;
    //         setSelectAllMap(updatedSelectAllMap);

    //         // Update the state with the modified subcategories
    //         setGroupedSubcategories(updatedGroupedSubcategories);
    //     }
    // };

    const [selectedCategories, setSelectedCategories] = useState([]);
    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedCategories([...selectedCategories, value]);
        } else {
            setSelectedCategories(selectedCategories.filter((category) => category !== value));
        }
    };

    const handleClick = () => {
        setLoading(true); 
        //console.log('setSelectedCategories', selectedCategories);
        const formattedData = selectedCategories.map((subcategoryId) => ({
            userProfileId: userProfileId,
            categoryId: parseInt(subcategoryId), // Convert subcategoryId to a number if needed
        }));
        //console.log(formattedData);
        //return false;
        try {

            dispatch(AddUserExpertiseAction(formattedData))
                .unwrap()
                .then((fulfilledAction) => {
                    //console.log('fulfilledAction:', fulfilledAction);

                    if (fulfilledAction.success === true) {
                        localStorage.removeItem('_tempuser');
                        //localStorage.removeItem('_tempsecond');
                        toast.success('Your profile was successfully created.');
                        //navigate('/login');
                        navigate('/home');
                    } else {
                        toast.error(fulfilledAction.message);
                    }
                    setLoading(false); 

                })
                .catch((rejectedAction) => {
                    //console.log('rejectedAction:', rejectedAction);
                    setLoading(false); 
                    toast.error('Incorrect username or password. Please try again.');

                });


        } catch (error) {
            console.error('Error:', error);
            setLoading(false); 
            toast.error('Error occurred while uploading.');
        }

    };

    return (
        <>
            {loading && <FullPageLoader />}
            <TopHeader />

            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="step_page">
                                <h3>areas of expertise</h3>
                                <div className="stepcategory_list">
                                    {Object.keys(groupedSubcategories).map((categoryName) => (
                                        <div className="category_list" key={categoryName}>
                                            <h5>{categoryName}</h5>

                                            {groupedSubcategories[categoryName].map((subCategory) => (
                                                <div className="form-check" key={subCategory.subCategoryId}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={subCategory.subCategoryId}
                                                        id={`flexCheck${subCategory.subCategoryId}`}
                                                        onChange={handleCheckboxChange}
                                                    // checked={subCategory.isChecked}
                                                    // onChange={(e) => handleSubcategoryChange(categoryName, subCategory.subCategoryId)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`flexCheck${subCategory.subCategoryId}`}>
                                                        {subCategory.subCategoryName}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    ))}


                                </div>
                                <div className="step_bottomposition">
                                    <div className="text-end">

                                        <button className="btn btn-danger text-uppercase ms-2" onClick={handleClick} >Submit</button>
                                    </div>
                                    <div className="pagebullet">
                                        {/* <ul>
                                            <li><a className="active" href="#">1</a></li>
                                            <li><a href="#">2</a></li>

                                        </ul> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default BusinessSingnupThirdStep