import React, { useEffect, useState } from 'react'
import { SignedOut, useUser, useAuth, SignIn, SignInButton, SignedIn, SignOutButton, SignUp } from "@clerk/clerk-react"
import { URLS } from '../../routes'



const ClerkSignUp = () => {

    return (
        <div className="login_page">
         <SignUp signInUrl={window.location.origin + URLS.LOGIN}></SignUp>
        </div>
    )
}

export default ClerkSignUp