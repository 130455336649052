import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getUserProfile, UpdateProfileAction } from '../store/reducer/user/action';
import TopHeader from '../components/Header/TopHeader'
import Footer from '../components/Footer/Footer'
import { useDispatch } from 'react-redux';
import ProfileImage from '../components/ProfileImage/ProfileImage';
import FullPageLoader from '../_common/helper/FullPageLoader';
import LeftNavMenu from '../components/LeftMenu/LeftNavMenu';
import MaskedInput from 'react-text-mask';
import { phonePattern } from '../_common/helper/LocalStorage';


const MyprofilePage = () => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const _user = JSON.parse(localStorage.getItem('user'));
    const userId = _user?.userId;
    const userProfileId = localStorage.getItem('_rfprofile'); //_user?.userProfileId;
    const [data, setData] = useState(null); // Initialize data state
    const [workForData, setWorkForData] = useState(0);
    const [phoneData, setPhoneData] = useState('');

    //const { register, handleSubmit, control, reset, setValue, getValues, formState: { errors } } = useForm();
    const { register, handleSubmit, control, reset, setValue, getValues, formState: { errors } } = useForm();

    // const phonePattern = [
    //     '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/
    // ];
    useEffect(() => {
        dispatch(getUserProfile(userProfileId))
            .then((fulfilledAction) => {
                const data = fulfilledAction.payload.data;
                setData(data); // Store data in the state        

                reset({
                    'workFor': data.workFor,
                    'FirstName': data ? data.firstName : '',
                    'LastName': data ? data.lastName : '',
                    'PhoneNumber': data ? data.phoneNumber : '',
                    'Email': data ? data.email : '',
                    'DisplayName': data ? data.displayName : '',
                    'CompanyName': data ? data.companyName : '',
                    'Biodata': data ? data.biodata : '',
                    'JobTitle': data ? data.jobTitle : '',
                })
                
                setWorkForData(data.workFor)
                setLoading(false)
            })
            .catch((error) => {
                // Handle fetch error here (error)
                console.error('Error fetching expertise data:', error);
            });
    }, []); // Provide an empty dependency array here


    const onSubmit = (data) => {
        setLoading(true)
        try {
            const formData = new FormData();

            formData.append('UserPofileId', userProfileId);
            formData.append('UserId', userId); // Replace with your user ID
            //formData.append('Image', file); // Append the file
            formData.append('FirstName', data.FirstName);
            formData.append('LastName', data.LastName);
            formData.append('PhoneNumber', data.PhoneNumber);
            formData.append('Email', data.Email);
            formData.append('DisplayName', data.DisplayName);
            formData.append('CompanyName', data.CompanyName);
            formData.append('Biodata', data.Biodata);
            formData.append('JobTitle', data.JobTitle);
            formData.append('WorkFor', workForData);
            //console.log('data',data)
            //return false;
            dispatch(UpdateProfileAction(formData))
                .unwrap()
                .then((fulfilledAction) => {

                    if (fulfilledAction.success === true) {
                        setLoading(false)
                        toast.success(fulfilledAction.message);
                    } else {
                        setLoading(false)
                        toast.error(fulfilledAction.message);

                    }

                })
                .catch((rejectedAction) => {
                    setLoading(false)
                    // toast.error('Error');

                });
        } catch (error) {
            console.error('Error:', error);
        }


    }
    const workforHandler = (data) => {
        setWorkForData(data)
    }

    const handelPhone = (phoneValue) => {
        setPhoneData(phoneValue)
    }
    
  
    return (
        <>
            {loading && <FullPageLoader />}
            <TopHeader />

            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="profile_page step_page p-0">
                        <div className="profile_laft">

                            <ProfileImage />
                            <div className="prof_menue">
                                <LeftNavMenu />

                            </div>
                        </div>
                        <div className="profile_right">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3>User Profile</h3>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">First Name</label>
                                            <input type="text" {...register("FirstName", { required: true })} className="form-control" id="exampleFormControlInput1" maxLength={48} />
                                            {errors.FirstName && <span className="rf-field-validation">First name is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput2" className="form-label">Last Name</label>
                                            <input type="text" {...register("LastName", { required: true })} className="form-control" id="exampleFormControlInput2" maxLength={48} />
                                            {errors.LastName && <span className="rf-field-validation">Last name is required</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput3" className="form-label">Phone Number</label>
                                            {/* <input type="text" {...register("PhoneNumber", { required: true })} className="form-control" id="exampleFormControlInput3" /> */}
                                            <Controller
                                                name={"PhoneNumber"}
                                                control={control}
                                                rules={{
                                                    pattern: {
                                                      value: /^\([1-9]\d{2}\) \d{3}-\d{4}$/,
                                                      message: "Invalid phone number format",
                                                    },
                                                    required: "Phone number is required",
                                                  }}
                                                render={({ field: { value, onChange, onBlur } }) => (
                                                    <MaskedInput
                                                    defaultValue={value}
                                                        mask={phonePattern}
                                                        className="form-control"
                                                        placeholder="Enter a phone number"
                                                        guide={false}                                                        
                                                        onBlur={() => { handelPhone(value)}}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            handelPhone(e.target.value);
                                                          }}
                                                        id="exampleFormControlInput3"
                                                    />
                                                )}
                                            />
                                            {errors.PhoneNumber && <span className="rf-field-validation">{errors.PhoneNumber.message}</span>}
                                            {/* <span>{errors.PhoneNumber && errors.PhoneNumber.message}</span> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput4" className="form-label">Email Address</label>
                                            <input type="text" {...register("Email", { required: true })} readOnly className="form-control" id="exampleFormControlInput4" maxLength={48}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput4" className="form-label">Display Name</label>
                                            <input type="text" {...register("DisplayName", { required: true })} className="form-control" id="exampleFormControlInput5" maxLength={48} />
                                            {errors.DisplayName && <span className="rf-field-validation">Display name is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput4" className="form-label">Company Name</label>
                                            <input type="text" {...register("CompanyName")} className="form-control" id="exampleFormControlInput6" maxLength={148} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput4" className="form-label">Job Title</label>
                                            <input type="text" {...register("JobTitle")} className="form-control" id="exampleFormControlInput7" maxLength={148} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput4" className="form-label">I Work For</label>


                                            <Controller
                                                name="workFor"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <div className="d-flex">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                {...field}
                                                                value={2}
                                                                //defaultChecked={data?.workFor === 2 && true}
                                                                checked={workForData === 2}
                                                                onChange={() => workforHandler(2)}
                                                                type="radio"
                                                                id="flexRadioDefault1"
                                                            />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                Utility
                                                            </label>
                                                        </div>
                                                        <div className="form-check ms-3">
                                                            <input
                                                                className="form-check-input"
                                                                {...field}
                                                                value={3}
                                                                //defaultChecked={data?.workFor === 3  && true}
                                                                onChange={() => workforHandler(3)}
                                                                checked={workForData === 3}
                                                                type="radio"
                                                                id="flexRadioDefault2"
                                                            />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                Solution Provider
                                                            </label>
                                                        </div>
                                                        {/* <div className="form-check ms-3">
                                                            <input
                                                                className="form-check-input"
                                                                {...field}
                                                                value={4}
                                                                type="radio"
                                                                checked={field.value === 4}
                                                                disabled
                                                                id="flexRadioDefault3"
                                                            />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                Other
                                                            </label>
                                                        </div> */}
                                                    </div>
                                                )}
                                            />



                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput4" className="form-label">Bio</label>
                                            <textarea className="form-control" {...register("Biodata")} maxLength={1900} >{data?.biodata}</textarea>

                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-danger text-uppercase" type='submit'>Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </>
    )
}

export default MyprofilePage