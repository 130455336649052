import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { ClerkProvider } from '@clerk/clerk-react'
import { store } from './store/store';
import { ToastContainer } from 'react-toastify';

import reportWebVitals from './reportWebVitals';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<ClerkProvider publishableKey={process.env.REACT_APP_CLERK_KEY}>
				<App />
			</ClerkProvider>
		</Provider>
		<ToastContainer />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();