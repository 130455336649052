import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuthStatus, useRegisterStatus } from '../_common/hooks/authHook';
import { URLS } from './index'

const RegistrationRoute = () => {
    const isAuth = useAuthStatus();
    const isRegister = useRegisterStatus();
    
    return (
        isAuth || isRegister ? <Outlet /> : <Navigate to={URLS.LOGIN} />
        
    )
}

export default RegistrationRoute