import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import axios from 'axios';
import { apiHost } from "../../../config";
import { removeAllUserStore } from "../../../_common/helper/LocalStorage";
import { toast } from "react-toastify";

const getUserToken = () => {
  const _tempuser = JSON.parse(localStorage.getItem('user'));
  const localUserToken = _tempuser?.token;
  return localUserToken;
};




export const AddMyPinAction = createAsyncThunk('pins/insertpins', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token
    //console.log(token);
    //console.log(param);

    const request = await axios.post(`${apiHost}api/PinsAPI/InsertPins`, param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    //console.log('responce', responce)
    return responce;

  } catch (error) {

    throw error;
  }
})

export const GetProfileCategoryMap = createAsyncThunk(
  'pin/getProfileCategoryMap',
  async (param) => {
    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/UserProfileAPI/GetProfileCategoryMap?userProfileId=${param}`,
        { headers } // Pass headers as a separate object
      );

      const responseData = response.data.data; // No need to access response.data.data
      //console.log('responseData', responseData);
      return responseData;
    } catch (error) {
      throw error;
    }
  }
)

export const getAllPins = createAsyncThunk(
  'pins/getAllPins',
  async (param) => {

    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/PinsAPI/GetAllPins?UserId=${param.userId}&RoleId=${param.roleId}`,
        { headers } // Pass headers as a separate object
      );
      const responseData = response.data.data.objListPin; // No need to access response.data.data

      return responseData;
    } catch (error) {
      throw error;
    }
  }
)

export const DeleteMyPinAction = createAsyncThunk('pins/DeletePin', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token    
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
      data: param // The data you want to include in the request body
    };

    const request = await axios.delete(`${apiHost}api/PinsAPI/DeletePin`, config);
    const responce = await request.data;
    //console.log('responce', responce)
    return responce;

  } catch (error) {

    throw error;
  }
})

export const GetPinInfo = createAsyncThunk(
  'pins/EditPin',
  async (param) => {

    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/PinsAPI/EditPin?pinId=${param.pinId}`,
        { headers } // Pass headers as a separate object
      );
      const responseData = response.data.data.objListPin; // No need to access response.data.data

      return responseData;
    } catch (error) {
      throw error;
    }
  }
)

export const UpdatePinAction = createAsyncThunk('user/UpdatePins', async (param) => {


  try {
    const token = getUserToken(); // Get the Bearer token
    const request = await axios.put(`${apiHost}api/PinsAPI/UpdatePins`, param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }

})

export const HomePageAllPins = createAsyncThunk(
  'pins/HomePageAllPins',
  async (param) => {

    const queryParams = [];

    // Iterate through the properties of the `getPin` object and add them to the array
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams.push(`${key}=${encodeURIComponent(param[key])}`);
      }
    }

    // Join the array elements with '&' to create the full query string
    const queryString = queryParams.join('&');

    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/PinsAPI/GetAllPins?${queryString}`,
        { headers } // Pass headers as a separate object
      );
      const responseData = response.data;
      //const responseData = response.data.data.objListPin; // No need to access response.data.data
      if (responseData.message === 'Unauthorized!') {
        removeAllUserStore();
        toast.error('Session expired. Please log in again.');
        setTimeout(() => {
          window.location.reload();
        }, 3000);


      }
      return responseData;
    } catch (error) {
      throw error;
    }
  }
)

export const GetRelatedPins = createAsyncThunk(
  'pins/GetRelatedPins',
  async (param) => {

    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/PinsAPI/GetRelatedPins?PinsId=${param.PinsId}&PageNumber=${param.PageNumber}&PageSize=${param.PageSize}`,
        { headers } // Pass headers as a separate object
      );
      const responseData = response.data.data.objListPin; // No need to access response.data.data

      return responseData;
    } catch (error) {
      throw error;
    }
  }
)

export const GetPinComments = createAsyncThunk(
  'pins/GetPinComments',
  async (param) => {

    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/PinsAPI/GetPinComments?PinsId=${param.PinsId}&PageNumber=${param.PageNumber}&PageSize=${param.PageSize}`,
        { headers } // Pass headers as a separate object
      );
      const responseData = response.data.data.objListPin; // No need to access response.data.data

      return responseData;
    } catch (error) {
      throw error;
    }
  }
)

export const InsertPinComments = createAsyncThunk('pins/InsertPinComments', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token  

    const request = await axios.post(`${apiHost}api/PinsAPI/InsertPinComments`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }
})

export const PinLikes = createAsyncThunk('pins/PinLikes', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token  

    const request = await axios.post(`${apiHost}api/PinsAPI/PinLikes`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    //console.log('responce', responce)
    return responce;

  } catch (error) {

    throw error;
  }
})

export const PinViews = createAsyncThunk('pins/PinViews', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token  

    const request = await axios.post(`${apiHost}api/PinsAPI/PinViews`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    //console.log('responce', responce)
    return responce;

  } catch (error) {

    throw error;
  }
})

export const GetPinSearchHistory = createAsyncThunk(
  'pins/GetPinSearchHistory',
  async (param) => {

    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/PinsAPI/GetPinSearchHistory?UserId=${param.UserId}&SearchText=${param.SearchText}&NoOfData=${param.NoOfData}`,
        { headers } // Pass headers as a separate object
      );
      const responseData = response.data.data; // No need to access response.data.data

      return responseData;
    } catch (error) {
      throw error;
    }
  }
)

export const GetPinReviews = createAsyncThunk(
  'pins/GetPinReviews',
  async (param) => {

    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/PinsAPI/GetPinReviews?PinsId=${param.PinsId}`,
        { headers } // Pass headers as a separate object
      );
      const responseData = response.data.data; // No need to access response.data.data

      return responseData;
    } catch (error) {
      throw error;
    }
  }
)

export const GetUserEngagement = createAsyncThunk('pins/GetUserEngagement', async (param) => {

  const queryParams = [];
  for (const key in param) {
    if (param.hasOwnProperty(key)) {
      queryParams.push(`${key}=${encodeURIComponent(param[key])}`);
    }
  }
  const queryString = queryParams.join('&');

  try {
    const token = getUserToken(); // Get the Bearer token
    const headers = {
      Authorization: `Bearer ${token}`, // Set the Bearer token
    };

    const response = await axios.get(
      `${apiHost}api/PinsAPI/GetUserEngagement?${queryString}`,
      { headers } // Pass headers as a separate object
    );
    const responseData = response.data.data; // No need to access response.data.data

    return responseData;
  } catch (error) {
    throw error;
  }
}
)

export const BulkFileUpload = createAsyncThunk('pins/BulkFileUpload', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token    

    const request = await axios.post(`${apiHost}api/PinsAPI/BulkFileUpload`, param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    //console.log('responce', responce)
    return responce;

  } catch (error) {

    throw error;
  }
})

// export const WishPin = createAsyncThunk('pins/WishPin', async (param) => {

//   try {
//     const token = getUserToken(); // Get the Bearer token  

//     const request = await axios.post(`${apiHost}api/PinsAPI/WishPin`, param, {
//       headers: {
//         'Authorization': `Bearer ${token}` // Set the Bearer token
//       },
//     });
//     const responce = await request.data;    
//     return responce;

//   } catch (error) {

//     throw error;
//   }
// })

// export const GetWishedPins = createAsyncThunk(
//   'pins/GetWishedPins',
//   async (param) => {

//     const queryParams = [];
//     for (const key in param) {
//       if (param.hasOwnProperty(key)) {
//         queryParams.push(`${key}=${encodeURIComponent(param[key])}`);
//       }
//     }
//     const queryString = queryParams.join('&');

//     try {
//       const token = getUserToken(); // Get the Bearer token
//       const headers = {
//         Authorization: `Bearer ${token}`, // Set the Bearer token
//       };

//       const response = await axios.get(
//         `${apiHost}api/PinsAPI/GetWishedPins?${queryString}`,
//         { headers } // Pass headers as a separate object
//       );
//       const responseData = response.data; // No need to access response.data.data

//       return responseData;
//     } catch (error) {
//       throw error;
//     }
//   }
// )

export const InsertUserBoard = createAsyncThunk('pins/InsertUserBoard', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token    

    const request = await axios.post(`${apiHost}api/UserBoardAPI/InsertUserBoard`, param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }
})

export const GetAllUserBoard = createAsyncThunk('pins/GetAllUserBoard', async (param) => {

  const queryParams = [];
  for (const key in param) {
    if (param.hasOwnProperty(key)) {
      queryParams.push(`${key}=${encodeURIComponent(param[key])}`);
    }
  }
  const queryString = queryParams.join('&');

  try {
    const token = getUserToken(); // Get the Bearer token
    const headers = {
      Authorization: `Bearer ${token}`, // Set the Bearer token
    };

    const response = await axios.get(
      `${apiHost}api/UserBoardAPI/GetAllUserBoard?${queryString}`,
      { headers } // Pass headers as a separate object
    );
    const responseData = response.data; // No need to access response.data.data

    return responseData;
  } catch (error) {
    throw error;
  }
}
)

export const UpdateUserBoard = createAsyncThunk('pins/UpdateUserBoard', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token    

    const request = await axios.put(`${apiHost}api/UserBoardAPI/UpdateUserBoard`, param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;

    return responce;

  } catch (error) {

    throw error;
  }
})

export const DeleteUserBoard = createAsyncThunk('pins/DeleteUserBoard', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token    
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
      data: param // The data you want to include in the request body
    };

    const request = await axios.delete(`${apiHost}api/UserBoardAPI/DeleteUserBoard`, config);
    const responce = await request.data;

    return responce;

  } catch (error) {

    throw error;
  }
})

export const InsertUserBoardPinMapData = createAsyncThunk('pins/InsertUserBoardPinMapData', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token    

    const request = await axios.post(`${apiHost}api/UserBoardAPI/InsertUserBoardPinMapData`, param, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }
})

export const GetUserBoardPinMapDataByPinId = createAsyncThunk(
  'pins/GetUserBoardPinMapDataByPinId',
  async (param) => {

    const queryParams = [];
    for (const key in param) {
      if (param.hasOwnProperty(key)) {
        queryParams.push(`${key}=${encodeURIComponent(param[key])}`);
      }
    }
    const queryString = queryParams.join('&');

    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/UserBoardAPI/GetUserBoardPinMapDataByPinId?${queryString}`,
        { headers } // Pass headers as a separate object
      );
      const responseData = response.data; // No need to access response.data.data

      return responseData;
    } catch (error) {
      throw error;
    }
  }
)

export const DeleteUserBoardPinMapData = createAsyncThunk('pins/DeleteUserBoardPinMapData', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token    
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
      data: param // The data you want to include in the request body
    };

    const request = await axios.delete(`${apiHost}api/UserBoardAPI/DeleteUserBoardPinMapData`, config);
    const responce = await request.data;
    //console.log('responce', responce)
    return responce;

  } catch (error) {

    throw error;
  }
})

export const GetUserBoardPinMapData = createAsyncThunk('pins/GetUserBoardPinMapData', async (param) => {

  const queryParams = [];
  for (const key in param) {
    if (param.hasOwnProperty(key)) {
      queryParams.push(`${key}=${encodeURIComponent(param[key])}`);
    }
  }
  const queryString = queryParams.join('&');

  try {
    const token = getUserToken(); // Get the Bearer token
    const headers = {
      Authorization: `Bearer ${token}`, // Set the Bearer token
    };

    const response = await axios.get(
      `${apiHost}api/UserBoardAPI/GetUserBoardPinMapData?${queryString}`,
      { headers } // Pass headers as a separate object
    );
    //const responseData = response.data; // No need to access response.data.data
    const responseData = response.data.data.objListPin;

    return responseData;
  } catch (error) {
    throw error;
  }
}
)

