import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAuthStatus } from '../_common/hooks/authHook';
import { URLS } from './index';

const PublicRoute = () => {
  const isAuth = useAuthStatus();

  return (
    
    isAuth ? <Navigate to={URLS.HOME} /> : <Outlet />
    //isAuth ? <Navigate to={URLS.LOGIN} /> : <Outlet />

  )
}

export default PublicRoute