


// import React, { useState } from 'react';

// const Categories = () => {
//   const initialCategories = [
//     {
//       id: 1,
//       name: 'Category 1',
//       checked: false,
//       subcategories: [
//         { id: 11, name: 'Subcategory 1-1', checked: false },
//         { id: 12, name: 'Subcategory 1-2', checked: false },
//       ],
//     },
//     {
//       id: 2,
//       name: 'Category 2',
//       checked: false,
//       subcategories: [
//         { id: 21, name: 'Subcategory 2-1', checked: false },
//         { id: 22, name: 'Subcategory 2-2', checked: false },
//       ],
//     },
//   ];

//   const [categories, setCategories] = useState(initialCategories);

//   const handleSubcategoryChange = (categoryId, subcategoryId) => {
//     const updatedCategories = categories.map((category) => {
//       if (category.id === categoryId) {
//         category.subcategories = category.subcategories.map((subcategory) => {
//           if (subcategory.id === subcategoryId) {
//             subcategory.checked = !subcategory.checked;
//           }
//           return subcategory;
//         });

//         // Check the parent category if all subcategories are checked
//         // Uncheck the parent category if any subcategory is unchecked
//         category.checked = category.subcategories.every(
//           (subcategory) => subcategory.checked
//         );
//       }
//       return category;
//     });

//     setCategories(updatedCategories);
//   };

//   const handleCategoryChange = (categoryId) => {
//     const updatedCategories = categories.map((category) => {
//       if (category.id === categoryId) {
//         category.checked = !category.checked;
//         category.subcategories = category.subcategories.map((subcategory) => {
//           subcategory.checked = category.checked;
//           return subcategory;
//         });
//       }
//       return category;
//     });

//     setCategories(updatedCategories);
//   };

//   return (
//     <div>
//       {categories.map((category) => (
//         <div key={category.id}>
//           <label>
//             <input
//               type="checkbox"
//               checked={category.checked}
//               onChange={() => handleCategoryChange(category.id)}
//               className="form-check-input"
//             />
//             {category.name}
//           </label>
//           <div>
//             {category.subcategories.map((subcategory) => (
//               <label key={subcategory.id}>
//                 <input
//                   type="checkbox"
//                   className="form-check-input"
//                   checked={subcategory.checked}
//                   onChange={() => handleSubcategoryChange(category.id, subcategory.id)}
//                 />
//                 {subcategory.name}
//               </label>
//             ))}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Categories;





import React, { useState } from 'react';

const Categories = () => {
  const initialCategories = [
    {
      id: 1,
      name: 'Category 1',
      checked: false,
      subcategories: [
        { id: 11, name: 'Subcategory 1-1', checked: false },
        { id: 12, name: 'Subcategory 1-2', checked: false },
      ],
    },
    {
      id: 2,
      name: 'Category 2',
      checked: true,
      subcategories: [
        { id: 21, name: 'Subcategory 2-1', checked: false },
        { id: 22, name: 'Subcategory 2-2', checked: true },
      ],
    },
  ];

  const [categories, setCategories] = useState(initialCategories);

  const handleSubcategoryChange = (categoryId, subcategoryId) => {
    const updatedCategories = categories.map((category) => {
      if (category.id === categoryId) {
        category.subcategories = category.subcategories.map((subcategory) => {
          if (subcategory.id === subcategoryId) {
            subcategory.checked = !subcategory.checked;
          }
          return subcategory;
        });

        // Check the parent category if any subcategory is checked
        category.checked = category.subcategories.some(
          (subcategory) => subcategory.checked
        );
      }
      return category;
    });

    setCategories(updatedCategories);
  };

  return (
    <div>
      {categories.map((category) => (
        <div key={category.id}>
          <label>
            <input
              type="checkbox"
              className="form-check-input"
              checked={category.checked}
              onChange={() => handleSubcategoryChange(category.id, category.id)}
            />
            {category.name}
          </label>
          <div>
            {category.subcategories.map((subcategory) => (
              <label key={subcategory.id}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={subcategory.checked}
                  onChange={() => handleSubcategoryChange(category.id, subcategory.id)}
                />
                {subcategory.name}
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Categories;

