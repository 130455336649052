import { LOGIN_VERIFICATION, LOGO_IMAGE } from "../../config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  SignIn,
  SignInButton,
  SignOutButton,
  SignedIn,
  SignedOut,
  useAuth,
  useUser,
} from "@clerk/clerk-react";
import {
  UserExpertiseAction,
  getUserProfile,
  userVerifiedAction,
} from "../../store/reducer/user/action";
import { useDispatch, useSelector } from "react-redux";

import { GetPinSearchHistory } from "../../store/reducer/pin/action";
import { OrganizationSwitcher } from "../organization-switcher";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { RotatingLines } from "react-loader-spinner";
import { getUserStore } from "../../_common/helper/LocalStorage";
import { useAuthStatus } from "../../_common/hooks/authHook";

const TopHeader = () => {
  const initialEmailVerified =
    localStorage.getItem("_email_verified") !== null
      ? JSON.parse(localStorage.getItem("_email_verified"))
      : false;

  const initialApprovedStatus =
    localStorage.getItem("_approved_status") !== null
      ? JSON.parse(localStorage.getItem("_approved_status"))
      : false;
  const _user = JSON.parse(localStorage.getItem("user"));

  const isAuth = useAuthStatus();
  const items = useSelector((state) => state.userProfileImage);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const storeData = getUserStore();
  const [logOut, setLogOut] = useState(false);
  const [selectAllMap, setSelectAllMap] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchHistory, setSearchHistory] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isToastVisibleLogin, setToastVisibilityLogin] =
    useState(initialEmailVerified);
  const [isToastApprovedStatus, setToastApprovedStatus] = useState(
    initialApprovedStatus
  );
  const [loading, setLoading] = useState(false);
  const hostname = window.location;
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [profileImage, setProfileImage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const ClerkAuth = useAuth();

  const transformedData = searchHistory.map((item, index) => ({
    id: index,
    name: item.searchText,
  }));

  const handleVerifyClick = () => {
    setLoading(true);
    const email_verification = hostname.origin + "/email-verification";
    let param = {
      userID: _user.userId,
      EmailVerificationBaseURL: email_verification,
    };

    dispatch(userVerifiedAction(param))
      .unwrap()
      .then((fulfilledAction) => {
        setToastVisibilityLogin(true);
        handleVerifyAfter();
        setLoading(false);
      })
      .catch((rejectedAction) => {
        setLoading(false);
      });
  };

  const handleCloseClick = () => {
    //setToastVisibility(false);
    setToastVisibilityLogin(true);
  };

  const handleVerifyAfter = () => {
    setModalOpen(true);
  };

  const handleLogout = (e) => {
    localStorage.removeItem("user");
    localStorage.removeItem("_rftoken");
    localStorage.removeItem("_rfprofile");
    localStorage.removeItem("_twofa");
    ClerkAuth.signOut().then(() => {
      setLogOut(true);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Use the `state` prop to send search_text value to the next page
    const stateData = {
      id: 3,
      name: "search",
      value: searchText,
      order: "desc",
    };

    // You can navigate to the /home page or any other page you want
    navigate("/home", { state: stateData });
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedCategories([...selectedCategories, value]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== value)
      );
    }
  };

  const handleFilterClick = () => {
    let catIds = selectedCategories.join(",");
    if (selectedCategories.length > 0) {
      catIds = catIds;
    } else {
      catIds = "";
    }
    const stateData = {
      id: 4,
      name: "filter",
      catIds: catIds,
      fromDate: startDate,
      toDate: endDate,
      order: "desc",
    };

    navigate("/home", { state: stateData });
  };

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearchText(string);
    const historyParam = {
      UserId: storeData.userID,
      SearchText: string,
      NoOfData: 10,
    };
    //if(searchText) {
    dispatch(GetPinSearchHistory(historyParam))
      .then((fulfilledAction) => {
        const search = fulfilledAction.payload;
        setSearchHistory(search);
      })
      .catch((error) => {
        console.error("Error fetching expertise data:", error);
      });
    //}
  };

  const handleOnHover = (result) => {
    // the item hovered
    //console.log('result', result)
  };

  const handleOnSelect = (item) => {
    // the item selected
    setSearchText(item.name);
  };

  const handleOnFocus = () => {
    //console.log('Focused')
  };

  const handleOnClear = () => {
    //console.log("Cleared");
  };

  const formatResult = (item) => {
    return (
      <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
    );
  };

  useEffect(() => {
    if (logOut) {
      navigate("/"); // '/login'
    }
  }, [logOut, navigate]);

  useEffect(() => {
    dispatch(UserExpertiseAction())
      .then((fulfilledAction) => {
        const initialSelectAllMap = fulfilledAction.payload.data;
        setSelectAllMap(initialSelectAllMap);
      })
      .catch((error) => {
        // Handle fetch error here (error)
        console.error("Error fetching expertise data:", error);
      });
  }, []);

  useEffect(() => {
    if (items) {
      setProfileImage(items?.imageData?.data?.profilePic);
    }
  }, [items]);

  useEffect(() => {
    dispatch(getUserProfile(storeData.userProfileId))
      .then((fulfilledAction) => {
        const data = fulfilledAction.payload.data;

        // if (!imageUrl) {
        setProfileImage(data?.profilePic);
        // }
      })
      .catch((error) => {
        console.error("Error fetching expertise data:", error);
      });
  }, []);

  return (
    <React.Fragment>
      <div className='top-nav-bar'>
        <div className='top-nav-bar-inner'>
          <nav className='navbar navbar-expand-lg bg-body-tertiary'>
            <div className='container-fluid'>
              <Link className='navbar-brand' to='/home'>
                <img src={LOGO_IMAGE} alt='' />
              </Link>
              <div className='mob_profileicons'>
                <div className='ms-auto'>
                  <ul className='navbar-nav align-items-center'>
                    <form
                      className='d-flex flex-grow-1 mx-2'
                      role='search'
                      onSubmit={handleSubmit}
                    >
                      <li className='nav-item dropdown search_icon'>
                        <a
                          className='nav-link dropdown-toggle dropdown-link-no-arrow d-flex align-items-center'
                          href='#'
                          role='button'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          <span className='material-symbols-outlined'>
                            search
                          </span>
                        </a>
                        <ul className='dropdown-menu dropdown-menu-end dropdown-menu-lg'>
                          <ReactSearchAutocomplete
                            items={transformedData}
                            onSearch={handleOnSearch}
                            placeholder='Search...'
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            onClear={handleOnClear}
                            className='form-control p-0 me-2 rounded-pill'
                            autoFocus
                            showIcon={false}
                            formatResult={formatResult}
                            showNoResults={false}
                            styling={{
                              fontFamily: "inherit",
                              border: "0px",
                              placeholderColor: "#44546A",
                            }}
                          />
                        </ul>
                      </li>

                      <li className='nav-item dropdown filter-mobile'>
                        <a
                          className='btn filter_btn btn-danger rounded-pill d-flex dropdown-toggle dropdown-link-no-arrow align-items-center gap-2 ms-2'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          <span className='material-symbols-outlined'>
                            tune
                          </span>
                        </a>
                        <ul className='dropdown-menu dropdown-menu-end dropdown-menu-lg'>
                          <li className='px-3'>
                            <label>Sort By</label>
                          </li>
                          <li key='new-or-trending-toggle'>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                to='/home'
                                state={{ id: 1, name: "date", order: "desc" }}
                                aria-current='page'
                              >
                                <span
                                  className={
                                    "btn text-uppercase ms-2" +
                                    (location?.state?.name === "date"
                                      ? " btn-danger"
                                      : "")
                                  }
                                  style={{
                                    fontSize: "13px",
                                    color:
                                      location?.state?.name === "date"
                                        ? "#ffffff"
                                        : "#000000",
                                  }}
                                >
                                  New
                                </span>
                              </Link>
                              <Link
                                to='/home'
                                state={{ id: 2, name: "view", order: "desc" }}
                              >
                                <span
                                  className={
                                    "btn text-uppercase ms-2" +
                                    (location?.state?.name === "view"
                                      ? " btn-danger"
                                      : "")
                                  }
                                  style={{
                                    fontSize: "13px",
                                    color:
                                      location?.state?.name === "view"
                                        ? "#ffffff"
                                        : "#000000",
                                  }}
                                >
                                  Trending
                                </span>
                              </Link>
                            </div>
                          </li>

                          <li className='px-3'>
                            <label>Only Show</label>
                          </li>
                          {selectAllMap && Array.isArray(selectAllMap) ? (
                            selectAllMap.map((category) => (
                              <li
                                key={category.categoryId}
                                className='category'
                              >
                                <label
                                  className='dropdown-item filter-cat'
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <input
                                    className='form-check-input me-2 mt-0'
                                    type='checkbox'
                                    value={category.categoryId}
                                    id={`headerCat${category.categoryId}`}
                                    onChange={handleCheckboxChange}
                                  />
                                  {category.categoryName}
                                </label>
                                <ul className='sub_category__'>
                                  {category.subCategories.map((subCategory) => (
                                    <li key={subCategory.subCategoryId}>
                                      <label
                                        className='dropdown-item filter-cat'
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <input
                                          className='form-check-input me-2 mt-0'
                                          type='checkbox'
                                          value={subCategory.subCategoryId}
                                          id={`headersubCat${subCategory.subCategoryId}`}
                                          onChange={handleCheckboxChange}
                                        />
                                        {subCategory.subCategoryName}
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))
                          ) : (
                            <p>No data found!.</p>
                          )}
                          <hr />
                          <li className='px-3'>
                            <label>Pin Added Date</label>
                            <div className='d-flex align-items-center mt-2'>
                              <input
                                type='date'
                                className='form-control'
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                              />
                              <span className='mx-2'>to</span>
                              <input
                                type='date'
                                className='form-control'
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                              />
                            </div>
                          </li>
                          <li className='px-3 mt-3 d-flex justify-content-end mobile-filter-submit'>
                            <button
                              type='button'
                              className='btn btn-danger text-uppercase ms-2 '
                              onClick={handleFilterClick}
                            >
                              Submit
                            </button>
                          </li>
                        </ul>
                      </li>
                    </form>

                    <li className='nav-item dropdown'>
                      <Link
                        className='nav-link dropdown-toggle d-flex align-items-center py-0'
                        to='#'
                        role='button'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        <div className='avatar'>
                          <img
                            src={
                              profileImage
                                ? profileImage
                                : "/assets/images/avatar.jpg"
                            }
                            alt=''
                          />
                        </div>
                      </Link>
                      <ul className='dropdown-menu dropdown-menu-end'>
                        <li>
                          <p className='px-2 mb-0'>
                            <i>Welcome Back</i>
                          </p>
                          <p className='px-2'>
                            {_user?.firstName} {_user?.lastName}
                          </p>
                        </li>
                        <li>
                          <Link className='dropdown-item' to='/my-profile'>
                            My Profile
                          </Link>
                        </li>
                        <li>
                          <hr className='dropdown-divider' />
                        </li>
                        <li>
                          <Link
                            className='dropdown-item'
                            onClick={handleLogout}
                          >
                            Log Out
                          </Link>
                        </li>
                        <li>
                          <OrganizationSwitcher />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <button
                className='navbar-toggler'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarScroll'
                aria-controls='navbarScroll'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
              </button>
              <div className='collapse navbar-collapse' id='navbarScroll'>
                <React.Fragment>
                  <ul className='navbar-nav my-2 my-lg-0 navbar-nav-scroll-height'>
                    <li className='nav-item'>
                      <Link className='nav-link' to='/the-club'>
                        The Club
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link className='nav-link' to='/home' aria-current='page'>
                        Test Flights
                      </Link>
                    </li>
                  </ul>
                  <div className='flex-grow-1'></div>
                  <form
                    className='d-flex mx-1 mob_none'
                    role='search'
                    onSubmit={handleSubmit}
                  >
                    <div
                      className='form-control p-0 me-2 rounded-pill'
                      style={{ position: "relative", minWidth: "311px" }}
                    >
                      <ReactSearchAutocomplete
                        items={transformedData}
                        onSearch={handleOnSearch}
                        placeholder='Search...'
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        onClear={handleOnClear}
                        className='p-0 w-full'
                        autoFocus
                        showIcon={false}
                        formatResult={formatResult}
                        showNoResults={false}
                        styling={{
                          fontFamily: "inherit",
                          border: "0px !important",
                          placeholderColor: "#44546A",
                        }}
                      />
                      <button
                        className='btn btn-danger rounded-pill d-flex align-items-center gap-2'
                        type='submit'
                        style={{
                          position: "absolute",
                          right: "0",
                          top: "0",
                          bottom: "0",
                          zIndex: "10",
                        }}
                      >
                        <span className='material-symbols-outlined'>
                          search
                        </span>
                      </button>
                    </div>
                    <ul className='navbar-nav align-items-center top_filter'>
                      <li className='nav-item dropdown'>
                        <a
                          className='btn filter_btn btn-danger rounded-pill d-flex dropdown-toggle dropdown-link-no-arrow align-items-center gap-2 ms-2'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          <span className='material-symbols-outlined'>
                            tune
                          </span>{" "}
                          Filter
                        </a>
                        <ul className='dropdown-menu dropdown-menu-end'>
                          <li className='px-3'>
                            <label>Sort By</label>
                          </li>
                          <li key='new-or-trending-toggle'>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                to='/home'
                                state={{ id: 1, name: "date", order: "desc" }}
                                aria-current='page'
                              >
                                <span
                                  className={
                                    "btn text-uppercase ms-2" +
                                    ((location?.state?.name === "date" || !location?.state?.name)
                                      ? " btn-danger"
                                      : "")
                                  }
                                  style={{
                                    fontSize: "13px",
                                    color:
                                      (location?.state?.name === "date" || !location?.state?.name)
                                        ? "#ffffff"
                                        : "#000000",
                                  }}
                                >
                                  New
                                </span>
                              </Link>
                              <Link
                                to='/home'
                                state={{ id: 2, name: "view", order: "desc" }}
                              >
                                <span
                                  className={
                                    "btn text-uppercase ms-2" +
                                    (location?.state?.name === "view"
                                      ? " btn-danger"
                                      : "")
                                  }
                                  style={{
                                    fontSize: "13px",
                                    color:
                                      location?.state?.name === "view"
                                        ? "#ffffff"
                                        : "#000000",
                                  }}
                                >
                                  Trending
                                </span>
                              </Link>
                            </div>
                          </li>

                          
                          {selectAllMap && Array.isArray(selectAllMap) ? (
                            selectAllMap.map((category) => (
                              <li
                                key={category.categoryId}
                                className='category'
                              >
                                <label
                                  className='dropdown-item filter-cat'
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <input
                                    className='form-check-input me-2 mt-0'
                                    type='checkbox'
                                    value={category.categoryId}
                                    id={`headerCat${category.categoryId}`}
                                    onChange={handleCheckboxChange}
                                  />
                                  {category.categoryName}
                                </label>
                                <ul className='sub_category'>
                                  {category.subCategories.map((subCategory) => (
                                    <li key={subCategory.subCategoryId}>
                                      <label
                                        className='dropdown-item filter-cat'
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <input
                                          className='form-check-input me-2 mt-0'
                                          type='checkbox'
                                          value={subCategory.subCategoryId}
                                          id={`headersubCat${subCategory.subCategoryId}`}
                                          onChange={handleCheckboxChange}
                                        />
                                        {subCategory.subCategoryName}
                                      </label>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ))
                          ) : (
                            <p>No data found!.</p>
                          )}
                          <hr />
                          <li className='px-3'>
                            <label>Test Flight Added Date</label>
                            <div className='d-flex align-items-center mt-2'>
                              <input
                                type='date'
                                className='form-control'
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                              />
                              <span className='mx-2'>to</span>
                              <input
                                type='date'
                                className='form-control'
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                              />
                            </div>
                          </li>
                          <li className='px-3 mt-3 d-flex justify-content-end'>
                            <button
                              type='button'
                              className='btn btn-danger text-uppercase ms-2'
                              onClick={handleFilterClick}
                            >
                              Submit
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </form>
                  <Link
                    className='btn btn-danger rounded-pill mx-2 d-flex align-items-center gap-1'
                    to={"/add-pin"}
                  >
                    <span className='material-symbols-outlined'>add</span>Add
                    Test Flight
                  </Link>
                </React.Fragment>
                {/* )} */}
                <div className='ms-auto mob_none'>
                  <ul className='navbar-nav align-items-center'>
                    <li className='nav-item dropdown'>
                      <ul className='dropdown-menu dropdown-menu-end dropdown-menu-lg'>
                        {isAuth ? (
                          <React.Fragment>
                            <li className='ps-3 pe-3'>
                              <p className='fw-light'>
                                <i>Welcome Back</i>
                              </p>
                              <p className='fw-bold'>
                                {_user?.firstName} {_user?.lastName}
                              </p>
                            </li>
                            <li>
                              <Link className='dropdown-item' to='/my-profile'>
                                My Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                className='dropdown-item'
                                onClick={handleLogout}
                              >
                                Log Out
                              </Link>
                            </li>
                            <li>
                              <OrganizationSwitcher />
                            </li>
                          </React.Fragment>
                        ) : (
                          <li>
                            <Link className='dropdown-item' to='/'>
                              Sign In
                            </Link>
                          </li>
                        )}
                        <li>
                          <hr className='dropdown-divider' />
                        </li>
                      </ul>
                    </li>

                    {isAuth ? (
                      <li className='nav-item dropdown'>
                        <a
                          className='nav-link dropdown-toggle d-flex align-items-center py-0'
                          href='#'
                          role='button'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          <div className='avatar'>
                            <img
                              src={
                                profileImage
                                  ? profileImage
                                  : "/assets/images/avatar.jpg"
                              }
                              alt=''
                            />
                          </div>
                        </a>
                        <ul className='dropdown-menu dropdown-menu-end'>
                          {isAuth ? (
                            <React.Fragment>
                              <li className='ps-3 pe-3'>
                                <small className='fw-light fs-6'>
                                  <i className='text-muted'>Welcome Back</i>
                                  <br />
                                  <b>
                                    {_user?.firstName} {_user?.lastName}{" "}
                                  </b>
                                </small>
                              </li>
                              <hr />
                              <li>
                                <Link
                                  className='dropdown-item'
                                  to='/my-profile'
                                >
                                  My Profile
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className='dropdown-item'
                                  onClick={handleLogout}
                                >
                                  Log Out
                                </Link>
                              </li>
                              <li>
                                <OrganizationSwitcher />
                              </li>
                            </React.Fragment>
                          ) : (
                            <li>
                              <Link className='dropdown-item' to='/'>
                                Sign In
                              </Link>
                            </li>
                          )}
                        </ul>
                      </li>
                    ) : (
                      <Link className='dropdown-item top_loginbtn' to='/'>
                        <span className='material-symbols-outlined'>lock</span>
                      </Link>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {LOGIN_VERIFICATION ? null : (
        <React.Fragment>
          {isToastVisibleLogin == false && isToastApprovedStatus != 2 && (
            <div
              className='toast custom-toast'
              role='alert'
              aria-live='assertive'
              aria-atomic='true'
            >
              <div className='toast-body'>
                Your email is not verified.
                <div className='mt-2 pt-2 border-top d-flex gap-2'>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm btn-danger rounded-pill'
                    onClick={handleVerifyClick}
                  >
                    <div className='rf-loader-spin-button'>
                      {loading ? (
                        <div className='rf-loader-spin-button'>
                          <RotatingLines
                            strokeColor='white'
                            strokeWidth={5}
                            animationDuration={0.75}
                            width={20}
                            visible={true}
                          />
                          {"  Verify My Email"}
                        </div>
                      ) : (
                        "Verify My Email"
                      )}
                    </div>
                  </button>
                  <button
                    type='button'
                    className='btn btn-secondary btn-sm filter_btn rounded-pill'
                    onClick={handleCloseClick}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          <div
            className={`modal ${isModalOpen ? "show" : ""}`}
            tabIndex='-1'
            style={{ display: isModalOpen ? "block" : "none" }}
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Email verification</h5>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={() => setModalOpen(false)}
                  ></button>
                </div>
                <div className='modal-body'>
                  <p>
                    We have sent a verification email to your registered email
                    address.
                  </p>
                  <p>
                    Please check your inbox and follow the instructions to
                    complete the verification process.
                  </p>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-secondary btn-danger'
                    data-bs-dismiss='modal'
                    onClick={() => setModalOpen(false)}
                  >
                    Understood
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`modal-backdrop ${isModalOpen ? "show" : ""}`}
            style={{ display: isModalOpen ? "block" : "none" }}
          ></div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default TopHeader;
