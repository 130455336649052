import React from 'react'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <div className="footer">
                <p>© {currentYear} InnovationForce. All Rights Reserved.</p>
            </div>
        </>
    )
}

export default Footer