import { DefaultAT, DefaultUT } from '../context/StreamApp';

import { ActivityProps } from './Activity';
import { Flex } from './Flex';
import { LoveButton } from './LikeButton';
import { ReactionIcon } from './ReactionIcon';
import { RepostButton } from './RepostButton';
import { UR } from 'getstream';
import classNames from 'classnames';

export type ActivityFooterProps<
  UT extends DefaultUT = DefaultUT,
  AT extends DefaultAT = DefaultAT,
  CT extends UR = UR,
  RT extends UR = UR,
  CRT extends UR = UR
> = Pick<ActivityProps<UT, AT, CT, RT, CRT>, 'activity' | 'feedGroup' | 'userId' | 'className' | 'style'> & {
  targetFeeds?: string[];
};

export const ActivityFooter = <
  UT extends DefaultUT = DefaultUT,
  AT extends DefaultAT = DefaultAT,
  CT extends UR = UR,
  RT extends UR = UR,
  CRT extends UR = UR
>({
  activity,
  feedGroup = 'user',
  userId,
  targetFeeds,
  className,
  style,
}: ActivityFooterProps<UT, AT, CT, RT, CRT>) => {

  return (
    <div className={classNames('raf-activity-footer', className)} style={style}>
      <div className="raf-activity-footer__left" >
        <LoveButton<UT, AT, CT, RT, CRT> activity={activity} targetFeeds={targetFeeds} />
        {false && <RepostButton<UT, AT, CT, RT, CRT>
          activity={activity}
          targetFeeds={targetFeeds}
          feedGroup={feedGroup}
          userId={userId}
        />}
      </div>
      <div className="raf-activity-footer__right">
        <Flex a="center" j="">
          <ReactionIcon
            counts={activity?.reaction_counts}
            // @ts-ignore
            own_reactions={activity?.own_reactions}
            kind="comment"
            labelSingle="comment"
            labelPlural="comments"
          />
        </Flex>
      </div>
    </div>
  )
};
