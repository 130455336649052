import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuth: false,
        data: ""
    },
    reducers: {
        setLogin: (state, action) => {
            state.isAuth = true;
            state.data = action.payload
        }
    }
});

export default authSlice.reducer