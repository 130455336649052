import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import axios from 'axios';
import { apiHost } from "../../../config";
import { toast } from "react-toastify";
import { removeAllUserStore } from "../../../_common/helper/LocalStorage";



const getTempToken = () => {
  //const _tempuser = JSON.parse(localStorage.getItem('_tempuser'));
  const _tempuser = JSON.parse(localStorage.getItem('user'));
  const localUserToken = _tempuser?.token;
  return localUserToken;
};
const getUserToken = () => {
  const _user = JSON.parse(localStorage.getItem('user'));
  const localUserToken = _user?.token;
  return localUserToken;
};


export const userLoginAction = createAsyncThunk('auth/login', async (param) => {

  try {

    const request = await axios.post(`${apiHost}api/UserLoginAPI/Login`, {
      email: param.username,
      password: param.password,
      IsPublicEnvironment: param.IsPublicEnvironment,
    });

    const responce = await request.data.data;
    if (responce.token) {
      localStorage.setItem('user', JSON.stringify(responce));
      localStorage.setItem('_rftoken', request.data.data.token);
      localStorage.setItem('_rfprofile', JSON.stringify(request.data.data.userProfileId));
      return responce;
    } else {
      return null;
    }

  } catch (error) {
    // Handle the error, for example, dispatch an error action or throw it.
    // You can access `thunkAPI.dispatch` to dispatch actions.
    // For example: thunkAPI.dispatch(someErrorAction(error.message));
    throw error;
  }

})
export const userClerkLoginAction = createAsyncThunk('auth/login', async (param) => {
  try {

    const request = await axios.post(`${apiHost}api/UserLoginAPI/ClerkLogin`, param);

    const responce = await request.data.data;
    if (request.data.success && responce.token) {
      localStorage.setItem('user', JSON.stringify(responce));
      localStorage.setItem('_rftoken', request.data.data.token);
      localStorage.setItem('_rfprofile', JSON.stringify(request.data.data.userProfileId));
      return responce;
    } else {
      throw request.data.message;
    }
  } catch (error) {
    throw error;
  }

})

export const userSignupAction = createAsyncThunk('auth/signup', async (param) => {

  try {

    const request = await axios.post(`${apiHost}api/UserLoginAPI/Signup`, param);

    //const responce = await request.data.data;
    const responce = await request.data;
    //console.log('Save:',responce);
    localStorage.setItem('_tempuser', JSON.stringify(responce.data));
    localStorage.setItem('_rfprofile', JSON.stringify(responce.data.userProfileId));
    return responce;

  } catch (error) {
    // Handle the error, for example, dispatch an error action or throw it.
    // You can access `thunkAPI.dispatch` to dispatch actions.
    // For example: thunkAPI.dispatch(someErrorAction(error.message));
    throw error;
  }

})

export const userVerifiedAction = createAsyncThunk('autologin/emailverification', async (param) => {

  try {

    const request = await axios.post(`${apiHost}api/UserLoginAPI/SendEmailVerification`, param);

    //const responce = await request.data.data;
    const responce = await request.data;
    //console.log('Save:',responce);
    //localStorage.setItem('_tempuser', JSON.stringify(responce.data));
    //localStorage.setItem('_rfprofile', JSON.stringify(responce.data.userProfileId));
    return responce;

  } catch (error) {
    // Handle the error, for example, dispatch an error action or throw it.
    // You can access `thunkAPI.dispatch` to dispatch actions.
    // For example: thunkAPI.dispatch(someErrorAction(error.message));
    throw error;
  }

})

export const UserProfileAction = createAsyncThunk('registration/secondstep', async (param) => {


  try {
    const token = getTempToken(); // Get the Bearer token
    const request = await axios.post(`${apiHost}api/UserProfileAPI/InsertUserProfile`, param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data.data;
    //localStorage.setItem('_tempsecond', JSON.stringify(responce));
    localStorage.setItem('_rfprofile', JSON.stringify(responce.userProfileId));
    return responce;

  } catch (error) {

    throw error;
  }

})

export const UserExpertiseAction = createAsyncThunk(
  'registration/expertise',
  async (param) => {
    try {
      const token = getTempToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/CategoriesAPI/GetCategories`,
        { headers } // Pass headers as a separate object
      );

      const responseData = response.data; // No need to access response.data.data

      return responseData;
    } catch (error) {
      throw error;
    }
  }
);


export const AfterLoginExpertiseAction = createAsyncThunk(
  'afterlogin/expertise',
  async (param) => {
    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/CategoriesAPI/GetCategories`,
        { headers } // Pass headers as a separate object
      );

      const responseData = response.data; // No need to access response.data.data

      return responseData;
    } catch (error) {
      throw error;
    }
  }
);

export const AddUserExpertiseAction = createAsyncThunk('registration/secondstep', async (param) => {


  try {
    const token = getTempToken(); // Get the Bearer token
    const request = await axios.post(`${apiHost}api/UserProfileAPI/InsertProfileCategoryMap`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }

})

export const UpdateExpertiseAction = createAsyncThunk('user/UpdateExpertiseAction', async (param) => {


  try {
    const token = getUserToken(); // Get the Bearer token
    const request = await axios.post(`${apiHost}api/UserProfileAPI/InsertProfileCategoryMap`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }

})

export const getUserProfile = createAsyncThunk(
  'user/GetUserProfile',
  async (param, { rejectWithValue }) => {

    try {
      const token = getUserToken(); // Get the Bearer token
      const headers = {
        Authorization: `Bearer ${token}`, // Set the Bearer token
      };

      const response = await axios.get(
        `${apiHost}api/UserProfileAPI/GetUserProfile?userProfileId=${param}`,
        { headers } // Pass headers as a separate object
      );

      const responseData = response.data; // No need to access response.data.data

      if (responseData.message === 'Unauthorized!') {

        removeAllUserStore();
        toast.error('Session expired. Please log in again.');
        setTimeout(() => {

          // const currentURL = window.location.href;
          // const url = new URL(currentURL);
          // const domainName = url.origin;
          // const restOfURL = currentURL.replace(domainName, '');
          // let redirectURL = domainName + '/?redirectto="' + currentURL + '"';
          // // console.log('Domain Name:', domainName);
          // // console.log('Rest of URL:', restOfURL);
          // window.location.href = redirectURL;
          window.location.reload();
        }, 5000);

      }

      return responseData;
    } catch (error) {
      //console.log('Unauthorized', error, error.response )


      throw error;
    }
  }
)

export const UpdateProfileAction = createAsyncThunk('user/UpdateUserProfile', async (param) => {


  try {
    const token = getUserToken(); // Get the Bearer token
    const request = await axios.post(`${apiHost}api/UserProfileAPI/UpdateUserProfile`, param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }

})

export const UpdatePasswordAction = createAsyncThunk('user/ResetOldPassword', async (param) => {


  try {
    const token = getUserToken(); // Get the Bearer token
    const request = await axios.post(`${apiHost}api/UserLoginAPI/ResetOldPassword`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }

})

export const UserTwofaAction = createAsyncThunk('user/EnableDisableTwoFA', async (param) => {


  try {
    const token = getUserToken(); // Get the Bearer token
    const request = await axios.put(`${apiHost}api/UserProfileAPI/EnableDisableTwoFA`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }

})

export const AllNotificationAction = createAsyncThunk('user/GetNotificationSettings', async (param) => {
  try {
    const token = getUserToken(); // Get the Bearer token
    const headers = {
      Authorization: `Bearer ${token}`, // Set the Bearer token
    };

    const response = await axios.get(
      `${apiHost}api/NotificationSettingsAPI/GetNotificationSettings`,
      { headers } // Pass headers as a separate object
    );

    const responseData = response.data; // No need to access response.data.data

    return responseData;
  } catch (error) {
    throw error;
  }
}
);

export const UpdateNotificationAction = createAsyncThunk('user/InsertNotification', async (param) => {


  try {
    const token = getUserToken(); // Get the Bearer token
    const request = await axios.put(`${apiHost}api/UserProfileAPI/InsertUserNotificationSettingsMap`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }

})

export const getUserSettings = createAsyncThunk('user/GetUserSettings', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token
    const headers = {
      Authorization: `Bearer ${token}`, // Set the Bearer token
    };

    const response = await axios.get(
      `${apiHost}api/UserProfileAPI/GetUserSettings?userProfileId=${param}`,
      { headers } // Pass headers as a separate object
    );

    const responseData = response.data; // No need to access response.data.data

    return responseData;
  } catch (error) {
    throw error;
  }
}
)

// This action can be dispatched when the image is successfully uploaded
// export const updateImageData = (data) => (dispatch) => {
//   console.log('Images',data);
//   //dispatch(setImageData(data));
// };

export const UserEmailVerification = createAsyncThunk('registration/secondstep', async (param) => {


  try {
    const token = getTempToken(); // Get the Bearer token
    const request = await axios.post(`${apiHost}api/UserLoginAPI/VerifyUserEmail`, param);
    const responce = await request.data.data;

    return responce;

  } catch (error) {

    throw error;
  }

})

export const updateProfileImage = createAsyncThunk('user/StoreUserProfileImage', async (param) => {


  try {
    const token = getUserToken(); // Get the Bearer token
    const request = await axios.put(`${apiHost}api/UserProfileAPI/StoreUserProfileImage`, param, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    //console.log('Images',responce);
    return responce;

  } catch (error) {

    throw error;
  }

})

export const ForgotPassword = createAsyncThunk('user/ForgotPassword', async (param) => {


  try {
    const token = getUserToken(); // Get the Bearer token
    const request = await axios.post(`${apiHost}api/UserLoginAPI/ForgotPassword`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }

})

export const ForgotPasswordReset = createAsyncThunk('user/ForgotPasswordReset', async (param) => {


  try {
    const token = getUserToken(); // Get the Bearer token
    const request = await axios.post(`${apiHost}api/UserLoginAPI/ForgotPasswordReset`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }

})

export const SendOTP = createAsyncThunk('user/SendOTP', async (param) => {

  try {
    const token = getUserToken(); // Get the Bearer token
    const headers = {
      Authorization: `Bearer ${token}`, // Set the Bearer token
    };

    const response = await axios.get(
      `${apiHost}api/UserLoginAPI/SendOTP?email=${param.email}`,
      { headers } // Pass headers as a separate object
    );

    const responseData = response.data; // No need to access response.data.data

    return responseData;
  } catch (error) {
    throw error;
  }
}
)

export const ValidateOTP = createAsyncThunk('user/ValidateOTP', async (param) => {


  try {
    const token = getUserToken(); // Get the Bearer token
    const request = await axios.post(`${apiHost}api/UserLoginAPI/ValidateOTP`, param, {
      headers: {
        'Authorization': `Bearer ${token}` // Set the Bearer token
      },
    });
    const responce = await request.data;
    return responce;

  } catch (error) {

    throw error;
  }

})

