import authSliceReducer from "./reducer/auth/slice";
//import userLoginSlice from "./reducer/user/userReducer"
import { userLoginReducer, profileImageReducer, notificationReducer } from "./reducer/user/userReducer"

import { addPinReducer, getPinItemsReducer, getAllPinItemsReducer } from "./reducer/pin/pinReducer"
import { combineReducers, configureStore } from "@reduxjs/toolkit";




// const rootReducers = {
//     authSlice: authSliceReducer,
//     userLogin: userLoginSlice,
//     addPins: addPinReducer,
//     items: getPinItemsReducer,
//     allItems: getAllPinItemsReducer,
// }

//export default rootReducers

// const rootReducer = combineReducers({
//     authSlice: authSliceReducer,
//     userLogin: userLoginReducer,
//     addPins: addPinReducer,
//     items: getPinItemsReducer,
//     allItems: getAllPinItemsReducer,
//     user: userReducer
// })

// const store = configureStore({
//     reducer: rootReducer
//   });

//   export default store;

const rootReducers = {
    authSlice: authSliceReducer,
    userLogin: userLoginReducer,
    addPins: addPinReducer,
    items: getPinItemsReducer,
    allItems: getAllPinItemsReducer,
    userProfileImage: profileImageReducer,
    notiInfo:notificationReducer
}

export default rootReducers


