import { Activity, EnrichedActivity, Reaction, UR } from 'getstream';
import { DefaultAT, DefaultUT } from '../context/StreamApp';
import { Textarea as DefaultTextarea, TextareaProps } from './Textarea';
import { ElementOrComponentOrLiteralType, PropsWithElementAttributes, inputValueFromEvent, smartRender } from '../utils';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useFeedContext, useTranslationContext } from '../context';

import { Avatar } from './Avatar';
import { Button } from './Button';
import { Data as EmojiDataSet } from 'emoji-mart';
import classNames from 'classnames';

export type CommentFieldProps<
  UT extends DefaultUT = DefaultUT,
  AT extends DefaultAT = DefaultAT,
  CT extends UR = UR,
  RT extends UR = UR,
  CRT extends UR = UR,
> = PropsWithElementAttributes<
  {
    activity: EnrichedActivity<UT, AT, RT>;
    /** Override the default emoji dataset, library has a light set of emojis
     * to show more emojis use your own or emoji-mart sets
     * https://github.com/missive/emoji-mart#datasets
     */
    emojiData?: EmojiDataSet;
    image?: string;
    /** If you want to change something about the activity data that this form
     * sends to stream you can do that with this function. This function gets the
     * activity data that the form would send normally and should return the
     * modified activity data that should be posted instead.
     *
     * For instance, this would add a target field to the activity:
     *
     * ```javascript
     * &lt;StatusUpdateForm
     *   modifyReactionData={(data) => ({...data, target: 'Group:1'})}
     * />
     * ```
     * */
    modifyReactionData?: (activity: Reaction<RT>) => Reaction<RT>;
    /** Override Post request */
    doRequest?: (activity: Reaction<RT>) => Promise<Reaction<RT>>;
    onSuccess?: () => void;
    placeholder?: string;
    targetFeeds?: string[];
    trigger?: TextareaProps['trigger'];
    Textarea?: ElementOrComponentOrLiteralType<Omit<TextareaProps, 'maxLength' | 'rows'>>;
  },
  HTMLFormElement
>;

export const CommentField = <
  UT extends DefaultUT = DefaultUT,
  AT extends DefaultAT = DefaultAT,
  CT extends UR = UR,
  RT extends UR = UR,
  CRT extends UR = UR,
  PT extends UR = UR>({
    activity,
    emojiData,
    modifyReactionData,
    doRequest,
    onSuccess,
    image,
    placeholder,
    trigger,
    targetFeeds,
    className,
    style,
    Textarea = DefaultTextarea,
  }: CommentFieldProps<UT, AT, CT, RT, CRT>) => {
  const feed = useFeedContext<UT, AT, CT, RT, CRT, PT>();
  const { t } = useTranslationContext();
  const textareaReference = useRef<HTMLTextAreaElement>();
  const [text, setText] = useState<string>();

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement> | KeyboardEvent) => {
    event.preventDefault();

    if (!text) return;
    const data = {
      data: { 'text': text }
    } as unknown as Reaction<RT>;

    const modifiedActivity = modifyReactionData
      ? modifyReactionData(data)
      : data;
    if (doRequest) {
      await doRequest(modifiedActivity);
    } else {
      try {
        await feed.onAddReaction('comment', activity as Activity<AT>, data.data, { targetFeeds });
      } catch (error) {
        console.error(error);
      }
    }

    setText('');
    onSuccess?.();
  };

  useEffect(() => {
    if (!textareaReference.current) return;

    const handleFormSubmitKey = (event: KeyboardEvent) => {
      const { current: textarea } = textareaReference;
      if (event.key === 'Enter' && textarea?.nextSibling === null) {
        handleFormSubmit(event);
      }
    };

    textareaReference.current.addEventListener('keydown', handleFormSubmitKey);

    return () => textareaReference.current?.removeEventListener('keydown', handleFormSubmitKey);
  }, []);

  return (
    <form onSubmit={handleFormSubmit} className={classNames('raf-comment-field', className)} style={style}>
      {image &&
        <div>
          <Avatar image={image} circle size={39} />
        </div>
      }
      <div className="raf-comment-field__group">
        {smartRender(Textarea, {
          value: text,
          placeholder: placeholder ?? t('Type your reply'),
          onChange: (event) => setText((pv) => inputValueFromEvent<HTMLTextAreaElement>(event, true) ?? pv),
          emojiData: emojiData,
          trigger: trigger,
          innerRef: (element) => (textareaReference.current = element),
        })}
        <div style={{ display: 'flex', width: '100%', paddingTop: '16px' }}>
          <div style={{ flexGrow: 1 }} />
          <Button buttonStyle="primary" disabled={!text} type="submit">
            <>
              {t('Post')}
            </>
          </Button>
        </div>
      </div>
    </form>
  );
};
