import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuthStatus } from '../_common/hooks/authHook';
import { URLS } from './index';
import { getUserStore } from '../_common/helper/LocalStorage';

const PrivateRoute = () => {
    const isAuth = useAuthStatus();
    let urlGurd = true; // Change to let, not const
    const data = getUserStore();

    //if (data.isApproved === true) {
        if (data.twoFA === true && data.localTwoFA === true) {
            urlGurd = true;
            //to='/my-profile'
        } else if (data.twoFA === true && data.localTwoFA !== true) {
            localStorage.removeItem('user');
            localStorage.removeItem('_rftoken');
            localStorage.removeItem('_rfprofile');
            localStorage.removeItem('_twofa');
            urlGurd = false;
            //to='/login'
        } else if (data.twoFA === false && data.localTwoFA !== true) {
            urlGurd = true;
        }

        if (data.isApproved === 0) {
            return <Navigate to="/access-denied" />; 
        }
        if (data.isApproved === 2) {
            return <Navigate to="/permission-denied" />; 
        }
    // } else {
    //     return <Navigate to="/access-denied" />; // Use return here
    // }

    return (
        // Use && to check both conditions
        isAuth && urlGurd ? <Outlet /> : <Navigate to={URLS.LOGIN} />
    );
};

export default PrivateRoute;
