import { formatDistanceToNow, format } from "date-fns";

export const  getUserStore = () => {
  const _user = JSON.parse(localStorage.getItem('user'));
  const _twofa = JSON.parse(localStorage.getItem('_twofa'));
  const localUserToken = {
   token: _user?.token,
   userID: _user?.userId,
   isApproved: _user?.isApproved,
   profileCompleteness: _user?.profileCompleteness,
   twoFA: _user?.twoFA,
   localTwoFA: _twofa? _twofa : false,
   userProfileId: _user?.userProfileId,
   roleId: _user?.roleId,
   profilePic: _user?.profilePic,
   firstName: _user?.firstName,
   lastName: _user?.lastName,
  };
  return localUserToken;
};

export const formatNumberAbbreviation = (number) => {
  if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + 'm';
  } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'k';
  } else {
      return number.toString();
  }
}

export const getPinStatus = (status) => {
  if (status === 0) {
    return 'Pending Review'
  } else if (status === 1) {
    return 'Archive'
  } else if (status === 2) {
    return 'Future review'
  } else if (status === 3) {
    return 'Under Review'
  } else if (status === 4) {
    return 'Active Test Flight'
  } else if (status === 5) {
    return 'Completed'
  } else if (status === 6) {
    return 'Active'
  } else {
    return ''
  }
} 

export const getUserRole = (roleId) => {
  if (roleId === 1) {
    return 'Admin'
  } else if (roleId === 2) {
    return 'Utility'
  } else if (roleId === 3) {
    return 'Solution Provider'
  } else {
    return 'Others'
  }
} 

// export const showDate = (dbDate, dateFormat='') => {
//   if (dateFormat == '') {
//     dateFormat = 'MM-DD-YYYY';
//   }
//   return (dbDate !== '') ? moment(dbDate).format(dateFormat) : "";
// }

export const  formatDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  if (now - date < 24 * 60 * 60 * 1000) {
      return formatDistanceToNow(date, { addSuffix: true });
  } else {
      return format(date, 'yyyy-MM-dd');
  }
}

export const phonePattern = [
  '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/
];

export const  removeAllUserStore = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('_rftoken');
        localStorage.removeItem('_rfprofile');
        localStorage.removeItem('_twofa');
        localStorage.removeItem('_tempuser');
        
}