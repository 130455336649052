import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import FullPageLoader from '../_common/helper/FullPageLoader';
import { AfterLoginExpertiseAction, UpdateExpertiseAction } from '../store/reducer/user/action';
import { GetProfileCategoryMap } from '../store/reducer/pin/action';
import TopHeader from '../components/Header/TopHeader'
import Footer from '../components/Footer/Footer'
import ProfileImage from '../components/ProfileImage/ProfileImage';
import LeftNavMenu from '../components/LeftMenu/LeftNavMenu';

const MyExpertise = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [groupedSubcategories, setGroupedSubcategories] = useState({});
    const [selectAllMap, setSelectAllMap] = useState({});
    //const _tempuser = JSON.parse(localStorage.getItem('user'));
    const userProfileId = localStorage.getItem('_rfprofile'); //_tempuser?.userProfileId;

    const { register, setValue, handleSubmit, control, formState: { errors } } = useForm();

    const [newSubCat, setnewSubCat] = useState([]);

    const seletedCatItems = useSelector((state) => state.items.items);
    useEffect(() => {
        if (seletedCatItems) {
            setnewSubCat(seletedCatItems);
        }
    }, [seletedCatItems]);


    const subCategoryIds = newSubCat.reduce((acc, category) => {
        const categorySubCategoryIds = category.subCategories.map(subCategory => subCategory.subCategoryId);
        return [...acc, ...categorySubCategoryIds];
    }, []);


    useEffect(() => {
        dispatch(GetProfileCategoryMap(userProfileId));
    }, []);



    useEffect(() => {
        dispatch(AfterLoginExpertiseAction())
            .then((fulfilledAction) => {

                const groupedData = groupSubcategoriesByCategory(fulfilledAction.payload);
                setGroupedSubcategories(groupedData);
                // Initialize selectAllMap with false for each category
                const initialSelectAllMap = {};
                Object.keys(groupedData).forEach((categoryName) => {
                    initialSelectAllMap[categoryName] = false;
                });
                setSelectAllMap(initialSelectAllMap);
                setLoading(false);
            })
            .catch((error) => {
                // Handle fetch error here (error)
                console.error('Error fetching expertise data:', error);
                setLoading(false);
            });
    }, [dispatch]);

    // Function to group subcategories by category name
    const groupSubcategoriesByCategory = (data) => {
        const groupedData = {};
        if (data && Array.isArray(data.data)) {
            data.data.forEach((category) => {
                const categoryName = category.categoryName;
                const subcategories = category.subCategories;
                groupedData[categoryName] = subcategories;
            });
        } else {
            console.error('Data is not in the expected structure:', data);
        }
        return groupedData;
    };


    const onSubmit = async (data) => {
        setLoading(true);
        const formattedData = data.PinCategories.map((subcategoryId) => ({
            userProfileId: userProfileId,
            categoryId: parseInt(subcategoryId), // Convert subcategoryId to a number if needed
        }));
        
        try {

            dispatch(UpdateExpertiseAction(formattedData))
                .unwrap()
                .then((fulfilledAction) => {
                    //console.log('fulfilledAction:', fulfilledAction);

                    if (fulfilledAction.success === true) {
                        setLoading(false);
                        toast.success(fulfilledAction.message);

                    } else {
                        setLoading(false);
                        toast.error(fulfilledAction.message);
                    }

                })
                .catch((rejectedAction) => {
                    setLoading(false);
                    //console.log('rejectedAction:', rejectedAction);
                    toast.error('Error:');

                });


        } catch (error) {
            console.error('Error:', error);
            toast.error('Error occurred while uploading.');
        }
    }


    return (
        <>
            {loading && <FullPageLoader />}
            <TopHeader />

            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="profile_page">
                        <div className="profile_laft">
                            {/* <div className="profile_pic">
                                <img src="assets/images/avatar.jpg" />
                                <div className="edit_profpic">
                                    <span className="material-symbols-outlined">photo_camera</span>
                                </div>
                                <input type="file" className="inputfile_profilechange" />
                            </div>
                            <h3>Sara Tancredi</h3>
                            <p>New York, USA</p> */}
                            <ProfileImage />
                            <div className="prof_menue">
                                <LeftNavMenu />
                                {/* <ul>
                                    <li><NavLink to="/my-profile" ><span className="material-symbols-outlined">person</span> User Info</NavLink></li>
                                    <li><NavLink to="/my-expertise" ><span className="material-symbols-outlined">construction</span> My Expertise</NavLink></li>
                                    <li><NavLink to='/my-pin' ><span className="material-symbols-outlined">push_pin</span>My Pin</NavLink></li>
                                    <li><NavLink to='/add-pin' ><span className="material-symbols-outlined">add</span>Add Pin</NavLink></li>
                                    <li><NavLink to="/settings" ><span className="material-symbols-outlined">settings</span> Settings</NavLink></li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="profile_right">

                            <div className="row">
                                <div className="col-md-12">
                                    <div>
                                        <h3>My expertise</h3>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="stepcategory_list">


                                                {Object.keys(groupedSubcategories).map((categoryName) => (
                                                    <div className="category_list" key={categoryName}>
                                                        <h5>{categoryName}</h5>

                                                        {groupedSubcategories[categoryName].map((subCategory) => (
                                                            <div className="form-check" key={subCategory.subCategoryId}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value={subCategory.subCategoryId}
                                                                    id={`flexCheck${subCategory.subCategoryId}`}
                                                                    {...register("PinCategories", { required: true })}
                                                                    //onChange={handleCheckboxChange}                                                                
                                                                    {...(subCategoryIds?.includes(subCategory.subCategoryId) ? { defaultChecked: true } : {})}

                                                                />
                                                                <label className="form-check-label" htmlFor={`flexCheck${subCategory.subCategoryId}`}>
                                                                    {subCategory.subCategoryName}
                                                                </label>

                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}

                                                {errors.PinCategories && <span className="rf-field-validation">Please select at least one area of expertise.</span>}


                                            </div>
                                            <div className="step_bottomposition">
                                                <div className="text-end">
                                                    <button className="btn btn-danger text-uppercase ms-2" type='submit' >Submit</button>

                                                </div>

                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </>
    )
}

export default MyExpertise