import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

const FullPageLoader = () => {
  return (
    <div className="rf-full-page-loader">
      <div className="rf-loader-container">
      <img src="/assets/images/loader.svg" alt="Loading..." />
        {/* <RotatingLines
          strokeColor="#B32F84"
          strokeWidth={5}
          animationDuration={0.75}
          width={96}
          visible={true}
        /> */}
      </div>
    </div>
  );
};

export default FullPageLoader;
