import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
    GetPinInfo,
    GetRelatedPins,
    GetPinComments,
    InsertPinComments,
    PinLikes,
    PinViews,
    GetUserEngagement,
    GetAllUserBoard,
    InsertUserBoardPinMapData,
    GetUserBoardPinMapDataByPinId,
    DeleteUserBoardPinMapData
} from '../store/reducer/pin/action';
import { formatDistanceToNow, format } from 'date-fns';
import TopHeader from '../components/Header/TopHeader';
import Footer from '../components/Footer/Footer';
import FullPageLoader from '../_common/helper/FullPageLoader';
import { useAppDispatch } from '../store/hook';
import { toast } from 'react-toastify';
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton, TwitterIcon, LinkedinIcon, FacebookIcon } from 'react-share';
import { NOIMAGES } from '../config';
import { getUserStore, formatNumberAbbreviation, getUserRole } from '../_common/helper/LocalStorage';




const ViewPin = () => {
    const [loading, setLoading] = useState(false);
    const [pinDetails, setPinDetails] = useState([]);
    const [relatedPin, setRelatedPin] = useState([]);
    const [commentsPin, setCommentsPin] = useState([]);
    const [totalCommentsPin, setTotalCommentsPin] = useState(0);
    const [totalLikePin, setTotalLikePin] = useState(0);
    const [totalViewPin, setTotalViewPin] = useState(0);
    const [engagement, setEngagement] = useState(false);
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { register: replyMessage, setValue: setCommantValue, handleSubmit: handleReplySubmit, formState: { errors: replyErrors }, reset: resetReplyForm } = useForm();
    const _user = getUserStore();
    const userId = _user?.userID;
    const textInputRef = useRef(null);
    const [wisePin, setWisePin] = useState(true);
    const [allBoardItems, setAllBoardItems] = useState([]);
    const [selectedBoards, setSelectedBoards] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [commentshowModal, setCommentShowModal] = useState(false);
    const [viewCount, setViewCount] = useState(false);
    //const [commentId, setCommentId] = useState(0);
    const getURL = window.location;

    const urlParams = new URLSearchParams(getURL.search);
    const request = urlParams.get('home');
    const [name, setName] = useState(request);

    const shareURL = getURL.origin + getURL.pathname;

    //console.log('shareURL',shareURL);



    const onSubmit = async (data) => {
        try {
            const postData = {
                "pinsId": id,
                "commentedBy": userId,
                "parentId": 0,
                "comments": data.comments
            }

            dispatch(InsertPinComments(postData))
                .unwrap()
                .then((fulfilledAction) => {

                    if (fulfilledAction.success === true) {
                        //setLoading(false);
                        const commentsParams = {
                            PinsId: id,
                            PageNumber: 1,
                            PageSize: 100
                        };
                        dispatch(GetPinComments(commentsParams))
                            .then((fulfilledAction) => {
                                const _pinComments = fulfilledAction.payload;
                                setCommentsPin(_pinComments);
                                setTotalCommentsPin(_pinComments[0]?.totalComments ? _pinComments[0]?.totalComments : 0)

                            })
                            .catch((error) => {
                                console.error('Error fetching related pins:', error);
                            });

                        toast.success(fulfilledAction.message);
                        reset({
                            comments: ''
                        });

                    } else {
                        //setLoading(false);
                        toast.error(fulfilledAction.message);
                    }
                })
                .catch((rejectedAction) => {
                    //setLoading(false);
                    toast.error('Error occurred while uploading.');
                });

        } catch (error) {
            //setLoading(false);
            console.error('Error:', error);
            toast.error('Error occurred while uploading.');
        }
    }

    const handleCancel = () => {
        reset({
            comments: ''
        });
    };

    const handleLike = () => {
        const likeParam = {
            "pinsId": id,
            "userId": userId,
        }

        dispatch(PinLikes(likeParam))
            .unwrap()
            .then((fulfilledAction) => {

                if (fulfilledAction.success === true) {
                    setTotalLikePin(fulfilledAction?.data?.totalLikes);
                    toast.success(fulfilledAction.message);
                } else {
                    toast.error(fulfilledAction.message);
                }
            })
            .catch((rejectedAction) => {
                toast.error('Error occurred while uploading.');
            });
        setEngagement(!engagement);
    };

    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {

        textInputRef.current.select();
        const result = document.execCommand('copy');
        window.getSelection().removeAllRanges();

        if (result) {
            setCopied(true);

            // Clear the "Copied!" message after a delay
            setTimeout(() => {
                setCopied(false);
            }, 800);
        }
    };


    const savePin = (status) => {


        const deleteBoardParam = {
            "pinsId": id,
            "userId": userId
        }

        dispatch(DeleteUserBoardPinMapData(deleteBoardParam))
            .unwrap()
            .then((fulfilledAction) => {
                //console.log(fulfilledAction);
                if (fulfilledAction.success === true) {

                    if (status === true) {
                        setWisePin(false)

                    } else if (status === false) {
                        setWisePin(true)
                    }

                    toast.success(fulfilledAction.message);

                } else {
                    toast.error(fulfilledAction.message);
                }
            })
            .catch((rejectedAction) => {
                toast.error('Error occurred while uploading.');
            });
    }




    const handleCheckboxChange = (event) => {
        const boardId = event.target.value;
        if (event.target.checked) {
            setSelectedBoards([...selectedBoards, boardId]);
        } else {
            setSelectedBoards(selectedBoards.filter(id => id !== boardId));
        }
    }

    const submitBoard = () => {

        const _saveBoardParam = selectedBoards.map(boardId => {
            return {
                "userBoardId": boardId,
                "pinsId": id,
                "userId": userId,
            };
        });
        if (_saveBoardParam.length > 0) {
            //console.log('_saveBoardParam', _saveBoardParam);
            dispatch(InsertUserBoardPinMapData(_saveBoardParam))
                .unwrap()
                .then((fulfilledAction) => {
                    //console.log(fulfilledAction);
                    if (fulfilledAction.success === true) {
                        setWisePin(false)
                        setShowModal(false);
                        toast.success(fulfilledAction.message);
                    } else {
                        toast.error(fulfilledAction.message);
                    }
                })
                .catch((rejectedAction) => {
                    toast.error('Error occurred while uploading.');
                });

        }
        //      
    }

    const closeModal = () => {

        setShowModal(false);
    }

    const addBoardModal = () => {
        setShowModal(true);
    }

    const closeCommentModal = () => {
        setName(null)
        resetReplyForm()
        setCommentShowModal(false);
    }
    const commentModal = (_commentid) => {

        setCommantValue('ReplyParentId', _commentid);
        setCommentShowModal(true)
    }

    const onReplySubmit = async (data) => {
        //console.log('onReplySubmit', data);
        const replyParam = {
            "pinsId": id,
            "commentedBy": userId,
            "parentId": data.ReplyParentId,
            "comments": data.replyComments
        }

        dispatch(InsertPinComments(replyParam))
            .unwrap()
            .then((fulfilledAction) => {

                if (fulfilledAction.success === true) {
                    //setLoading(false);
                    resetReplyForm()
                    setCommentShowModal(false);
                    const commentsParams = {
                        PinsId: id,
                        PageNumber: 1,
                        PageSize: 100
                    };
                    dispatch(GetPinComments(commentsParams))
                        .then((fulfilledAction) => {
                            const _pinComments = fulfilledAction.payload;
                            setCommentsPin(_pinComments);
                            setTotalCommentsPin(_pinComments[0]?.totalComments ? _pinComments[0]?.totalComments : 0)

                        })
                        .catch((error) => {
                            console.error('Error fetching related pins:', error);
                        });

                    toast.success(fulfilledAction.message);
                    reset({
                        comments: ''
                    });

                } else {
                    //setLoading(false);
                    toast.error(fulfilledAction.message);
                }
            })
            .catch((rejectedAction) => {
                //setLoading(false);
                toast.error('Error occurred while uploading.');
            });
    }


    useEffect(() => {

        const pinParam = id;
        const getPin = {
            pinId: pinParam
        };
        const relatedParams = {
            PinsId: pinParam,
            PageNumber: 1,
            PageSize: 4
        };
        const commentsParams = {
            PinsId: pinParam,
            PageNumber: 1,
            PageSize: 100
        };

        const engagementParam = {
            UserId: userId,
            PinsId: id
        }
        const wiseParam = {
            PinsId: id,
            UserId: userId,
        }
        if (pinParam) {
            dispatch(GetPinInfo(getPin))
                .then((fulfilledAction) => {
                    const _pinDetails = fulfilledAction.payload[0];
                    setPinDetails(_pinDetails);
                    setTotalLikePin(_pinDetails?.totalLikes ? _pinDetails?.totalLikes : '0');
                    setTotalViewPin(_pinDetails?.totalViews ? _pinDetails?.totalViews : '0');
                })
                .catch((error) => {
                    console.error('Error fetching pin info:', error);
                });

            dispatch(GetRelatedPins(relatedParams))
                .then((fulfilledAction) => {
                    const _relatedPin = fulfilledAction.payload;
                    setRelatedPin(_relatedPin);
                })
                .catch((error) => {
                    console.error('Error fetching related pins:', error);
                });

            dispatch(GetPinComments(commentsParams))
                .then((fulfilledAction) => {
                    const _pinComments = fulfilledAction.payload;
                    //console.log('_pinComments', _pinComments);
                    setCommentsPin(_pinComments);
                    setTotalCommentsPin(_pinComments[0]?.totalComments ? _pinComments[0]?.totalComments : 0)

                })
                .catch((error) => {
                    console.error('Error fetching related pins:', error);
                });

            // Pin View Page Onload


            dispatch(GetUserEngagement(engagementParam))
                .then((fulfilledAction) => {
                    const _engagement = fulfilledAction.payload[0];
                    setEngagement(_engagement.userLiked ? _engagement.userLiked : false);

                })
                .catch((error) => {
                    //console.error('Error fetching related pins:', error);
                });

            dispatch(GetUserBoardPinMapDataByPinId(wiseParam))
                .then((fulfilledAction) => {
                    const _wise = fulfilledAction.payload;
                    //console.log('_wise',_wise)
                    if (_wise?.totalRecords === 0) {
                        setWisePin(true)
                    } else {
                        setWisePin(false)
                    }
                    //setEngagement(_engagement.userLiked ? _engagement.userLiked : false);

                })
                .catch((error) => {
                    console.error('Error fetching related pins:', error);
                });
        }
    }, [dispatch, id]);

    useEffect(() => {
        if(!viewCount){
            setViewCount(true);
            const viewParam = {
                "pinsId": id,
                "userId": userId,
            }
            dispatch(PinViews(viewParam)).then((fulfilledAction) => {
                const _viewPin = fulfilledAction.payload;
                setTotalViewPin(_viewPin?.data?.totalViews ? _viewPin?.data?.totalViews : '0')
            }).catch((error) => {
                console.error('Error fetching related pins:', error);
            });
        }
        
    }, [viewCount])

    useEffect(() => {
        setLoading(true);
        if (userId) {
            let _boardParam = {
                UserId: userId,
            }
            dispatch(GetAllUserBoard(_boardParam))
                .then((fulfilledAction) => {
                    const _boardAll = fulfilledAction.payload.data.objParentUserBoard;
                    setAllBoardItems(_boardAll)
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error fetching expertise data:', error);
                });
        }
    }, [userId]);

    return (
        <React.Fragment>
            {loading && <FullPageLoader />}
            {name !== null ? null : <TopHeader />}
            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card">
                                <img src={pinDetails?.pinImage ? pinDetails?.pinImage : NOIMAGES.pinImage} alt="" />
                                <div className="card-body position-relative">
                                    <h5 className="card-title text-truncate whitespace_normal">{pinDetails?.pinTitle ? pinDetails?.pinTitle : ''}</h5>

                                    {
                                        pinDetails?.hideMyName === false ? (
                                            <p className='font-size-12 '>
                                                <strong >Submitter:</strong> {pinDetails?.firstName} {pinDetails?.lastName}
                                            </p>
                                        ) : (
                                            <p>{pinDetails?.role}</p>
                                        )
                                    }
                                    {pinDetails?.source !== '' && (
                                        <div className='font-size-12 '>
                                            <strong>Source:</strong> <span className='fw-light'>{pinDetails?.source}</span>
                                        </div>
                                    )}
                                    <div className='verified-icon'>
                                        {pinDetails?.isApproved === 7 ? (

                                            <img src="/assets/images/verify.png" alt="Verified" />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card bill-body-text-like-inner-sec">
                                <ul>
                                    <li>
                                        <Link to="" onClick={handleLike} className={engagement === true ? 'active' : ''}><span className="material-symbols-outlined">thumb_up</span> {formatNumberAbbreviation(totalLikePin)}</Link>
                                    </li>
                                    <li>
                                        <a href="#"><span className="material-symbols-outlined">visibility</span> {formatNumberAbbreviation(totalViewPin)}</a>
                                    </li>
                                    <li>
                                        <Link to="#" data-bs-toggle="modal" data-bs-target="#shareModal" ><span className="material-symbols-outlined">share</span></Link>

                                    </li>
                                </ul>
                            </div>
                            <div className='pt-2'>
                                {
                                    wisePin === true ? (
                                        <button type='button' className="btn btn-danger w-100"
                                            onClick={() => addBoardModal()}
                                        >
                                            Save Test Flight
                                        </button>

                                    ) : (
                                        <button type='button' onClick={() => savePin(false)} className="btn btn-danger w-100">
                                            Unsave Test Flight
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="card p-4">
                                <div className="problem_statement">
                                    <h3>PROBLEM STATEMENT</h3>
                                    <p>{pinDetails?.problemStatement ? pinDetails?.problemStatement : ''}</p>
                                </div>
                                <div className="problem_statement">
                                    <h3>Current State</h3>
                                    <p>{pinDetails?.currentState ? pinDetails?.currentState : ''}</p>
                                </div>
                                <div className="problem_statement">
                                    <h3>desired outcome</h3>
                                    <p>{pinDetails?.desiredOutcome ? pinDetails?.desiredOutcome : ''}</p>
                                </div>
                                <div className="problem_statement">
                                    <h3>Solutions</h3>
                                    <p dangerouslySetInnerHTML={{ __html: pinDetails?.solutions ? pinDetails?.solutions : '' }} />
                                    {/* <p>{pinDetails?.solutions ? pinDetails?.solutions : ''}</p> */}
                                </div>
                                {/* <Link className='btn btn-danger submit-solution-btn' to={'https://share.hsforms.com/1coXkG-CYS4mb0dSPjvLrwAc8o4f'} target='_blank'>Submit a Solution</Link>  */}
                            </div>
                        </div>
                    </div>
                    <div className="card related_service p-4 mt-3">
                        <div className="d-flex alignitems-center justify-content-between mb-3">
                            <h3 className="p-0">Test Flights also in this Category</h3>
                            {/* <a href="#" className="more">More</a> */}
                        </div>

                        <div className="row">
                            {relatedPin?.map((relatedItem) => (

                                <div className="col-md-3 col-sm-2" key={relatedItem.pinsId}>
                                    <div className="card related-img-box">
                                        <div className='related-image related-image-size'>
                                            <Link to={`/view-pin/${relatedItem.pinsId}`} className='home-title-link'>
                                                <img src={relatedItem?.pinImage ? relatedItem?.pinImage : NOIMAGES.pinImage} alt="" />
                                            </Link>
                                        </div>
                                        <div className="card-body">
                                            <Link to={`/view-pin/${relatedItem.pinsId}`} className='home-title-link'>
                                                <h5 className="card-title text-truncate">{relatedItem?.pinTitle ? relatedItem?.pinTitle : ''}</h5>
                                            </Link>
                                            <p className="card-text">
                                                {relatedItem?.currentState ? (
                                                    relatedItem?.currentState.length > 50 ? (
                                                        `${relatedItem?.currentState.slice(0, 50)}...`
                                                    ) : (
                                                        relatedItem?.currentState
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                            )}

                        </div>
                    </div>
                    <div className="card comment_post p-4 mt-3">
                        <h3>Is this a challenge for your utility or have you solved it already? Share with your peers…</h3>
                        <div className="bg-light text-dark">

                            <div className="app">
                                <div className="col-md-12 col-lg-12 m-auto">
                                    <div className="bg-white rounded-3 shadow-sm p-4 mb-4">
                                        {/* <!-- New Comment //--> */}
                                        <div className="d-flex mobile-comment-section">
                                            <div className='mobile-comment-inner'>
                                                <img
                                                    className="rounded-circle me-3"
                                                    style={{ width: '3rem', height: '3rem' }}
                                                    //src={pinDetails?.profilePic ? pinDetails?.profilePic : NOIMAGES.profileImageSmall}
                                                    src={_user?.profilePic ? _user?.profilePic : NOIMAGES.profileImageSmall}

                                                    alt="Profile"
                                                />
                                                <div className="hstack gap-2 mb-1 ">
                                                    <Link to="#" className="fw-bold link-dark">{_user?.firstName ? _user?.firstName : ''} {_user?.lastName ? _user?.lastName : ''}</Link>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="hstack gap-2 mb-1 ">
                                                    <Link to="#" className="fw-bold link-dark">{_user?.firstName ? _user?.firstName : ''} {_user?.lastName ? _user?.lastName : ''}</Link>
                                                </div>
                                                <form className="dropzone needsclick" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-floating mb-3">
                                                        <textarea className="form-control w-100"
                                                            placeholder="Leave a comment here"
                                                            id="my-comment"
                                                            {...register("comments", { required: true })}
                                                            style={{ height: '7rem' }} maxLength={1900} ></textarea>
                                                        <label htmlFor="my-comment">Leave a comment here</label>
                                                    </div>
                                                    <div className="hstack justify-content-end gap-2 mobile-comment-button">
                                                        <div className='float-start'>
                                                            {errors.comments && <span className="rf-field-validation">Comments state is required</span>}
                                                        </div>
                                                        <button
                                                            className="btn btn-sm btn-link link-secondary text-uppercase"
                                                            type="button"
                                                            onClick={handleCancel}
                                                        > Cancel</button>
                                                        <button className="btn btn-sm btn-danger text-uppercase" type='submit'>comment</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white rounded-3 shadow-sm p-4">

                                        <h4 className="mb-2">
                                            {/* {totalCommentsPin}  */}
                                            Here's what innovators are saying...
                                        </h4>

                                        {/* <!-- Comment #1 //--> */}
                                        <div className="">

                                            {commentsPin[0]?.objPinComments?.map((commentsItem) => {
                                                const randomColor = Math.floor(Math.random() * 16777215).toString(16);

                                                return (
                                                    <div className="py-3" key={commentsItem.parentCommentId}>
                                                        <div className="d-flex comment">
                                                            <img
                                                                className="rounded-circle comment-img"
                                                                src={commentsItem?.parentProfilePic ? commentsItem?.parentProfilePic : NOIMAGES.profileImageSmall}
                                                                alt="Profile"
                                                            />
                                                            <div className="flex-grow-1 ms-3">
                                                                <div className="mb-1">
                                                                    <a href="#" className="fw-bold link-dark me-1">
                                                                        {commentsItem.parentFirstName}
                                                                    </a>
                                                                    <span className="text-muted text-nowrap">
                                                                        {formatDistanceToNow(new Date(commentsItem.parentCommentDate), { addSuffix: true })}
                                                                    </span>
                                                                </div>
                                                                <div className="mb-2">{commentsItem.parentComments}</div>

                                                                <div className="hstack align-items-center mb-2">
                                                                    <Link
                                                                        className="link-secondary small"
                                                                        onClick={() => commentModal(commentsItem.parentCommentId)}
                                                                        to="#"
                                                                    >
                                                                        REPLY
                                                                    </Link>
                                                                </div>

                                                                {commentsItem.objSubcomments && commentsItem.objSubcomments.length > 0 && (
                                                                    <div className="child-replies">
                                                                        {commentsItem.objSubcomments.map((subComment) => (
                                                                            <div className="child-reply" key={subComment.subCommentId}>
                                                                                <div className='d-flex comment'>
                                                                                    <img
                                                                                        className="rounded-circle comment-img"
                                                                                        src={subComment?.subProfilePic ? subComment?.subProfilePic : NOIMAGES.profileImageSmall}
                                                                                        alt="Profile"
                                                                                    />
                                                                                    <div className='flex-grow-1 ms-3'>


                                                                                        <div className="mb-1">
                                                                                            <a href="#" className="fw-bold link-dark me-1">
                                                                                                {subComment.subFirstName}
                                                                                            </a>
                                                                                            <span className="text-muted text-nowrap">
                                                                                                {formatDistanceToNow(new Date(subComment.subCommentDate), { addSuffix: true })}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="mb-2">{subComment.subComments}</div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="shareModal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header modal-header-color">
                            <h5 className="modal-title">Social Media Share</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div></div>
                            <div className='d-flex justify-content-center gap-3 mb-3'>
                                <LinkedinShareButton
                                    title={pinDetails?.pinTitle ? pinDetails?.pinTitle : ''}
                                    children={pinDetails?.currentState ? pinDetails?.currentState : ''}
                                    source={pinDetails?.pinTitle ? pinDetails?.pinTitle : ''}
                                    url={shareURL}
                                > <LinkedinIcon size={32} round={true} /></LinkedinShareButton>

                                <TwitterShareButton url={shareURL} title={pinDetails?.pinTitle ? pinDetails?.pinTitle : ''}>
                                    {/* <TwitterIcon size={32} round={true} /> */}
                                    <span className="twitter-outlined"></span>
                                </TwitterShareButton>

                                <FacebookShareButton url={shareURL}
                                    quote={pinDetails?.currentState ? pinDetails?.currentState : ''}
                                    hashtag={'#innovationForce'}
                                ><FacebookIcon size={32} round={true} /></FacebookShareButton>

                                <a href="mailto:youremail@example.com"><span className="mail-outlined"></span></a>

                            </div>

                            <div className='d-flex align-items-center'>
                                <input
                                    type="text"
                                    id="copy_box"
                                    className="form-control"
                                    readOnly
                                    value={shareURL}
                                    ref={textInputRef}
                                />


                                <button className="btn-sm filter_btn copy-button ms-3" onClick={handleCopyClick}>
                                    Copy {copied && <div className="tip">Copied!</div>}
                                </button>

                            </div>

                        </div>

                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-danger text-uppercase ms-2" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>




            <div className={`modal board-modal boxy-modal ${showModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="saveBoardLabel">Save Test Flight</h5>
                            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h6>Please select a board name.</h6>
                            {allBoardItems && allBoardItems.length > 0 ? (
                                allBoardItems.map((item, index) => (
                                    <div className="form-check" key={index}>
                                        {/* <input className="form-check-input" type="checkbox" value={item?.parentUserBoardId} id={`flexCheckDefault${item?.parentUserBoardId}`} /> */}
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name='board_id'
                                            value={item?.parentUserBoardId}
                                            id={`flexCheckDefault${item?.parentUserBoardId}`}
                                            //checked={selectedBoards.includes(item?.parentUserBoardId)}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor={`flexCheckDefault${item?.parentUserBoardId}`}>
                                            {item?.parentName}
                                        </label>
                                    </div>
                                ))
                            ) : (
                                <p>No board available.</p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger rounded-pill" onClick={closeModal}>Close</button>
                            <button type="button" onClick={submitBoard} className="btn btn-secondary rounded-pill">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className={`modal fade rf-modal ${commentshowModal ? 'show' : ''}`} tabIndex="-1" style={{ display: commentshowModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="commentModalLabel">Reply Message</h5>
                            <button type="button" className="btn-close" onClick={closeCommentModal} aria-label="Close"></button>
                        </div>
                        <form onSubmit={handleReplySubmit(onReplySubmit)}>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <input
                                        type="hidden"
                                        className="form-control"
                                        id="name"
                                        {...replyMessage("ReplyParentId", { required: true })}

                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message-text" className="col-form-label">Message:</label>
                                    <textarea className="form-control h-auto" rows={7} {...replyMessage("replyComments", { required: true })} id="message-text" maxLength={1900}></textarea>
                                </div>
                                {replyErrors.replyComments && <span className="rf-field-validation">Message is required</span>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger rounded-pill" onClick={closeCommentModal}>Close</button>
                                <button type="submit" className="btn btn-secondary rounded-pill">Send message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {name !== null ? null : <Footer />}

        </React.Fragment>
    )
}

export default ViewPin