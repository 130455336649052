import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie';
import { Form } from 'react-bootstrap';
import { URLS } from '../routes';
import { Link } from 'react-router-dom';

const OnlandMessage = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(()=>{
        if(Cookies.get('ONLAND') == undefined){
            setShow(true)
        }
    },[])
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>The Club</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Welcome to The Club, the newest section of the InnovationWorks Hangar!</p>

               <p>We have incorporated many enhancements including adding a new <b>Linked-In</b> like feed to share Test Flights and collaborate with peers in real time. Join the conversation by writing your first post!</p>
                
                <div className='mt-3'>
                    <Form.Check // prettier-ignore
                        className='small-check'
                        type={`checkbox`}
                        id={`pwd-rset`}
                        label={`I have already reset my password, please don't show this message again.`}
                        onChange={(e)=>{
                            Cookies.set('ONLAND',e.target.checked)
                        }}
                    />
                   
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OnlandMessage





