import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { GetAllUserBoard, UpdateUserBoard, DeleteUserBoard, InsertUserBoard } from '../store/reducer/pin/action';
import Footer from '../components/Footer/Footer';
import ProfileImage from '../components/ProfileImage/ProfileImage';
import TopHeader from '../components/Header/TopHeader';
import FullPageLoader from '../_common/helper/FullPageLoader';
import ReactPaginate from 'react-paginate';
import LeftNavMenu from '../components/LeftMenu/LeftNavMenu';
import { getUserStore } from '../_common/helper/LocalStorage';
import { toast } from 'react-toastify';



const MyWisePinList = () => {

    const [loading, setLoading] = useState(true);
    const [allItems, setAllItems] = useState([]);
    const _user = getUserStore();
    const userId = _user?.userID;
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const { register, reset, handleSubmit, setValue, formState: { errors } } = useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        if (userId) {
            let _boardParam = {
                UserId: userId,
            }
            dispatch(GetAllUserBoard(_boardParam))
                .then((fulfilledAction) => {
                    const _boardAll = fulfilledAction.payload.data.objParentUserBoard;
                    setAllItems(_boardAll)
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error fetching expertise data:', error);
                });
        }
    }, [userId]);

    const onSubmit = (data) => {
        setLoading(true);
        const formData = new FormData();
        if (data.BoardID !== 0) {
            formData.append('UserBoardId', data.BoardID);
        }

        formData.append('UserId', userId);
        formData.append('Name', data?.BoardName);
        formData.append('ParentId', 0);
        formData.append('Status', 1);
        if (data.BoardID === 0) {
            dispatch(InsertUserBoard(formData))
                .then((fulfilledAction) => {
                    const _boardData = fulfilledAction.payload;
                    if (_boardData.success === false) {
                        toast.error(_boardData.message);
                    } else {
                        let _boardParam = {
                            UserId: userId,
                        }
                        dispatch(GetAllUserBoard(_boardParam))
                            .then((fulfilledAction) => {
                                const _boardAll = fulfilledAction.payload.data.objParentUserBoard;
                                setAllItems(_boardAll)

                                setLoading(false);
                            })
                            .catch((error) => {
                                setLoading(false);
                                console.error('Error fetching expertise data:', error);
                            });

                        toast.success(_boardData.message);
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error fetching related pins:', error);
                });
        } else {

            dispatch(UpdateUserBoard(formData))
                .then((fulfilledAction) => {
                    const _boardData = fulfilledAction.payload;
                    if (_boardData?.success === false) {
                        setLoading(false);
                        toast.error(_boardData?.message);
                    } else {
                        let _boardParam = {
                            UserId: userId,
                        }
                        dispatch(GetAllUserBoard(_boardParam))
                            .then((fulfilledAction) => {
                                const _boardAll = fulfilledAction.payload.data.objParentUserBoard;
                                setAllItems(_boardAll)

                                setLoading(false);
                            })
                            .catch((error) => {
                                setLoading(false);
                                console.error('Error fetching expertise data:', error);
                            });
                        toast.success(_boardData?.message);
                    }
                    //console.log('_boardData', _boardData)

                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error fetching related pins:', error);
                });
        }
        reset()
        setShowModal(false);
    }

    const editBoardName = (id, name) => {
        setModalText('Edit ')
        setValue('BoardID', id);;
        setValue('BoardName', name);
        setShowModal(true);
    }

    const addBoardName = () => {
        setModalText('Create ')
        setValue('BoardID', 0);
        setValue('BoardName', '');
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);

    }
    const deleteBoard = (id, name) => {
        //console.log(id, name);
        const deleteParam = {
            UserBoardId: id,
        }
        let _boardParam = {
            UserId: userId,
        }
        //return false;
        Swal.fire({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this board with all saved Test Flights?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                dispatch(DeleteUserBoard(deleteParam))
                    .unwrap()
                    .then((fulfilledAction) => {
                        if (fulfilledAction.success === true) {
                            dispatch(GetAllUserBoard(_boardParam))
                                .then((fulfilledAction) => {
                                    const _boardAll = fulfilledAction.payload.data.objParentUserBoard;
                                    setAllItems(_boardAll)
                                    setLoading(false);
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    console.error('Error fetching expertise data:', error);
                                });

                            Swal.fire('Deleted!', fulfilledAction.message, 'success');
                        }
                    })
                    .catch((rejectedAction) => {
                        setLoading(false);
                    });
            }
        });

    }


    return (
        <>
            {loading && <FullPageLoader />}
            <TopHeader />
            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="profile_page">
                        <div className="profile_laft">
                            <ProfileImage />
                            <div className="prof_menue">
                                <LeftNavMenu />
                            </div>
                        </div>
                        <div className="profile_right">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3>My Board</h3>
                                <div className='d-flex gap-2'>
                                    <Link to="#" onClick={() => addBoardName()} className="btn btn-danger btn-sm d-flex align-items-center"><span className="material-symbols-outlined">add</span> Create Board</Link>
                                </div>
                            </div>

                            <div className="boxy-pattern mt-3">
                                <div className="row">
                                    {allItems && allItems.length > 0 ? (
                                        allItems.map((item, index) => (
                                            <div className="col-lg-3 col-md-6 col-12" key={index}>
                                                <div className="boxy-pattern-inner">
                                                    <div className="boxy-pattern-inner-img board-img-hight">
                                                    <Link to={`/my-board-pins/${item.parentUserBoardId}`}  className="dropdown-item">
                                                        {item?.parentImageLink !== null ? (
                                                            <img src={item?.parentImageLink} />
                                                        ) : (
                                                            <><img src="/assets/images/board_image.png" /></>
                                                        )}
                                                        <div className="more-image"><span>+{item?.parentTotalPins}</span></div>
                                                            </Link>
                                                        <div className="dropdown boxy-dropdown">
                                                            <button className="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                                                                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                                                </svg>
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li>
                                                                    <Link to='#' className="dropdown-item" onClick={() => editBoardName(item?.parentUserBoardId, item?.parentName)}>Edit</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to='#' className="dropdown-item" onClick={() => deleteBoard(item?.parentUserBoardId, item?.parentName)}>Delete</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="pin_category p-14">
                                                        <Link to={`/my-board-pins/${item.parentUserBoardId}`}  className="dropdown-item">
                                                            <h4>{item?.parentName}</h4>
                                                        </Link>

                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No board available.</p>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`modal board-modal ${showModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{modalText} Board</h5>
                                    <button type="button" className="btn-close" onClick={closeModal}></button>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="modal-body">
                                        <div className="mb-3">
                                            <label htmlFor="board-name" className="form-label">Board Name:</label>
                                            <input type="hidden" {...register("BoardID", { required: true })} id="board-id" className="form-control" />
                                            <input type="text" {...register("BoardName", { required: true })} id="board-name" className="form-control" maxLength={48}/>
                                            {errors.BoardName && <span className="rf-field-validation">Board name is required</span>}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                                        <button type="submit" className="btn btn-danger">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}

export default MyWisePinList