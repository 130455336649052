import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";

import { userLoginAction, AllNotificationAction, updateProfileImage } from "./action";




const userLoginSlice = createSlice({
    name: "auth",
    initialState: {
        isLoding: false,
        user: null,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(userLoginAction.pending, (state, action) => {
            state.isLoding = true;
            state.user = null;
            state.error = null;
        })
        builder.addCase(userLoginAction.fulfilled, (state, action) => {
            state.isLoding = false;
            state.user = action.payload;
            state.error = null;
        })

        builder.addCase(userLoginAction.rejected, (state, action) => {
            state.isLoding = false;
            state.user = null;
            // console.log(action.error.message);
            state.error = action.error.message;
        })
    }
})

const NotificationSlice = createSlice({
    name: "notification",
    initialState: {
        isLoding: false,
        notificationData: null,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(AllNotificationAction.pending, (state, action) => {
            state.isLoding = true;
            state.notificationData = null;
            state.error = null;
        })
        builder.addCase(AllNotificationAction.fulfilled, (state, action) => {
            state.isLoding = false;
            state.notificationData = action.payload;
            state.error = null;
        })

        builder.addCase(AllNotificationAction.rejected, (state, action) => {
            state.isLoding = false;
            state.notificationData = null;
            // console.log(action.error.message);
            state.error = action.error.message;
        })
    }
})

const profileImageSlice = createSlice({
    name: 'profileImage',
    initialState: {
        imageData: null,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(updateProfileImage.pending, (state, action) => {
            state.imageData = null;
            state.error = null;
        });
        builder.addCase(updateProfileImage.fulfilled, (state, action) => {
            state.imageData = action.payload;
            state.error = null;
        });

        builder.addCase(updateProfileImage.rejected, (state, action) => {
            state.imageData = null;
            state.error = action.error.message;
        });
    }
});




//export default userLoginSlice.reducer;


export const userLoginReducer = userLoginSlice.reducer;
export const profileImageReducer = profileImageSlice.reducer;
export const notificationReducer = NotificationSlice.reducer;


