import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../store/hook';
import { ForgotPasswordReset } from '../store/reducer/user/action';
import { toast } from 'react-toastify';
import FullPageLoader from '../_common/helper/FullPageLoader';
import { LOGO_IMAGE } from '../config';

const RestPassword = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, reset, getValues, formState: { errors } } = useForm();
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const resetId = url.searchParams.get("ResetId");

    const checkPasswordMatch = (value, password) => {
        if (!value) {
            return "Confirm password is required";
        }
        return password === value || "Password Mismatch";
    };

    const onSubmit = (data) => {
        //setLoading(true);
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const resetId = url.searchParams.get("ResetId");
        let param = {
            resetId: resetId,
            "password": data.password
        };
        //console.log(param);
        //return false;
        if (resetId !== null) {
        dispatch(ForgotPasswordReset(param))
            .unwrap()
            .then((fulfilledAction) => {
                //console.log(fulfilledAction);
                if (fulfilledAction.success === true) {

                    setLoading(false);
                    toast.success(fulfilledAction.message);
                    reset();
                    navigate('/'); // /login
                } else {
                    setLoading(false);
                    toast.error(fulfilledAction.message);

                }

            })
            .catch((rejectedAction) => {
                setLoading(false);
                //console.log('rejectedAction:', rejectedAction);
                toast.error('Error:');

            });
        } else {
            setLoading(false);
            toast.error('Your token is not valid');
        }
    }
    return (
        <>
            {loading && <FullPageLoader />}
            <div className="login_page">
                <div className="loin_area">
                    <div className="login_logo">
                        <Link to='/'><img src={LOGO_IMAGE} /></Link>
                    </div>
                    <h3>Welcome!</h3>
                    <p>Change your password?</p>
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="login_content">
                            <div className="loin_input">
                                <input type="password" {...register("password", { required: true, pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, minLength: 6 })} placeholder="New Password" className="input_box" maxLength={48}/>
                                <span className="material-symbols-outlined">lock</span>
                            </div>
                            {errors.password?.type === "required" && <span className="rf-field-validation">New password is required</span>}
                            {errors.password?.type === "pattern" && (
                                <span className="rf-field-validation">
                                    Password must contain at least one uppercase letter, one number, and one special character.
                                </span>
                            )}
                            {errors.password?.type === "minLength" && (
                                <span className="rf-field-validation">
                                    Password must be at least 6 characters long.
                                </span>
                            )}

                            <div className="loin_input">
                                <input type="password" {...register("confirmPassword", { required: true, validate: (value) => checkPasswordMatch(value, getValues("password")), })} placeholder="Confirm Password" className="input_box" maxLength={48}/>
                                <span className="material-symbols-outlined">lock</span>
                            </div>
                            {errors.confirmPassword && (
                                <span className="rf-field-validation">
                                    {errors.confirmPassword.message === "Retype New Password is required"
                                        ? "Retype New Password is required"
                                        : "Password Mismatch"}
                                </span>
                            )}

                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-3">

                            <div className="remember_txt">
                                <p className="dont_account"><Link to='/'>Back To Login</Link></p>
                                {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">&nbsp;Remember Me</label> */}
                            </div>
                            <button className="btn btn-danger signin_btn" type="submit">Submit</button>
                        </div>
                    </form>
                    {/* <p className="dont_account mt-3">Don't have an account? <Link to='/sign-up'>Sign Up</Link></p> */}
                </div>
            </div>
        </>
    )
}

export default RestPassword