import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { getAllPins, DeleteMyPinAction, PinLikes, GetUserEngagement, GetPinReviews, GetUserBoardPinMapData } from '../store/reducer/pin/action';
import Footer from '../components/Footer/Footer';
import ProfileImage from '../components/ProfileImage/ProfileImage';
import TopHeader from '../components/Header/TopHeader';
import FullPageLoader from '../_common/helper/FullPageLoader';
import ReactPaginate from 'react-paginate';
import LeftNavMenu from '../components/LeftMenu/LeftNavMenu';
import { getPinStatus, getUserStore, formatDate } from '../_common/helper/LocalStorage';
import { toast } from 'react-toastify';
import { NOIMAGES } from '../config';

const MyBoardDetails = () => {

    const [loading, setLoading] = useState(true);
    const [allItems, setAllItems] = useState([]);
    const [pinIdArray, setPinIdArray] = useState([]);
    const _user = getUserStore();
    const userId = _user?.userID;       
    const [engagement, setEngagement] = useState([]);  
    const { id } = useParams();
    //const { register, handleSubmit, formState: { errors } } = useForm();


    const dispatch = useDispatch();

    useEffect(() => {
        if (userId) {
            let boardParam = {
                UserBoardId: id,
                UserId: userId,

            }
            dispatch(GetUserBoardPinMapData(boardParam))
                .then((fulfilledAction) => {
                    const _pinAll = fulfilledAction.payload;                    
                    const pinIds = _pinAll.map(item => item.pinId);
                    setAllItems(_pinAll);
                    setPinIdArray(pinIds)
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error fetching expertise data:', error);
                });
        }
    }, [userId]);

    //############# Start Pagination Part ################
    const [currentPage, setCurrentPage] = useState(0); // Current page
    const itemsPerPage = 10; // Number of items per page. Please change this number in this postion "marginPagesDisplayed"

    const pageCount = Math.ceil(allItems.length / itemsPerPage); // Calculate the number of pages
    const [pagedItems, setPagedItems] = useState([]); // Items for the current page

    // Update pagedItems when the currentPage changes
    useEffect(() => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const itemsOnPage = allItems.slice(startIndex, endIndex);
        setPagedItems(itemsOnPage);
    }, [currentPage, allItems]);

    // Handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    //########### End Pagination Part #################

    const formatNumberAbbreviation = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'm';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'k';
        } else {
            return number.toString();
        }
    }

    useEffect(() => {
        if (pinIdArray.length > 0) {
            const engagementParam = {
                UserId: userId,
                PinsId: pinIdArray.join(',')
            }

            dispatch(GetUserEngagement(engagementParam))
                .then((fulfilledAction) => {
                    const _engagement = fulfilledAction.payload;
                    setEngagement(_engagement);

                })
                .catch((error) => {
                    console.error('Error fetching related pins:', error);
                });
        }

    }, [pinIdArray]);




    const getLikeStatus = (pinID) => {

        if (engagement.length > 0) {
            const engagementItem = engagement.find(item => item.pinsId === pinID);
            if (engagementItem) {
                return engagementItem.userLiked;
            } else {
                // Handle the case where pinID is not found in the engagement array
                return false; // Or any other appropriate value
            }
        }
    }

    return (
        <>
            {loading && <FullPageLoader />}
            <TopHeader />
            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="profile_page">
                        <div className="profile_laft">
                            <ProfileImage />
                            <div className="prof_menue">
                                <LeftNavMenu />
                            </div>
                        </div>
                        <div className="profile_right">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3>My Save Test Flights</h3>
                                <div className='d-flex gap-2'>

                                    <Link to="/add-pin" className="btn btn-danger btn-sm d-flex align-items-center"><span className="material-symbols-outlined">add</span> Add Pin</Link>
                                </div>
                            </div>
                            <div className="boxy-pattern mt-3">
                                <div className="row">
                                    {pagedItems && pagedItems.length > 0 ? (
                                        pagedItems.map((item, index) => (


                                            <div className="col-lg-3 col-md-6 col-12 pb-4" key={index}>
                                                <div className='board-container'>
                                                    <div className="boxy-pattern-inner">
                                                        <div className="boxy-pattern-inner-img board-img-hight">
                                                            <Link to={`/view-pin/${item.pinId}`} className="dropdown-item">
                                                                <img src={item.pinImage || '/assets/images/no-pin-image.jpg'} alt="Pin Image" />
                                                            </Link>
                                                        </div>
                                                        <div className="pin_category board-box p-2">
                                                            <Link to={`/view-pin/${item.pinId}`} className="dropdown-item">
                                                                {item?.pinTitle ? item?.pinTitle : ''}
                                                                {/* <h5 className="card-title text-truncate">{item?.pinTitle ? item?.pinTitle : ''}</h5> */}
                                                            </Link>

                                                        </div>
                                                    </div>
                                                    <div className="bill-body-text-like-inner-sec p-2 m-0">
                                                        <ul>
                                                            <li>
                                                                <Link to='#' className={getLikeStatus(item.pinId) ? 'active' : ''} onClick={(e) => e.preventDefault()}>
                                                                    <span className="material-symbols-outlined">thumb_up</span> {formatNumberAbbreviation(item.totalLikes)}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={(e) => e.preventDefault()}>
                                                                    <span className="material-symbols-outlined">visibility</span> {formatNumberAbbreviation(item.totalViews)}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={(e) => e.preventDefault()}>
                                                                    <span className="material-symbols-outlined">message</span> {formatNumberAbbreviation(item.totalComments)}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <>
                                            <p>No test flight available.</p>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="mypin-pagination my-4">
                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={10}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination justify-content-end'}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    activeLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    pageLinkClassName={'page-link'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default MyBoardDetails