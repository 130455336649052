import { ElementOrComponentOrLiteralType, smartRender } from '../utils';
import React, { Component, ComponentProps, PropsWithChildren } from 'react';

import { LoadingIndicator as DefaultLoadingIndicator } from 'react-file-utils';
import { InfiniteScroll } from './InfiniteScroll';

export interface InfiniteScrollPaginatorProps extends PropsWithChildren, Pick<ComponentProps<'div'>, 'style'> {
  /** indicates if there is a next page to load */
  hasNextPage?: boolean;
  /** the loading indicator to use */
  Loader?: ElementOrComponentOrLiteralType;
  /** callback to load the next page */
  loadNextPage?: () => void;
  /** indicates if there there's currently any refreshing taking place */
  refreshing?: boolean;
  /** display the items in opposite order */
  reverse?: boolean;
  /** offset from when to start the loadNextPage call */
  threshold?: number;
  /** use Window as parent scrolling container */
  useWindow?: boolean;
};

export const InfiniteScrollPaginator = (props: InfiniteScrollPaginatorProps) => {
  const {
    children,
    hasNextPage,
    Loader = DefaultLoadingIndicator,
    loadNextPage,
    refreshing,
    reverse,
    threshold,
    useWindow,
    style = { display: 'flex', flexDirection: 'column', gap: '24px' },
    ...other
  } = props;

  return (
    <InfiniteScroll
      hasMore={hasNextPage}
      isLoading={refreshing}
      isReverse={reverse}
      loader={<React.Fragment key="loading-indicator">{smartRender(Loader)}</React.Fragment>}
      loadMore={loadNextPage}
      threshold={threshold}
      useWindow={useWindow}
      style={style}
      {...other}
    >
      {children}
    </InfiniteScroll >
  );
};
