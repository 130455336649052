import { ElementOrComponentOrLiteralType, PropsWithElementAttributes, smartRender } from '../utils';
import React, { MouseEventHandler, useMemo } from 'react';

import { Avatar } from './Avatar';
import classNames from 'classnames';
import { humanizeTimestamp } from '../utils';
import { useTranslationContext } from '../context';

export type UserBarProps = PropsWithElementAttributes<{
  username: string;
  AfterUsername?: React.ReactNode;
  avatar?: string;
  follow?: boolean;
  icon?: string;
  onClickUser?: MouseEventHandler;
  Right?: ElementOrComponentOrLiteralType;
  subtitle?: string;
  time?: string; // text that should be displayed as the time
  timestamp?: string | number | Date; // a timestamp that should be humanized
}>;

export const UserBar = ({
  time,
  timestamp,
  Right,
  subtitle,
  icon,
  AfterUsername,
  username,
  onClickUser,
  avatar,
  className,
  style,
}: UserBarProps) => {
  const { tDateTimeParser } = useTranslationContext();

  const [humanReadableTimestamp, parsedTimestamp] = useMemo(
    () => [
      !time && timestamp ? humanizeTimestamp(timestamp, tDateTimeParser) : time,
      timestamp ? tDateTimeParser(timestamp).toJSON() : undefined,
    ],
    [timestamp, tDateTimeParser],
  );

  return (
    <div className={classNames('raf-user-bar', className)} style={style}>
      <div style={{ minWidth: 'fit-content' }} >
        <Avatar onClick={onClickUser} size={40} circle image={avatar} />
      </div>
      <div className="raf-user-bar__details">
        <p data-testid="user-bar-username" className="raf-user-bar__username" onClick={onClickUser}>
          {username}
        </p>
        {AfterUsername}
        {icon && <img src={icon} alt="icon" />}
        {subtitle && (
          <p className="raf-user-bar__subtitle">
            <time dateTime={parsedTimestamp} title={parsedTimestamp}>
              {subtitle}
            </time>
          </p>
        )}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem'
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{
            color: '#6B7280'
          }}>
            <path
              d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM11 6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73478 5 9.48043 5.10536 9.29289 5.29289C9.10536 5.48043 9 5.73478 9 6V10C9.00006 10.2652 9.10545 10.5195 9.293 10.707L12.121 13.536C12.2139 13.6289 12.3242 13.7026 12.4456 13.7529C12.567 13.8032 12.6971 13.8291 12.8285 13.8291C12.9599 13.8291 13.09 13.8032 13.2114 13.7529C13.3328 13.7026 13.4431 13.6289 13.536 13.536C13.6289 13.4431 13.7026 13.3328 13.7529 13.2114C13.8032 13.09 13.8291 12.9599 13.8291 12.8285C13.8291 12.6971 13.8032 12.567 13.7529 12.4456C13.7026 12.3242 13.6289 12.2139 13.536 12.121L11 9.586V6Z"
              fill="currentColor"
              fill-rule="evenodd"
              clip-rule="evenodd"
            />
          </svg>
          <p className="raf-user-bar__extra">
            <time dateTime={parsedTimestamp} title={parsedTimestamp}>
              {humanReadableTimestamp}
            </time>
          </p>
        </div>
      </div>
      {smartRender(
        Right,
        {},
        <></>,
      )}
    </div>
  );
};
