import { Button } from "react-bootstrap";
import { useOrganizationList } from "@clerk/clerk-react";

function OrganizationSwitchButton({
    onClick,
    imageUrl,
    name,
    showJoinButton = false,
    showRequestButton = false,
    suggestionStatus
}: {
    onClick: () => Promise<void>
    imageUrl: string
    name: string
    showJoinButton?: boolean
    showRequestButton?: boolean
    suggestionStatus?: 'pending' | 'accepted'
}) {
    return (
        <Button
            variant="ghost"
            onClick={onClick}
            disabled={showRequestButton && suggestionStatus === 'accepted'}
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: 'transparent',
                color: 'black'
            }}
        >
            <div style={{ width: 'max-content', display: 'flex', alignItems: 'center', marginRight: '8px', }}>
                <img
                    src={imageUrl}
                    alt={`${name} logo`}
                    style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '4px',
                        marginRight: '8px'
                    }}
                />
                <span style={{ width: 'max-content' }}>{name}</span>
            </div>
            {showJoinButton && (
                <span style={{ marginLeft: '8px', color: 'blue' }}>Join</span>
            )}
            {showRequestButton &&
                (
                    suggestionStatus === 'accepted'
                        ? <span style={{ marginLeft: '8px', color: 'black' }}>pending</span>
                        : <span style={{ marginLeft: '8px', color: 'blue' }}>Request</span>

                )}
        </Button>
    )
}

export function OrganizationSwitcher() {
    const {
        isLoaded,
        setActive,
        userMemberships,
        userInvitations,
        userSuggestions
    } = useOrganizationList({
        userMemberships: {
            infinite: true,
            keepPreviousData: true,
            pageSize: 100
        },
        userInvitations: {
            infinite: true,
            keepPreviousData: true,
            pageSize: 100,
        },
        userSuggestions: {
            infinite: true,
            keepPreviousData: true,
            pageSize: 100,
            status: ["accepted", "pending"]
        },
    });

    if (!isLoaded) {
        return null;
    }

    return (
        <div style={{ marginTop: '16px' }}>
            <OrganizationSwitchButton
                key={'public-hangar'}
                onClick={
                    async () => {
                        window.location.href = 'https://hangar.innovationforce.io'
                    }
                }
                imageUrl="https://ik.imagekit.io/innovationforce/Favicon/favicon.png"
                name="Hangar"
            />
            {
                userMemberships.data.map((membership) => (
                    <>
                        {typeof membership.organization.publicMetadata.hangarUrl === 'string' && membership.organization.publicMetadata.hangarUrl &&
                            <OrganizationSwitchButton
                                key={`${membership.id}-hangar`}
                                onClick={
                                    async () => {
                                        await setActive(membership)
                                        const href: string = typeof membership.organization.publicMetadata.hangarUrl === 'string' ? membership.organization.publicMetadata.hangarUrl : `https://${membership.organization.slug}hangar.innovationforce.io`;
                                        if (href) { window.location.href = href }
                                    }
                                }
                                imageUrl={membership.organization.imageUrl}
                                name={`${membership.organization.name} Hangar`}
                            />
                        }
                        {membership.organization.publicMetadata.atcUrl !== null &&
                            <OrganizationSwitchButton
                                key={`${membership.id}-atc`}
                                onClick={
                                    async () => {
                                        await setActive(membership)
                                        const href: string = typeof membership.organization.publicMetadata.atcUrl === 'string' ? membership.organization.publicMetadata.atcUrl : `https://${membership.organization.slug}.innovationforce.io`;
                                        if (href) { window.location.href = href }
                                    }
                                }
                                imageUrl={membership.organization.imageUrl}
                                name={`${membership.organization.name} ATC`}
                            />
                        }
                    </>
                ))
            }
            {
                userInvitations.data.map((invitation) => (
                    <OrganizationSwitchButton
                        key={invitation.id}
                        onClick={
                            async () => {
                                await invitation.accept()
                                await setActive({
                                    organization: invitation.publicOrganizationData.id,
                                })
                                window.location.href = `https://${invitation.publicOrganizationData.slug}.innovationforce.io`
                            }
                        }
                        imageUrl={invitation.publicOrganizationData.imageUrl}
                        name={invitation.publicOrganizationData.name}
                        showJoinButton
                    />
                ))
            }
            {
                userSuggestions.data.map((suggestion) => (
                    <OrganizationSwitchButton
                        key={suggestion.id}
                        onClick={
                            async () => {
                                if (suggestion.status !== 'pending') return;
                                await suggestion.accept();
                            }
                        }
                        imageUrl={suggestion.publicOrganizationData.imageUrl}
                        name={suggestion.publicOrganizationData.name}
                        showRequestButton
                        suggestionStatus={suggestion.status}
                    />
                ))
            }
        </div>
    );
}
