import { configureStore, combineReducers,getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducers from "./rootReducers";

const rootReducer = combineReducers(rootReducers);


export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production"
});