import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { getAllPins, DeleteMyPinAction, PinLikes, GetUserEngagement, GetPinReviews, BulkFileUpload } from '../store/reducer/pin/action';
import Footer from '../components/Footer/Footer';
import ProfileImage from '../components/ProfileImage/ProfileImage';
import TopHeader from '../components/Header/TopHeader';
import FullPageLoader from '../_common/helper/FullPageLoader';
import ReactPaginate from 'react-paginate';
import LeftNavMenu from '../components/LeftMenu/LeftNavMenu';
import { getPinStatus, getUserStore, formatDate } from '../_common/helper/LocalStorage';

import { toast } from 'react-toastify';


const MyPin = () => {
    const [loading, setLoading] = useState(true);
    const [allItems, setAllItems] = useState([]);
    const [pinIdArray, setPinIdArray] = useState([]);
    const _user = getUserStore();
    const userId = _user?.userID;
    const roleId = _user?.roleId;
    const [totalLikePin, setTotalLikePin] = useState(0);
    const [engagement, setEngagement] = useState([]);
    const [review, setReview] = useState([]);
    const [bulkDataLog, setBulkDataLog] = useState([]);
    //const { register, handleSubmit, formState: { errors } } = useForm();


    const values = {
        userId: userId,
        roleId: roleId
    }

    const dispatch = useDispatch();

    const handleDelete = (pinId) => {
        const pinValues = {
            pinsId: pinId
        }

        Swal.fire({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this pin?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B32F84',
            cancelButtonColor: '#44546A',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteMyPinAction(pinValues))
                    .unwrap()
                    .then((fulfilledAction) => {
                        if (fulfilledAction.success === true) {
                            dispatch(getAllPins(values))
                                .then((fulfilledAction) => {
                                    const _pinAll = fulfilledAction.payload;
                                    setAllItems(_pinAll);
                                    setLoading(false);
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    console.error('Error fetching expertise data:', error);
                                });
                            Swal.fire('Deleted!', fulfilledAction.message, 'success');
                        }
                    })
                    .catch((rejectedAction) => {
                    });
            }
        });
    }

    useEffect(() => {

        if (userId) {
            dispatch(getAllPins(values))
                .then((fulfilledAction) => {
                    const _pinAll = fulfilledAction.payload;
                    const pinIds = _pinAll.map(item => item.pinId);
                    setAllItems(_pinAll);
                    setPinIdArray(pinIds)
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error fetching expertise data:', error);
                });
        }
    }, [userId]);

    //############# Start Pagination Part ################
    const [currentPage, setCurrentPage] = useState(0); // Current page
    const itemsPerPage = 3; // Number of items per page. Please change this number in this postion "marginPagesDisplayed"

    const pageCount = Math.ceil(allItems.length / itemsPerPage); // Calculate the number of pages
    const [pagedItems, setPagedItems] = useState([]); // Items for the current page

    // Update pagedItems when the currentPage changes
    useEffect(() => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const itemsOnPage = allItems.slice(startIndex, endIndex);
        setPagedItems(itemsOnPage);
    }, [currentPage, allItems]);

    // Handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    //########### End Pagination Part #################

    const formatNumberAbbreviation = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'm';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'k';
        } else {
            return number.toString();
        }
    }

    const handleLike = (id) => {
        //console.log('id', id);
        const likeParam = {
            "pinsId": id,
            "userId": userId,
        }

        dispatch(PinLikes(likeParam))
            .unwrap()
            .then((fulfilledAction) => {

                if (fulfilledAction.success === true) {
                    setTotalLikePin(fulfilledAction?.data?.totalLikes);
                    toast.success(fulfilledAction.message);
                } else {
                    toast.error(fulfilledAction.message);
                }
            })
            .catch((rejectedAction) => {
                toast.error('Error occurred while uploading.');
            });

    };

    const handleReview = (id) => {
        setLoading(true)
        setReview([]);
        const reviewParam = {
            PinsId: id
        }
        dispatch(GetPinReviews(reviewParam))
            .then((fulfilledAction) => {

                const _review = fulfilledAction.payload;
                //console.log('_review', _review)
                setReview(_review);
                setLoading(false)

            })
            .catch((error) => {
                setLoading(false)
                console.error('Error fetching related pins:', error);
            });
    }

    useEffect(() => {
        if (pinIdArray.length > 0) {
            const engagementParam = {
                UserId: userId,
                PinsId: pinIdArray.join(',')
            }

            dispatch(GetUserEngagement(engagementParam))
                .then((fulfilledAction) => {
                    const _engagement = fulfilledAction.payload;
                    setEngagement(_engagement);

                })
                .catch((error) => {
                    console.error('Error fetching related pins:', error);
                });
        }

    }, [pinIdArray]);




    const getLikeStatus = (pinID) => {

        if (engagement?.length > 0) {
            const engagementItem = engagement.find(item => item.pinsId === pinID);
            if (engagementItem) {
                return engagementItem.userLiked;
            } else {
                // Handle the case where pinID is not found in the engagement array
                return false; // Or any other appropriate value
            }
        }
    }

    const [selectedFile, setSelectedFile] = useState(null);


    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };



    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedFile) {
            setLoading(true);
            // Create a new FormData object
            const formData = new FormData();
            // Append the file to the formData object
            formData.append('UserId', userId);
            formData.append('ExcelFile', selectedFile);
            // Log the content of the formData
            formData.forEach((value, key) => {
               // console.log(`${key}: ${value}`);
            });

            dispatch(BulkFileUpload(formData))
                .then((fulfilledAction) => {
                    const _bulkdata = fulfilledAction.payload;
                    setBulkDataLog(_bulkdata);
                    //console.log('_bulkdata', _bulkdata);

                    dispatch(getAllPins(values))
                        .then((fulfilledAction) => {
                            const _pinAll = fulfilledAction.payload;
                            const pinIds = _pinAll.map(item => item.pinId);
                            setAllItems(_pinAll);
                            setPinIdArray(pinIds)
                            setLoading(false);
                        })
                        .catch((error) => {
                            setLoading(false);
                            console.error('Error fetching expertise data:', error);
                        });


                })
                .catch((error) => {
                    console.error('Error fetching related pins:', error);
                });

            // const modal = document.getElementById('csvUploadModal');
            // if (modal) {
            // const modal = new bootstrap.Modal(document.getElementById('csvUploadModal'));
            //  modal.hide();

            // }

        }
    }

    return (
        <>
            {loading && <FullPageLoader />}
            <TopHeader />
            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="profile_page">
                        <div className="profile_laft">
                            <ProfileImage />
                            <div className="prof_menue">
                                <LeftNavMenu />
                            </div>
                        </div>
                        <div className="profile_right">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3>My Test Flights</h3>
                                <div className='d-flex gap-2'>
                                    
                                    <Link to="/add-pin" className="btn btn-danger btn-sm d-flex align-items-center"><span className="material-symbols-outlined">add</span> Add Test Flight</Link>
                                </div>
                            </div>
                            {pagedItems && pagedItems.length > 0 ? (
                                pagedItems.map((item) => (
                                    <div className="pin_list" key={item.pinId}>
                                        <div className="row">
                                            <div className="col-md-3">
                                                {/* Replace this with the actual image source */}
                                                <img src={item.pinImage || '/assets/images/no-pin-image.jpg'} alt="Pin Image" />
                                            </div>
                                            <div className="col-md-5">
                                                <div className="problem_statement">
                                                    <div className='d-flex align-items-center'>
                                                        <h3 className='p-0 me-2'>
                                                            {item.pinTitle}
                                                        </h3>

                                                        {(() => {
                                                            switch (item.isApproved) {
                                                                case 0:
                                                                    return <span className="badge bg-dark">Pending Review</span>;
                                                                case 1:
                                                                    return <span className="badge bg-secondary">Inactive</span>;
                                                                case 2:
                                                                    return <span className="badge bg-warning text-dark">On Hold</span>;
                                                                case 3:
                                                                    return <span className="badge bg-success">Active</span>;
                                                                case 4:
                                                                    return <span className="badge bg-danger">Active Test Flight</span>;
                                                                case 5:
                                                                    return <span className="badge bg-info text-dark">Completed</span>;
                                                                case 6:
                                                                    return <span className="badge bg-success">Active</span>;
                                                                default:
                                                                    return ''; // Handle other cases or provide a default value
                                                            }
                                                        })()}
                                                    </div>
                                                    <div className="pin_category">
                                                        {item.objPinsCategory.map((category) => (
                                                            <div key={category.categoryId}>
                                                                <h4>{category.categoryName}</h4>
                                                                <ul>
                                                                    {category.objSubcategories.map((subcategory) => (
                                                                        <li key={subcategory.subCategoryId}>
                                                                            {subcategory.subCategoryName}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="bill-body-text-like-inner-sec p-0 w-50">
                                                        <ul>
                                                            <li>
                                                                <Link to='#' className={getLikeStatus(item.pinId) ? 'active' : ''}
                                                                // onClick={() => handleLike(item.pinId)}
                                                                ><span className="material-symbols-outlined">thumb_up</span> {formatNumberAbbreviation(item.totalLikes)}</Link>
                                                            </li>

                                                            <li>
                                                                <Link to='#'><span className="material-symbols-outlined">visibility</span> {formatNumberAbbreviation(item.totalViews)}</Link>
                                                            </li>
                                                            <li>
                                                                <Link to='#'><span className="material-symbols-outlined">message</span> {formatNumberAbbreviation(item.totalComments)}</Link>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 d-flex">
                                                <div className="mypin_btnlist">
                                                    <ul>
                                                        <li><Link to={`/edit-pin/${item.pinId}`}><span className="material-symbols-outlined">edit</span></Link></li>

                                                        <li className="dsb_btn"><Link to={`/view-pin/${item.pinId}`}><span className="material-symbols-outlined">visibility_off</span></Link></li>
                                                        <li><Link to='#'
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#myModal"
                                                            onClick={() => handleReview(item.pinId)}><span className="material-symbols-outlined">comment</span></Link></li>
                                                        {item.isApproved !== 3 || item.isApproved !== 6 ? (
                                                            <li>
                                                                <Link to="" onClick={() => handleDelete(item.pinId)}>
                                                                    <span className="material-symbols-outlined">delete</span>
                                                                </Link>
                                                            </li>
                                                        ) : null}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No test flights available.</p>
                            )}

                            <div className="mypin-pagination my-4">
                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination justify-content-end'}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    activeLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    pageLinkClassName={'page-link'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="myModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header modal-header-color">
                            <h5 className="modal-title">Review Logs</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {loading ?
                                <div>
                                    Loading......
                                </div>
                                :
                                <div className="row">
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Review Comment</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                review && review?.length == 0 ?
                                                    <tr>
                                                        <td colSpan={2}>No data found!</td>
                                                    </tr>
                                                    :
                                                    <>
                                                        {
                                                            review.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>{item?.comment}</td>

                                                                    <td>
                                                                        <b>{getPinStatus(item?.reviewStatus)}</b> by
                                                                        <b> {item?.firstName} {item?.lastName}</b> on
                                                                        <b> {formatDate(item?.reviewDate)}</b>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>

                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-danger text-uppercase ms-2" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="csvUploadModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header modal-header-color">
                            <h5 className="modal-title">Upload Pins</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body d-flex justify-content-center">
                                <input
                                    id="upload-image-input"
                                    type="file"
                                    accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={handleFileChange}

                                />
                            </div>

                            {bulkDataLog?.data?.objSuccessUploads.length > 0 && (
                                <>
                                <hr />
                                <div className="ps-3">
                                    <h5 className='modal_body_h'>Successfully Uploaded Pin List</h5>
                                    <table className="table upload_modal_table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Test Flight Title</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bulkDataLog?.data?.objSuccessUploads?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item?.pinTitle}</td>
                                                    <td><span className="badge bg-success">Success</span></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                </>
                            )}



                            {bulkDataLog?.data?.objFailedUploads.length > 0 && (
                                <div className="px-3">
                                    <h5 className='modal_body_h'>
                                    Upload Fail Pin List
                                    </h5>
                                    <table className="table upload_modal_table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Test Flight Title</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bulkDataLog?.data?.objFailedUploads?.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{item?.pinTitle}</td>
                                                    <td>{item?.message}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}




                            <div className="modal-footer border-0">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                <button type="submit" className="btn btn-danger text-uppercase ms-2">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default MyPin;
