import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../store/hook';
import { GetPinInfo, UpdatePinAction } from '../store/reducer/pin/action';
import { AfterLoginExpertiseAction } from '../store/reducer/user/action';
import TopHeader from '../components/Header/TopHeader';
import Footer from '../components/Footer/Footer';
import { useSelector } from 'react-redux';
import FullPageLoader from '../_common/helper/FullPageLoader';
import ProfileImage from '../components/ProfileImage/ProfileImage';
import LeftNavMenu from '../components/LeftMenu/LeftNavMenu';
//import { Editor } from "@tinymce/tinymce-react";
import { TINYMCEAPIKEY } from '../config';
import Editor from 'react-simple-wysiwyg';

const EditMyPin = () => {


    const [pin, setPin] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [pincategoryIds, setPincategoryIds] = useState([]);
    const [pinsubCategoryIds, setPinSubCategoryIds] = useState([]);
    const [pinfilterCategoryIds, setPinfilterCategoryIds] = useState([]);
    const [pinAllData, setPinAllData] = useState([]);
    const [parentCat, setParentCat] = useState([]);
    const { id } = useParams();    

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            //console.log(editorRef.current.getContent());
        }
    };

    const { register, handleSubmit, setValue, control, setError, formState: { errors } } = useForm();
    const [file, setFile] = useState(null);
    const _user = JSON.parse(localStorage.getItem('user'));
    const userId = _user?.userId;
    const userProfileId = localStorage.getItem('_rfprofile'); //_user?.userProfileId;
    const dispatch = useAppDispatch();
    

    useEffect(() => {

        // const urlSearchParams = new URLSearchParams(window.location.search);
        const pinParam = id; //urlSearchParams.get('pin');
        const getPin = {
            pinId: pinParam
        }
        if (pinParam) {
            setPin(pinParam);
            dispatch(GetPinInfo(getPin)).then((fulfilledAction) => {
                const _pinCategories = fulfilledAction.payload[0];
                setPinAllData(_pinCategories);                
                const _categoryIds = _pinCategories.objPinsCategory.map(item => item.categoryId);
                const _subCategoryIds = _pinCategories.objPinsCategory.flatMap(item =>
                    item.objSubcategories.map(subItem => subItem.subCategoryId)
                );                
                setPincategoryIds(_categoryIds);
                setPinSubCategoryIds(_subCategoryIds);

            })
                .catch((error) => {
                    console.error('Error fetching expertise data:', error);
                });
        }

    }, []);
    

    useEffect(() => {

        if (pinAllData !== undefined) {

            setValue('PinTitle', pinAllData ? pinAllData.pinTitle : '');
            setValue('CurrentState', pinAllData ? pinAllData.currentState : '');
            setValue('ProblemStatement', pinAllData ? pinAllData.problemStatement : '');
            setValue('DesiredOutcome', pinAllData ? pinAllData.desiredOutcome : '');
            setValue('Solutions', pinAllData ? pinAllData.solutions : '');
            setValue('Source', pinAllData ? pinAllData.source : '');
            if (pinAllData.pinImage instanceof File || pinAllData.pinImage instanceof Blob) {
                setSelectedFile(pinAllData.pinImage);
            } else if (typeof pinAllData.pinImage === 'string') {
                // If it's a string, treat it as a URL
                setImageURL(pinAllData.pinImage);
            }

        }
    }, [pinAllData]);   

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setSelectedFile(file); // Store the selected file object
        } else {
            alert('Please select an image file.');
        }
    };
   
    const preventDefault = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        // Whenever selectedFile changes, you can handle it here
        // For example, you can update the image URL here
        if (selectedFile) {
            setImageURL(URL.createObjectURL(selectedFile));
        }
    }, [selectedFile]);

    const [imageURL, setImageURL] = useState('');
    useEffect(() => {
        dispatch(AfterLoginExpertiseAction())
            .then((fulfilledAction) => {               
                const getAllCat = fulfilledAction.payload;
                const categoryIdToFilter = pincategoryIds; // Array of categoryId values you want to filter by
                // When user set their profile 
                //const filteredData = getAllCat.data.filter(item => categoryIdToFilter.includes(item.categoryId));
                const filteredData = getAllCat.data;
                setPinfilterCategoryIds(filteredData);
                
                const  _parentCat = [];
                if (filteredData && Array.isArray(filteredData)) {
                    filteredData.forEach((category) => {                     
                       
                        _parentCat.push(category.categoryId);
                    });
                    setParentCat(_parentCat);
                }

                setLoading(false);
            })
            .catch((error) => {
                // Handle fetch error here (error)
                console.error('Error fetching expertise data:', error);
                setLoading(false);
            });
    }, [dispatch, pincategoryIds]);

    const onSubmit = async (data) => {

        setLoading(true); // Set loading to true when submitting
        try {
            
            const checketed = new Set(data.PinCategories.map(String));
            const allParent = new Set(parentCat.map(String));
            // Compare the sets for equality
            const arraysAreEqual = new Set([...checketed].filter(x => allParent.has(x))).size === checketed.size;
            if (arraysAreEqual) {
                setError('PinCategories', {
                    type: 'manual',
                    message: 'Please select at least one area of expertise.'
                });
                toast.error('Please select at least one area of expertise.');
                setLoading(false);
                return false;
            }
            
            const formData = new FormData();            
            formData.append('UserId', userId);
            formData.append('PinsId', pin);
            formData.append('CreatedBy', userId);
            formData.append('Image', selectedFile); // Use the selected file object
            formData.append('PinTitle', data.PinTitle);
            formData.append('CurrentState', data.CurrentState);
            formData.append('ProblemStatement', data.ProblemStatement);
            formData.append('DesiredOutcome', data.DesiredOutcome);
            formData.append('Solutions', data.Solutions);
            formData.append('PinCategories', data.PinCategories.join(','));
            formData.append('HideMyName', data.HideMyName); 
            formData.append('Source', data.Source); 
            

            // //Log the form data to the console
            // for (const entry of formData.entries()) {
            //     console.log(entry[0], entry[1]);
            // }
            //return false;
            dispatch(UpdatePinAction(formData))
                .unwrap()
                .then((fulfilledAction) => {
                    if (fulfilledAction.success === false) {
                        setLoading(false);
                        toast.error(fulfilledAction.message);
                    }
                    if (fulfilledAction.success === true) {
                        setLoading(false);
                        toast.success(fulfilledAction.message);
                        navigate('/my-pin');
                    }
                })
                .catch((rejectedAction) => {
                    setLoading(false);
                    toast.error('Error occurred while uploading.');
                });
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
            toast.error('Error occurred while uploading.');
        }
    };  

    //console.log('PinSubCategoryIds', pinsubCategoryIds);

    return (
        <>
            {/* {loading && <FullPageLoader />} */}
            <TopHeader />
            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="profile_page">
                        <div className="profile_laft">
                            
                            <ProfileImage />
                            <div className="prof_menue">
                                <LeftNavMenu />                               
                            </div>
                        </div>
                        <div className="profile_right">
                            <div className="row">
                                <div className="col-md-10">
                                    <form className="dropzone needsclick" id="demo-upload" onSubmit={handleSubmit(onSubmit)}>
                                        <div>

                                            <div className="profile_right">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h3>Add A Test Flight</h3>
                                                </div>                   


                                                <div className='mb-3'>
                                                    <div id="dropzone">
                                                        <div
                                                            className="image-dropzone w-100"
                                                            onDragOver={preventDefault}
                                                            onClick={() => document.getElementById('image-input').click()}
                                                        >
                                                            <div className="dz-message needsclick">
                                                                <span className="material-symbols-outlined">
                                                                    cloud_upload
                                                                </span>
                                                                <div className="note needsclick">Click to upload image (JPEG or PNG) to be used for test flight</div>
                                                            </div>
                                                            {imageURL ? (
                                                                <img src={imageURL} alt="Selected" />
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </div>
                                                        <input
                                                            id="image-input"
                                                            type="file"
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            onChange={handleFileInputChange}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label">Test Flight Title<em className='rf-required'>*</em></label>
                                                            <input type="text" {...register("PinTitle", { required: true })} className="form-control" id="exampleFormControlInput1" placeholder="" maxLength={240} />
                                                        </div>
                                                        {errors.PinTitle && <span className="rf-field-validation">Test Flight title is required</span>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput10" className="form-label">Source</label>
                                                            <input type="text" {...register("Source")} className="form-control" id="exampleFormControlInput10" placeholder="" maxLength={1900} />
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label">Current State <em className='rf-required'>*</em></label>
                                                            <textarea className="form-control" {...register("CurrentState", { required: true })} maxLength={1900} ></textarea>
                                                        </div>
                                                        {errors.CurrentState && <span className="rf-field-validation">Current state is required</span>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label">Problem Statement</label>
                                                            <textarea className="form-control" {...register("ProblemStatement")} maxLength={1900} ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label">Desired Outcome</label>
                                                            <textarea className="form-control" {...register("DesiredOutcome")} maxLength={1900} ></textarea>
                                                        </div>
                                                    </div>
                                                </div>                                                

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="exampleFormControlInput1" className="form-label">Solutions</label>

                                                            {/* <Controller
                                                                name="Solutions"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <Editor
                                                                        apiKey={TINYMCEAPIKEY}
                                                                        onInit={(evt, editor) => (editorRef.current = editor)}                                                                        
                                                                        value={field.value}                                                                        
                                                                        init={{
                                                                            height: 500,
                                                                            menubar: false,
                                                                            plugins: [
                                                                                "advlist",
                                                                                "autolink",
                                                                                "lists",
                                                                                "link",
                                                                                "image",
                                                                                "charmap",
                                                                                "preview",
                                                                                "anchor",
                                                                                //"searchreplace",
                                                                                //"visualblocks",
                                                                                "code",
                                                                                "fullscreen",
                                                                                "insertdatetime",
                                                                                //"media",
                                                                                //"table",
                                                                                "code",
                                                                                "help",
                                                                                "wordcount",
                                                                            ],
                                                                            toolbar:
                                                                                "undo redo | blocks | " +
                                                                                "bold italic forecolor | alignleft aligncenter " +
                                                                                "alignright alignjustify | bullist numlist outdent indent | " +
                                                                                "removeformat | help",
                                                                            content_style:
                                                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                                        }}
                                                                        onEditorChange={(content, editor) => {
                                                                            if (content.length <= 1900) {
                                                                              // Update the form field value as the user types
                                                                              field.onChange(content);
                                                                            } else {
                                                                              // Optionally, you can display an error message or prevent further input here
                                                                            }
                                                                          }}
                                                                    />
                                                                )}

                                                            /> */}

                                                            <Controller
                                                                defaultValue=" "
                                                                control={control}
                                                                name="Solutions"
                                                                rules={{ required: true }}
                                                                render={({ field: { onChange, value, ref } }) => (
                                                                    <Editor 
                                                                    style={{ minHeight: '300px' }}
                                                                    value={value}  
                                                                    //onChange={onChange}                                                                   
                                                                    onChange={(content) => {
                                                                        onChange(content);
                                                                        //handleOnChange(content, onChange); 
                                                                    }} 
                                                                    />
                                                                )}
                                                            />

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h3>areas of expertise</h3>
                                                        <div className="stepcategory_list">
                                                            {pinfilterCategoryIds?.map((category) => (
                                                                <div className="category_list" key={category.categoryId}>
                                                                    <div className='d-none'>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={category.categoryId}
                                                                            id={`flexCheck${category.categoryId}`}
                                                                            {...register("PinCategories", { required: true })}
                                                                            defaultChecked={true}
                                                                            //{...(pincategoryIds.includes(category.categoryId) ? { defaultChecked: true } : {})}
                                                                        />
                                                                    </div>
                                                                    <h5>{category.categoryName}</h5>

                                                                    {category.subCategories.map((subCategory) => (
                                                                        <div className="form-check" key={subCategory.subCategoryId}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                value={subCategory.subCategoryId}
                                                                                id={`flexCheck${subCategory.subCategoryId}`}
                                                                                {...register("PinCategories", { required: true })}

                                                                                {...(pinsubCategoryIds?.includes(subCategory.subCategoryId) ? { defaultChecked: true } : {})}
                                                                                
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`flexCheck${subCategory.subCategoryId}`}>
                                                                                {subCategory.subCategoryName} 
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))} 

                                                            {errors.PinCategories && <span className="rf-field-validation">Please select at least one area of expertise.</span>}

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div >
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <h3 className="border_bottom">Hide My Name {pinAllData.hideMyName}</h3>
                                                            </div>
                                                            <div className="form-check form-switch mb-3">
                                                                <input className="form-check-input" type="checkbox"
                                                                    {...register("HideMyName")} 
                                                                    defaultChecked={pinAllData?.hideMyName !== null ? pinAllData?.hideMyName : false} 
                                                                    role="switch" id="flexSwitchCheckDefault" />
                                                                <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">ON / OFF</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center">

                                                    <button className="btn btn-danger text-uppercase ms-2" type='submit'>Submit for Review</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default EditMyPin