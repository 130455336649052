import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { 
    UpdatePasswordAction, 
    UserTwofaAction, 
    AllNotificationAction, 
    UpdateNotificationAction, 
    getUserSettings 
} from '../store/reducer/user/action';

import TopHeader from '../components/Header/TopHeader'
import Footer from '../components/Footer/Footer'
import ProfileImage from '../components/ProfileImage/ProfileImage';
import FullPageLoader from '../_common/helper/FullPageLoader';
import LeftNavMenu from '../components/LeftMenu/LeftNavMenu';
import { LOGIN_VERIFICATION } from '../config';



const SettingsProfile = () => {
    const _user = JSON.parse(localStorage.getItem('user'));
    const userId = _user?.userId;
    const userProfileId = localStorage.getItem('_rfprofile'); //_user?.userProfileId;
    const useEmail = _user?.email;
    const [loading, setLoading] = useState(true);
    const [settingsData, setSettingsData] = useState(null);
    const [ userNotificationSettingsIds, setUserNotificationSettingsIds] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        dispatch(getUserSettings(userProfileId))
            .then((fulfilledAction) => {
                const data = fulfilledAction.payload.data;
                
                if(data) {
                    setSettingsData(data); // Store data in the state                    
                    setLoading(false);
                } 
                setLoading(false);             
            })
            .catch((error) => {    
                setLoading(false);            
                console.error('Error fetching expertise data:', error);
            });
    }, []); 

    //const userNotificationSettingsIds = settingsData?.objNotificationSettings.map(item => item.userNotificationSettingsId);
    
   
    // Dispatch the AllNotificationAction to fetch data
    useEffect(() => {
        dispatch(AllNotificationAction());
        let _notificationSettings = settingsData?.objNotificationSettings.map(item => item.userNotificationSettingsId);        
        setUserNotificationSettingsIds(_notificationSettings)
       
    }, [dispatch, settingsData]);
    

    // Access the data from the Redux store using useSelector
    const notificationData = useSelector(state => state.notiInfo);    

    const { register: passwordRegister, handleSubmit: handlePasswordSubmit, getValues, formState: { errors: passwordErrors }, reset: resetPasswordForm } = useForm({

        defaultValues: {
            email: useEmail

        }
    });
    const { register: twofactorRegister, handleSubmit: handleTwoFactorSubmit, formState: { errors: twofactorErrors }, reset: resetTwoFactorForm } = useForm();
    const { register: notificationRegister, handleSubmit: handleNotificationSubmit, formState: { errors: notificationErrors }, reset: resetNotificationForm } = useForm();



    const onSubmit = (data) => {
        setLoading(true);
        let postData = {
            "userId": userId,
            "email": data.email,
            "oldPassword": data.oldPassword,
            "newPassword": data.newPassword,
            "IsPublicEnvironment": !LOGIN_VERIFICATION
        }
        try {
            dispatch(UpdatePasswordAction(postData))
                .unwrap()
                .then((fulfilledAction) => {                   
                    if (fulfilledAction.success === true) {
                        setLoading(false);
                        toast.success(fulfilledAction.message);
                    } else {
                        setLoading(false);
                        toast.error(fulfilledAction.message);

                    }

                })
                .catch((rejectedAction) => {
                    setLoading(false);                    
                    // toast.error(fulfilledAction.message);

                });
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);            
        }        
    }

    const checkPasswordMatch = (value) => {
        const newPassword = getValues("newPassword");        
        if (!value) {
            return "Retype New Password is required";
        }
        return newPassword === value || "Password Mismatch";

    };

    const onSubmitTwoFactor = (data) => {
        setLoading(true);
        let postData = {
            "userProfileId": userProfileId,
            "twoFA": data.TwoFA,
        }

        try {
            dispatch(UserTwofaAction(postData))
                .unwrap()
                .then((fulfilledAction) => {                   
                    if (fulfilledAction.success === true) {
                        setLoading(false);
                        toast.success(fulfilledAction.message);
                    } else {
                        setLoading(false);
                        toast.error(fulfilledAction.message);

                    }

                })
                .catch((rejectedAction) => {
                    setLoading(false);                    
                    // toast.error('Incorrect username or password. Please try again.');

                });
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    }


    const onSubmitNotification = (data) => {
        setLoading(true);
        const trueValuesWithKeys = data.notification.reduce((result, value, key) => {
           // console.log(' key, value',  key, value);
            if (value === true) {
              result.push({ key, value });
            }
            return result;
          }, []);
         
        const postData = trueValuesWithKeys.map((item) => ({
            userProfileId: userProfileId,
            userNotificationSettingsId: item.key,
        }));
        
        if(postData.length > 0 ) {

            try {
                dispatch(UpdateNotificationAction(postData))
                    .unwrap()
                    .then((fulfilledAction) => {
                        
                        if (fulfilledAction.success === true) {
                            setLoading(false);
                            toast.success(fulfilledAction.message);
                        } else {
                            setLoading(false);
                            toast.error(fulfilledAction.message);
    
                        }
    
                    })
                    .catch((rejectedAction) => {
                        setLoading(false);
                        
                        // toast.error('Incorrect username or password. Please try again.');
    
                    });
            } catch (error) {
                setLoading(false);
                console.error('Error:', error);
            }
           
        } else {

            const postData = [{
                userProfileId: userProfileId,
                userNotificationSettingsId: 0,
            }];

            dispatch(UpdateNotificationAction(postData))
                    .unwrap()
                    .then((fulfilledAction) => {
                        //console.log('fulfilledAction:', fulfilledAction);
    
                        if (fulfilledAction.success === true) {
                            setLoading(false);
                            toast.success(fulfilledAction.message);
                        } else {
                            setLoading(false);
                            toast.error(fulfilledAction.message);
    
                        }
    
                    })
                    .catch((rejectedAction) => {
                        setLoading(false);
                        //console.log('rejectedAction:', rejectedAction);
                        // toast.error('Incorrect username or password. Please try again.');
    
                    });
            setLoading(false);
        }
       
        //console.log('onSubmitNotification', data);
    }


    return (
        <>
            {loading && <FullPageLoader />}
            <TopHeader />
            <div className="masonry-container page_minheight">
                <div className="container">
                    <div className="profile_page">
                        <div className="profile_laft">                            
                            <ProfileImage />
                            <div className="prof_menue">
                                <LeftNavMenu />                                
                            </div>
                        </div>
                        <div className="profile_right">
                            <form onSubmit={handlePasswordSubmit(onSubmit)}>
                                <input type="hidden" {...passwordRegister("email", { required: true })} className="form-control" id="exampleFormControlInput11" placeholder="" />
                                <div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h3 className="border_bottom">Change Password</h3>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Old Password</label>
                                                <input type="password" {...passwordRegister("oldPassword", { required: true })} className="form-control" id="exampleFormControlInput1" placeholder="" maxLength={48}/>
                                            </div>
                                            {passwordErrors.oldPassword && <span className="rf-field-validation">Old password is required</span>}
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput2" className="form-label">New Password</label>
                                                <input type="password" {...passwordRegister("newPassword", { required: true, pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, minLength: 6 })} className="form-control" id="exampleFormControlInput2" placeholder="" maxLength={48} />
                                            </div>
                                            {passwordErrors.newPassword?.type === "required" && <span className="rf-field-validation">New password is required</span>}
                                            {passwordErrors.newPassword?.type === "pattern" && (
                                                <span className="rf-field-validation">
                                                    Password must contain at least one uppercase letter, one number, and one special character.
                                                </span>
                                            )}
                                            {passwordErrors.newPassword?.type === "minLength" && (
                                                <span className="rf-field-validation">
                                                    Password must be at least 6 characters long.
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="exampleFormControlInput3" className="form-label">Retype New Password</label>
                                                <input type="password" className="form-control"
                                                    {...passwordRegister("retypeNewPassword", {
                                                        required: true,
                                                        validate: checkPasswordMatch, // Custom validation rule
                                                    })} id="exampleFormControlInput3" placeholder="" maxLength={48}/>
                                            </div>
                                            {passwordErrors.retypeNewPassword && (
                                                <span className="rf-field-validation">
                                                    {passwordErrors.retypeNewPassword.message === "Retype New Password is required"
                                                        ? "Retype New Password is required"
                                                        : "Password Mismatch"}
                                                </span>
                                            )}
                                            {/* {passwordErrors.retypeNewPassword && (
                                                <span className="rf-field-validation">{passwordErrors.retypeNewPassword.message}</span>
                                            )} */}
                                        </div>

                                    </div>
                                    <div className="mb-4">
                                        <button className="btn filter_btn btn-danger rounded-pill" type='submit'>
                                            <span>Update</span>
                                            <div className="transition"></div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <form onSubmit={handleTwoFactorSubmit(onSubmitTwoFactor)}>
                                <div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h3 className="border_bottom">Two Factor Authentication</h3>
                                    </div>
                                    <div className="form-check form-switch mb-3">
                                        <input className="form-check-input" type="checkbox" {...twofactorRegister("TwoFA")} defaultChecked={settingsData?.twoFA !== null ? settingsData?.twoFA : false} role="switch" id="flexSwitchCheckDefault" />
                                        <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">ON / OFF</label>
                                    </div>
                                    <div className="mb-4">
                                        <button className="btn filter_btn btn-danger rounded-pill">
                                            <span>Update</span>
                                            <div className="transition"></div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <form onSubmit={handleNotificationSubmit(onSubmitNotification)}>
                                <div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h3 className="border_bottom">Notification settings</h3>
                                    </div>

                                    <div>
                                        {notificationData?.notificationData?.data.map(item => (
                                            <div className="form-check form-switch mb-3" key={item.userNotificationSettingsId}>
                                                <input
                                                    className="form-check-input"
                                                    {...notificationRegister(`notification.${item.userNotificationSettingsId}`)} // You might need to adjust the input name here
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`flexSwitchCheckDefault${item.userNotificationSettingsId}`}
                                                    //checked={userNotificationSettingsIds?.includes(item.userNotificationSettingsId)}
                                                    {...(userNotificationSettingsIds?.includes(item.userNotificationSettingsId) ? { defaultChecked: true } : {})}
                                                />
                                                <label className="form-check-label ms-2" htmlFor={`flexSwitchCheckDefault${item.userNotificationSettingsId}`}>
                                                    {item.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>

                                    {/* <div className="form-check form-switch mb-3">
                                        <input className="form-check-input" {...notificationRegister("Like")} type="checkbox" role="switch" id="flexSwitchCheckDefault2" />
                                        <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault2">Like</label>
                                    </div>
                                    <div className="form-check form-switch mb-3">
                                        <input className="form-check-input" type="checkbox" {...notificationRegister("Share")} role="switch" id="flexSwitchCheckDefault3" />
                                        <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault3">Share</label>
                                    </div>
                                    <div className="form-check form-switch mb-3">
                                        <input className="form-check-input" type="checkbox" {...notificationRegister("Comment")} role="switch" id="flexSwitchCheckDefault4" />
                                        <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault4">Comment</label>
                                    </div>
                                    <div className="form-check form-switch mb-3">
                                        <input className="form-check-input" type="checkbox" {...notificationRegister("Review")} role="switch" id="flexSwitchCheckDefault5" />
                                        <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault5">Pin Review Accept/Reject</label>
                                    </div> */}
                                    <div className="">
                                        <button className="btn filter_btn btn-danger rounded-pill" type='submit'>
                                            <span>Update</span>
                                            <div className="transition"></div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default SettingsProfile