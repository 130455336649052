import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { UserEmailVerification } from '../store/reducer/user/action';
import { useDispatch } from 'react-redux';
import { LOGO_IMAGE } from '../config';


const EmailVerification = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        // Get the current URL
        const currentUrl = window.location.href;

        const url = new URL(currentUrl);

        // Get the value of the "ResetId" parameter from the URL
        const resetId = url.searchParams.get("ResetId");
       
        if (resetId !== null) {
            const param = {
                "resetId": resetId,
                "isEmailVerified": true
              }
              dispatch(UserEmailVerification(param));
        }
    }, []);
    return (
        <>

            <div className="login_page">
                <div className="loin_area">
                    <div className="login_logo">
                        <Link to='/'><img src={LOGO_IMAGE} /></Link>
                    </div>
                    <h3 className='pt-3'><strong>Congratulations!</strong></h3>
                    <p><strong>Your email is succesfully verified.</strong></p>


                    <p className="mt-3"> <Link to='/' className="btn btn-danger signin_btn">Sign In</Link></p>
                </div>
            </div>
        </>
    )
}

export default EmailVerification