import React from 'react'
import TopHeader from '../components/Header/TopHeader'
import Footer from '../components/Footer/Footer'

const Details = () => {
  return (
    <>
        <TopHeader />
        <div className="masonry-container page_minheight">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card">
                            <img src="assets/images/pic-2.avif" alt="" />
                            <div className="card-body">
                                <h5 className="card-title text-truncate whitespace_normal">Card title that wraps</h5>
                            </div>
                        </div>
                        <div className="card bill-body-text-like-inner-sec">
                            <ul>
                              <li>
                                <a href="#"><span className="material-symbols-outlined">thumb_up</span> 1.2k</a>
                              </li>
                              <li>
                                <a href="#"><span className="material-symbols-outlined">thumb_Down</span> 480</a>
                              </li>
                              <li>
                                <a href="#"><span className="material-symbols-outlined">visibility</span> 3.6k</a>  
                              </li>
                              <li>
                                <a href="#"><span className="material-symbols-outlined">share</span></a>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="card p-4">
                            <div className="problem_statement">
                                <h3>PROBLEM STATEMENT</h3>
                                <p>Time based maintenance leaves units that are in need of immediate attention at risk, and spends resources on units that don't need it yet.</p>
                            </div>
                            <div className="problem_statement">
                                <h3>Current State</h3>
                                <p>Solar fields are inspected every 5 years regardless of condition</p>
                            </div>
                            <div className="problem_statement">
                                <h3>desired outcome</h3>
                                <p>A maintenance program that is driven off real time data</p>
                            </div>
                            <div className="problem_statement">
                                <h3>Solutions</h3>
                                <p>CBM Monitors: real time, 5G oil monitore placed on transformer tanks</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card related_service p-4 mt-3">
                  <div className="d-flex alignitems-center justify-content-between mb-3">
                    <h3 className="p-0">Related Pin</h3>
                    <a href="#" className="more">More</a>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-3 col-sm-2">
                      <div className="card">
                        <img src="assets/images/01.jpeg" alt="" />
                        <div className="card-body">
                        <h5 className="card-title text-truncate">Regulation and Compliance</h5>
                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-3 col-sm-2 ">
                      <div className="card">
                        <img src="assets/images/02.jpg" alt="" />
                        <div className="card-body">
                        <h5 className="card-title text-truncate">Transmission</h5>
                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-3 col-sm-2 ">
                      <div className="card">
                        <img src="assets/images/03.jpeg" alt="" />
                        <div className="card-body">
                        <h5 className="card-title text-truncate">Energy Efficiency & Demand Response</h5>
                        <p className="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-3 col-sm-2 ">
                      <div className="card">
                        <img src="assets/images/04.jpeg" alt="" />
                        <div className="card-body">
                        <h5 className="card-title text-truncate">Technology & Innovation</h5>
                        <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="card comment_post p-4 mt-3">
                  <h3>Post</h3>
                  <div className="bg-light text-dark">

                    <div className="app">
                       <div className="col-md-12 col-lg-12 m-auto">
                          <div className="bg-white rounded-3 shadow-sm p-4 mb-4">
                             {/* <!-- New Comment //--> */}
                             <div className="d-flex">
                                <img className="rounded-circle me-3"
                                     style={{width:'3rem', height:'3rem'}}
                                     src="https://via.placeholder.com/128/fe669e/ffcbde.png?text=S" />
                                <div className="flex-grow-1">
                                   <div className="hstack gap-2 mb-1">
                                      <a href="#" className="fw-bold link-dark">Studio KonKon</a>
                                   </div>
                                   <div className="form-floating mb-3">
                                      <textarea className="form-control w-100"
                                                placeholder="Leave a comment here"
                                                id="my-comment"
                                                style={{height:'7rem'}}></textarea>
                                      <label htmlFor="my-comment">Leave a comment here</label>
                                   </div>
                                   <div className="hstack justify-content-end gap-2">
                                      <button className="btn btn-sm btn-link link-secondary text-uppercase">cancel</button>
                                      <button className="btn btn-sm btn-danger text-uppercase">comment</button>
                                   </div>
                                </div>
                             </div>
                          </div>
                          <div className="bg-white rounded-3 shadow-sm p-4">
                 
                             <h4 className="mb-2">3 Comments</h4>
                 
                             {/* <!-- Comment #1 //--> */}
                             <div className="">
                                <div className="py-3">
                                   <div className="d-flex comment">
                                      <img className="rounded-circle comment-img"
                                           src="https://via.placeholder.com/128/fe669e/ffcbde.png?text=S" />
                                      <div className="flex-grow-1 ms-3">
                                         <div className="mb-1"><a href="#" className="fw-bold link-dark me-1">Studio KonKon</a> <span className="text-muted text-nowrap">2 days ago</span></div>
                                         <div className="mb-2">Lorem ipsum dolor sit amet, ut qui commodo sensibus, id utinam inermis constituto vim. In nam dolorum interesset, per fierent ponderum ea. Eos aperiri feugiat democritum ne.</div>
                                         <div className="hstack align-items-center mb-2">
                                            <a className="link-primary me-2" href="#"><span className="material-symbols-outlined">thumb_up</span></a>
                                            <span className="me-3 small">55</span>
                                            <a className="link-secondary me-4" href="#"><span className="material-symbols-outlined">thumb_Down</span></a>
                                            <a className="link-secondary small" href="#">REPLY</a>
                                            <a className="link-danger small ms-3" href="#">DELETE</a>
                                         </div>
                                        
                                      </div>
                                   </div>
                                   
                                </div>
                 
                                {/* <!-- Comment #2 //--> */}
                                <div className="py-3">
                                   <div className="d-flex comment">
                                      <img className="rounded-circle comment-img"
                                           src="https://via.placeholder.com/128/99ccff/0073e6.png?text=A" />
                                      <div className="flex-grow-1 ms-3">
                                         <div className="mb-1"><a href="#" className="fw-bold link-dark pe-1">Asai Kazuma</a> <span className="text-muted text-nowrap">8 hours ago</span></div>
                                         <div className="mb-2">Ei saepe abhorreant temporibus cum, hinc praesent voluptatum ea has.<br />Vis nihil tacimates senserit ut, quo posse labores honestatis te. Ex duo nullam posidonium deterruisset, altera aeterno duo.</div>
                                         <div className="hstack align-items-center mb-2">
                                            <a className="link-secondary me-2" href="#"><span className="material-symbols-outlined">thumb_up</span></a>
                                            <span className="me-3 small">26</span>
                                            <a className="link-secondary small me-4" href="#"><span className="material-symbols-outlined">thumb_Down</span></a>
                                            <a className="link-secondary small" href="#">REPLY</a>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                 
                                {/* <!-- Comment #3 //--> */}
                                <div className="py-3">
                                   <div className="d-flex comment">
                                      <img className="rounded-circle comment-img"
                                           src="https://via.placeholder.com/128/ff99cc/fe669e.png?text=K" />
                                      <div className="flex-grow-1 ms-3">
                                         <div className="mb-1"><a href="#" className="fw-bold link-dark pe-1">Kamisato Mugi</a> <span className="text-muted text-nowrap">10 hours ago</span></div>
                                         <div className="mb-2">Aenean non tellus sed erat ultrices rutrum. Sed ac dolor tempus, efficitur diam vitae, sagittis nisi. Morbi bibendum congue nisl eu congue. Mauris eu eros bibendum, pretium ex ac, aliquam lorem.</div>
                                         <div className="hstack align-items-center mb-2">
                                            <a className="link-primary me-2" href="#"><span className="material-symbols-outlined">thumb_up</span></a>
                                            <span className="me-3 small">8</span>
                                            <a className="link-secondary small me-4" href="#"><span className="material-symbols-outlined">thumb_Down</span></a>
                                            <a className="link-secondary small" href="#">REPLY</a>
                                         </div>
                                         
                                      </div>
                                   </div>
                                   
                                </div>
                             </div>
                 
                          </div>
                       </div>
                    </div>
                 
                   
                 
                  </div>
                 
                </div>
            </div>
           
        </div>
        <Footer />
    </>
  )
}

export default Details